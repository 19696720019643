import { Controller } from "@hotwired/stimulus";
import consumer from "@channels/consumer";

/**
 * Creates a WebSockets connection and acts on broadcasts with a matching subject_id.
 */
export default class extends Controller {
  static values = {
    /**
     * The action to take when a valid notification is received
     */
    action: { type: String, default: "pageReload" },
    /**
     * Optional space-separated list of event names to filter notifications by.
     */
    events: String,
    /**
     * The subject_id to filter notifications by
     */
    subjectId: String
  };

  connect() {
    this.eventsValue = this.eventsValue.split(" ");
    this.initChannel();
  }

  /**
   * Validate messages by subject_id and optional event_name
   */
  validateMessage(data) {
    if (!data.subject_id || data.subject_id !== this.subjectIdValue) {
      return false;
    }

    if (this.eventsValue.length && !this.eventsValue.includes(data.event_name)) {
      return false;
    }

    return true;
  }

  consumer(data) {
    console.log("Message Received", data, this.validateMessage(data));
    if (this.validateMessage(data)) {
      switch (this.actionValue) {
        case "frameReload":
          this.element.reload();
          break;

        case "pageReload":
          window.location.reload();
          break;
      }
    }
  }

  initChannel() {
    console.log('initchannel')
    this.subscription = consumer.subscriptions.create(
      { channel: "OrganizationChannel" },
      { received: this.consumer.bind(this) }
    );
  }
}
