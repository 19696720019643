// @flow
import { useState } from "react";

export default function useCsrfToken(): string {
  const [csrfToken] = useState<string>(
    () => (document.querySelector("meta[name=csrf-token]"): any).content
  );

  return csrfToken;
}
