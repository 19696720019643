import moment from "moment";

export const formatDate = (date, format) => {
  return moment(date, format);
};

export const today = () => moment.utc();

export const daysAgo = (days) => today().subtract(days, "days");

export const isToday = (dayObj) => dayObj.isSame(today(), "day");

export const isAfterToday = (dayObj) => dayObj.isAfter(today().endOf("day"));

/**
 * Converts a datetime string to the correct format for a datetime-local field value
 */
export const toDatetimeLocalFieldValue = (dateString) => {
  if (!dateString) {
    return;
  }

  const dateObj = new Date(dateString)
  return dateObj.toISOString().substr(0, 19);
};
