//@flow
import React, { useMemo } from "react";
import uniqueId from "lodash/uniqueId";
import { useRateGroup } from "./context";
import type { CreativeType, CreativeTypeField, MailType } from "./types";

type RateFieldProps = {|
  rateType:
    | "base"
    | "offset"
    | "append"
    | "option"
    | "per_page"
    | "extra_postage"
    | "record"
    | "customer_ranking",
  fieldId: string,
  label?: string,
  creativeType?: CreativeType | CreativeTypeField,
  creativeTypeOptionId?: string,
  mailType?: MailType,
  offsetMinimum?: string,
  destroy?: boolean
|};

function RateField({
  label,
  fieldId,
  rateType,
  creativeType,
  creativeTypeOptionId,
  mailType,
  offsetMinimum,
  destroy
}: RateFieldProps): React$Node {
  const { getRate, setPrice } = useRateGroup();
  const { price = "", id: rateId } = getRate(fieldId);

  const uid = useMemo(() => uniqueId(), []);

  const rateName = useMemo(() => {
    return label || [creativeType?.name, mailType?.name, offsetMinimum].filter(Boolean).join(" ");
  }, [label, creativeType, mailType, offsetMinimum]);

  // price is on blur instead of change so the user is able to use backspace/delete while editing the field
  const handleBlur = () => {
    setPrice(fieldId, price ? parseFloat(price).toFixed(2) : "");
  };

  // If a rate does not have an ID, it does not need to be destroyed
  if (destroy && !rateId) {
    return null;
  }

  return (
    <div>
      {!!rateId && <input type="hidden" name={fieldName(uid, "id")} value={rateId} />}

      {!!mailType && (
        <input type="hidden" name={fieldName(uid, "mail_type_id")} value={mailType.id} />
      )}

      {!!creativeType && (
        <input type="hidden" name={fieldName(uid, "creative_type_id")} value={creativeType.id} />
      )}

      {!!creativeTypeOptionId && (
        <input
          type="hidden"
          name={fieldName(uid, "creative_type_option_id")}
          value={creativeTypeOptionId}
        />
      )}

      {destroy && <input type="hidden" name={fieldName(uid, "_destroy")} value={1} />}

      {!!offsetMinimum && (
        <input type="hidden" name={fieldName(uid, "offset_minimum")} value={offsetMinimum} />
      )}
      <input type="hidden" name={fieldName(uid, "name")} value={rateName} />
      <input
        type="hidden"
        name={fieldName(uid, "rate_type")}
        value={rateType === "offset" ? "base" : rateType}
      />
      <input
        type="number"
        step="0.01"
        min="0"
        name={fieldName(uid, "price")}
        id={fieldId}
        onChange={(e) => setPrice(fieldId, e.target.value)}
        onBlur={handleBlur}
        value={price}
        required
      />
    </div>
  );
}

const fieldName = (uid: string, attribute: string): string =>
  `rate_group[rates_attributes][${uid}][${attribute}]`;

export default RateField;
