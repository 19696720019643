import React, { Fragment } from "react";

class AttributionFilter extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      unique: props.unique === "true"
    };

    this._handleToggle = this._handleToggle.bind(this);
  }

  _handleToggle(event){
    const unique = event.target.value === "true";

    this.setState({ unique });
  }

  render(){
    const {
      data_url,
      manual_send_id,
      start_date,
      end_date
    } = this.props;
    const { unique } = this.state;

    const token = $("meta[name=\"csrf-token\"]").attr("content");

    return (
      <Fragment>
        <button className="slm-toolbar-button icon-filter" type="button" data-toggle="filter-dropdown">Filters</button>
        <div className="dropdown-pane filter-dropdown" id="filter-dropdown" data-dropdown>
          <form className="filter-dropdown__form" action={data_url} acceptCharset="UTF-8" method="get">
            <input type="hidden" name="authenticity_token" value={token} />
            <input type="hidden" name="states[manual_send_id]" value={manual_send_id} />
            <input type="hidden" name="states[start_date]" value={start_date} />
            <input type="hidden" name="states[end_date]" value={end_date} />
            <input type="hidden" name="view_settings_sent" value />
            <h5 className="preview-attribute__label">Calculate By</h5>
            <label>
              <input
                type="radio"
                name="view_settings[unique]"
                value="false"
                checked={!unique}
                onChange={this._handleToggle}
              />
              All Orders
            </label>
            <label>
              <input
                type="radio"
                name="view_settings[unique]"
                value="true"
                checked={unique}
                onChange={this._handleToggle}
              />
              Unique Buyers Only
            </label>
            <input type="submit" name="commit" value="Apply" className="slm-button secondary " data-disable-with="Apply" />
          </form>
        </div>
      </Fragment>
    );
  }
}

export default AttributionFilter;
