// @flow
import React, { useState } from "react";
import type { Node } from "react";
import Input from "@elements/PasswordInput";

type PasswordInputProps = {
  id: string,
  name: string,
  forgotPasswordUrl: string,
  autoComplete: string,
  dataMethod: string
};

export default function PasswordInput(props: PasswordInputProps): Node  {
  const [type, setType] = useState<"password" | "text">("password");

  const toggleType = () => {
    const nextType = type === "password" ? "text" : "password";
    setType(nextType);
  };

  return (
    <Input
      onClick={toggleType}
      type={type}
      {...props}
    />
  );
}
