// @flow
import uniqueId from "lodash/uniqueId";
import type { RateFields, RateGroupState } from "./types";
import { toDatetimeLocalFieldValue } from "@utilities/MomentHelpers";

type RateGroupFields = {
  name: string,
  startDate: string,
  endDate: string,
  globalDefault: boolean
};

type InitialRate = {|
  creativeTypeId?: string,
  creativeTypeOptionId?: string,
  id?: string,
  mailTypeId?: string,
  offsetMinimum?: number,
  price: string,
  rateType: string
|};

type InitialStateParams = {
  rateFields: RateFields,
  rates: Array<InitialRate>,
  clone: boolean,
  rateGroup: RateGroupFields
};

export function generateInitialState({
  rateFields,
  rates,
  rateGroup,
  clone
}: InitialStateParams): RateGroupState {
  const { name, startDate, endDate, globalDefault } = rateGroup;
  const rateGroupName = `${clone ? "CLONE: " : ""}${name || ""}`;
  const creativeTypes = creativeTypesState(rateFields);
  const ratesState: RateGroupState["rates"] = {};

  if (rates) {
    rates.forEach((rate) => {
      let offsetTier;
      let { rateType } = rate;

      if (rate.offsetMinimum && rate.creativeTypeId) {
        rateType = "offset";
        const tierKey = rate.creativeTypeId;
        const { offsetTiers } = creativeTypes[tierKey];
        offsetTier = getOffsetTierKey(offsetTiers, rate.offsetMinimum);

        creativeTypes[tierKey].offsetTiers[offsetTier] = {
          minimum: rate.offsetMinimum
        };
      }
      const key = rateKey({
        creativeTypeId: rate.creativeTypeId,
        mailTypeId: rate.mailTypeId,
        creativeTypeOptionId: rate.creativeTypeOptionId,
        rateType,
        offsetTier
      });

      const rateObj = clone
        ? {
            price: parseFloat(rate.price).toFixed(2)
          }
        : {
            price: parseFloat(rate.price).toFixed(2),
            id: rate.id
          };

      ratesState[key] = rateObj;
    });
  }

  return {
    creativeTypes,
    name: rateGroupName,
    startDate: toDatetimeLocalFieldValue(startDate) || "",
    endDate: toDatetimeLocalFieldValue(endDate) || "",
    globalDefault: globalDefault || false,
    rates: ratesState
  };
}

export function rateKey({
  offsetTier,
  rateType,
  creativeTypeId,
  mailTypeId,
  creativeTypeOptionId
}: {
  offsetTier?: string,
  rateType: string,
  creativeTypeId?: string,
  mailTypeId?: string,
  creativeTypeOptionId?: string
}): string {
  return [rateType, creativeTypeId, mailTypeId, offsetTier, creativeTypeOptionId]
    .filter(Boolean)
    .join("-")
    .replace(/\W+/g, "-")
    .toLowerCase();
}

function getOffsetTierKey(
  offsetTiers: { [string]: { minimum: number } },
  offsetMinimum: number
): string {
  const existingTier = Object.keys(offsetTiers).find(
    (key) => offsetTiers[key].minimum === offsetMinimum
  );
  if (existingTier) {
    return existingTier;
  }

  const [firstKey] = Object.keys(offsetTiers);
  // replace the default tier if it still exists, otherwise create a new key
  return offsetTiers[firstKey].minimum === 0 ? firstKey : uniqueId();
}

function creativeTypesState({ baseFields }: RateFields): any {
  return baseFields.reduce(
    (state, { mailTypes, name, id }) => ({
      ...state,
      [id]: {
        name,
        id,
        // sort mailtypes standard before first class
        mailTypes: mailTypes.sort((a, b) => b.name.localeCompare(a.name)),
        // create a default offset tier
        offsetTiers: {
          [uniqueId()]: { minimum: 0 }
        }
      }
    }),
    {}
  );
}
