// @flow

import React, { useCallback, useEffect } from "react";
import type { Node } from "react";
import X from "react-feather/dist/icons/x";
import useOutsideClick from "@utilities/useOutsideClick";

type Props = {
  children: Node,
  className?: string,
  footer?: Node,
  header?: Node,
  subHeader?: Node,
  onClose?: () => void,
  show: boolean
};

export default function Modal({
  children,
  className = "",
  footer,
  header,
  subHeader,
  onClose,
  show
}: Props): Node {
  const node = useOutsideClick(onClose);

  const onEscapeKeyPress = useCallback(
    (event) => {
      if (event.key === "Escape" && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (onClose) {
      document.addEventListener("keydown", onEscapeKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", onEscapeKeyPress);
    };
  }, [onEscapeKeyPress, onClose]);

  if (!show) return null;

  return (
    <article
      className="modal-container"
      role="dialog"
      aria-modal="true"
      aria-labelledby="#modal-header"
    >
      <div ref={node} className={`modal-content ${className}`}>
        {header && (
          <h1 className="modal-header" id="modal-header">
            {header}
          </h1>
        )}
        {subHeader && <h2 className="modal-sub-header">{subHeader}</h2>}
        {onClose && (
          <button aria-label="close" type="button" className="modal-close" onClick={onClose}>
            <X strokeWidth="2" size={18} />
          </button>
        )}
        <div>{children}</div>
        {footer && <div className="flex-justify-end">{footer}</div>}
      </div>
    </article>
  );
}
