// @flow
import React, { useState } from "react";
import RailsFormData from "@utilities/FormatRailsFormData";
import FormErrors from "@elements/FormErrors";
import FileUploader from "@elements/FileUploader";
import ConvertBytesToMegabytes from "@utilities/ConvertBytesToMegabytes";
import ModalActions from "@elements/ModalPortals";
import Button from "@elements/Button";
import classNames from "classnames";
import type { Node } from "react";

const buttonClassNames = "inline-flex items-center justify-center slm-button";

function CreativeUploadForm({
  errors,
  label_back,
  label_front,
  maximum_filesize,
  previous_wizard_path,
  show_back_upload,
  supported_formats,
  supported_mime_types
}: CreativeUploadFormProps): Node {
  const [frontArtwork, setFrontArtwork] = useState<string | null>(null);
  const [backArtwork, setBackArtwork] = useState<string | null>(null);

  const disableSubmit = !frontArtwork || (!backArtwork && show_back_upload) || errors.length;

  return (
    <div className="grid-x grid-padding-x">
      <ModalActions>
        <a href={previous_wizard_path} className={classNames(buttonClassNames, "secondary mr-2")}>
          Back
        </a>
        <button
          className={classNames(buttonClassNames, { disabled: disableSubmit })}
          disabled={disableSubmit}
          form="submit_target"
        >
          Next
        </button>
      </ModalActions>
      <RailsFormData
        path="creative"
        data={{
          active_version_attributes: {
            front_artwork: frontArtwork,
            back_artwork: backArtwork
          }
        }}
      />
      <FormErrors errors={errors} />
      <FileUploader
        accept={supported_mime_types}
        supportedFormats={supported_formats}
        label={show_back_upload ? label_front : null}
        maxFileSize={maximum_filesize}
        containerClassName={show_back_upload ? "cell medium-6" : "cell medium-12"}
        onUploadSuccess={(blob) => setFrontArtwork(blob.signed_id)}
      />
      {show_back_upload ? (
        <FileUploader
          accept={supported_mime_types}
          supportedFormats={supported_formats}
          label={label_back}
          maxFileSize={maximum_filesize}
          containerClassName="cell medium-6"
          onUploadSuccess={(blob) => setBackArtwork(blob.signed_id)}
        />
      ) : null}
      <div className="cell medium-12 uploader__footer">
        <span>{`Supported format(s): ${supported_formats.join(", ").toUpperCase()}`}</span>
        <br />
        <span>{`Maximum filesize: ${ConvertBytesToMegabytes(maximum_filesize)} MB`}</span>
        <br />
        <span>
          Learn more about our{" "}
          <a
            href="https://docs.heypoplar.com/article/189-templates-specs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creative File Requirements
          </a>
          .
        </span>
      </div>
    </div>
  );
}

type CreativeUploadFormProps = {
  errors: Array<string>,
  label_back: string,
  label_front: string,
  maximum_filesize: number,
  previous_wizard_path: string,
  show_back_upload: Boolean,
  supported_formats: Array<string>,
  supported_mime_types: Array<string>
};

export default CreativeUploadForm;
