import React from "react";
import CheckboxList from "@components/CheckboxList";
import ColumnsIcon from "react-feather/dist/icons/pause";
import ToolbarDropdown from "./ToolbarDropdown";

function ColumnSelector({
  data_url,
  manual_send_id,
  start_date,
  end_date,
  attributionColumns,
  selectedColumns
}) {
  const token = document.querySelector("[name=csrf-token]").content;

  return (
    <ToolbarDropdown
      button_text="Columns"
      icon={<ColumnsIcon className="mr-1" fill="currentcolor" size="18" />}
    >
      <form className="filter-dropdown__form" action={data_url} acceptCharset="UTF-8" method="get">
        <input type="hidden" name="authenticity_token" value={token} />
        {!!manual_send_id && (
          <input type="hidden" name="states[manual_send_id]" value={manual_send_id} />
        )}
        <input type="hidden" name="states[start_date]" value={start_date} />
        <input type="hidden" name="states[end_date]" value={end_date} />
        <input type="hidden" name="view_settings_sent" value />
        <CheckboxList
          checkboxGroups={[{ namespace: "view_settings[columns]", checkboxes: attributionColumns }]}
          initialChecked={selectedColumns}
        />
      </form>
    </ToolbarDropdown>
  );
}

export default ColumnSelector;
