// @flow
import React, { useState } from "react";
import type { Node } from "react";
import RailsFormData from "@utilities/FormatRailsFormData";
import ModalActions from "@elements/ModalPortals";
import Button from "@elements/Button";
type ProcessType = "digital" | "offset";

type Props = {
  additional_offset_creative_cost: number,
  multiple_creatives: boolean,
  current_production_option: ProcessType,
  digital_estimated_cost: number,
  offset_estimated_cost: number,
  digital_price_per_piece: number,
  offset_price_per_piece: number,
  backPath: string
};

export default function ProductionOptions(props: Props): Node {
  const {
    additional_offset_creative_cost,
    multiple_creatives,
    current_production_option,
    digital_estimated_cost,
    offset_estimated_cost,
    digital_price_per_piece,
    offset_price_per_piece,
    backPath
  } = props;
  const [selectedProductionOption, setSelectedProductionOption] =
    useState<ProcessType>(current_production_option);

  function submitProductionOptionSelectionForm() {
    const form = document.forms.manual_send_production_options_form;
    form.querySelector("input[type='submit']")?.click();
  }

  function changeProductionOption(optionType) {
    setSelectedProductionOption(optionType);
  }

  return (
    <div className="mt-5">
      <RailsFormData path="manual_send[process_type]" data={selectedProductionOption} />
      <ModalActions>
        <Button secondary label="Back" href={backPath} />
        <Button
          disabled={!selectedProductionOption}
          label="Next"
          onClick={submitProductionOptionSelectionForm}
        />
      </ModalActions>
      <div className="mb-4">
        <h5>
          <b>Choose Your Printing Method</b>
        </h5>
      </div>
      <ProductionOptionsSection
        additional_offset_creative_cost={additional_offset_creative_cost}
        multiple_creatives={multiple_creatives}
        digital_estimated_cost={digital_estimated_cost}
        offset_estimated_cost={offset_estimated_cost}
        digital_price_per_piece={digital_price_per_piece}
        offset_price_per_piece={offset_price_per_piece}
        selectedProductionOption={selectedProductionOption}
        onClick={changeProductionOption}
      />
    </div>
  );
}

const ProductionOption = ({
  preference,
  type,
  title,
  description,
  bullet_points,
  estimated_cost,
  selected,
  onClick
}) => (
  <label className={`production-card ${selected ? "selected" : ""}`}>
    <dl className="production-card__header">
      <dt className="production-card__header-title">
        <h6 className="font-bold">{title}</h6>
      </dt>
      <dd>
        <span className={`production-card__header-description-${preference}`}>{description}</span>
      </dd>
    </dl>
    <div className="production-card__content">
      <ul>
        {bullet_points.map((point) => (
          <li key={point}>{point}</li>
        ))}
      </ul>
    </div>
    <p className="production-card_footer">
      <strong>{estimated_cost}</strong>
      <span className="block">Estimated Cost</span>
    </p>
    <input
      className="sr-only"
      type="radio"
      name="manual_send[production_option]"
      checked={selected}
      onChange={() => onClick(type)}
    />
  </label>
);

const ProductionOptionsSection = ({
  additional_offset_creative_cost,
  multiple_creatives,
  digital_estimated_cost,
  offset_estimated_cost,
  digital_price_per_piece,
  offset_price_per_piece,
  selectedProductionOption,
  onClick
}) => {
  const offset_bullet_points = ["Delivered in 2-3 weeks", `${offset_price_per_piece}/piece`];

  if (multiple_creatives) {
    offset_bullet_points.push(`${additional_offset_creative_cost} for each additional creative`);
  }

  const options = [
    {
      preference: "fast",
      type: "digital",
      title: "Digital Printing",
      description: "Fastest Delivery",
      bullet_points: ["Delivered in 5-9 business days", `${digital_price_per_piece}/piece`],
      estimated_cost: digital_estimated_cost
    },
    {
      preference: "price",
      type: "offset",
      title: "Offset Printing",
      description: "Best Price",
      bullet_points: offset_bullet_points,
      estimated_cost: offset_estimated_cost
    }
  ];
  return (
    <div className="production-options-container">
      {options.map((option) => (
        <ProductionOption
          key={option.type}
          type={option.type}
          preference={option.preference}
          title={option.title}
          description={option.description}
          bullet_points={option.bullet_points}
          estimated_cost={option.estimated_cost}
          selected={option.type == selectedProductionOption}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
