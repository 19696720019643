import React from "react";
import SelectTag from "@elements/SelectTag";
import RailsFormData from "@utilities/FormatRailsFormData";

class CategorySelector extends React.Component {
  constructor(props) {
    super(props);
    const { selectedCategory, selectedSubCategory } = this.props;
    this.state = {
      selectedCategory: selectedCategory,
      selectedSubCategory: selectedSubCategory,
      category_id: selectedSubCategory || selectedCategory || null
    };
    this._handleCategoryUpdate = this._handleCategoryUpdate.bind(this);
    this._handleSubCategoryUpdate = this._handleSubCategoryUpdate.bind(this);
    this._subCategoryChoices = this._subCategoryChoices.bind(this);
  }

  _handleCategoryUpdate(e) {
    this.setState({
      selectedCategory: e.target.value,
      selectedSubCategory: null,
      category_id: e.target.value
    });
  }

  _handleSubCategoryUpdate(e) {
    this.setState({
      selectedSubCategory: e.target.value,
      category_id: e.target.value
    });
  }

  _subCategoryChoices() {
    const { subCategoriesArray } = this.props;
    const { selectedCategory } = this.state;
    if (selectedCategory === null) {
      return [];
    } else {
      return subCategoriesArray.filter((subcategory) => subcategory.parent_id === selectedCategory);
    }
  }

  render() {
    const { selectedCategory, selectedSubCategory, category_id } = this.state;
    const { categoriesArray } = this.props;
    const subCategoryChoices = this._subCategoryChoices();

    return (
      <div className="grid grid-cols-2 gap-6">
        <RailsFormData path="organization" data={{ category_id: category_id }} />
        <SelectTag
          required
          data={categoriesArray}
          label="Category"
          value={selectedCategory || ""}
          onChange={this._handleCategoryUpdate}
        />
        <SelectTag
          includeBlank
          data={subCategoryChoices}
          label="SubCategory *"
          disabled={subCategoryChoices.length === 0}
          value={selectedSubCategory || ""}
          onChange={this._handleSubCategoryUpdate}
        />
      </div>
    );
  }
}

export default CategorySelector;
