import React from "react";

const ProgressBar = ({ percent, message }) => (
  <div className="text-center progress-bar__container">
    <span className="progress-bar__message">{message}</span>
    <div className="mt-4 overflow-hidden bg-gray-200 rounded-full progress-bar__bar w-96">
      <div className="h-3 progress-bar__meter bg-jade-500" style={{ width: `${percent}%` }} />
    </div>
  </div>
);

export default ProgressBar;
