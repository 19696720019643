import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  static classes = ["open", "closed"]

  static values = {
    closeOnBlur: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.btn = this.element.querySelector("[data-action='toggler#toggle']");
    this.togglable = this.toggleableTarget || this.element.getElementById(this.btn.getAttribute("aria-controls"));

    if (this.closeOnBlurValue) {
      this.element.addEventListener("blur", this.blurHandler.bind(this), true);
      this.element.addEventListener("keydown", this.escapeHandler.bind(this), true);
    }
  }

  escapeHandler(e) {
    if (e.key === "Escape") {
      this.setOpen(false);
    }
  }

  blurHandler() {
    setTimeout(() => {
      if (!this.element.contains(document.activeElement)) {
        this.setOpen(false);
      }
    });
  }

  setOpen(show) {
    this.btn.setAttribute("aria-expanded", show);
    this.togglable.dataset.open = show;
    if (this.openClasses) {
      this.togglable.classList.toggle(...this.openClasses, show);
    }
    if (this.closedClasses) {
      this.togglable.classList.toggle(...this.closedClasses, !show);
    }
  }

  toggle(e) {
    const {currentTarget: btn} = e;
    const isOpen = btn.getAttribute("aria-expanded") === "true";
    this.setOpen(!isOpen);
  }
}
