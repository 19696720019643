//@flow

import React, { Component } from "react";
import _ from "lodash";
import throttledQueue from "throttled-queue";
import Papa from "papaparse";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import consumer from "@channels/consumer";
import type { Subscription } from "@rails/actioncable";
import ProgressBar from "@elements/ProgressBar";
import { parseCsv } from "@utilities/FileParsing";
import {
  fetchUpload,
  fetchBatch,
  fetchRecords,
  sendBatch,
  processUpload,
  cancelUpload
} from "@utilities/api/Upload";
import type { UploadResponse, FetchRecordsResponse } from "@utilities/api/Upload";
import type {
  BatchErrors,
  RecordHash,
  MappingHash,
  FlattendMappedDataObject
} from "@utilities/UploadHelpers";
import DeepCopyArrayOfObjects from "@utilities/DeepCopyArrayOfObjects";
import { updateRecordsHash } from "@utilities/UploadHelpers";
import StateErrorBoundary from "./StateErrorBoundary";
import Review from "./Review";
import ColumnMapper from "./ColumnMapper";
import FileSelect from "./FileSelect";
import FileParser from "./FileParser";
import type {
  UploaderType,
  UploaderStates,
  UploaderProps,
  UploaderState,
  WebsocketData,
  Record,
  RecordSubObject
} from "./types";
import UploaderProgress from "./UploaderProgress";
import { distinct } from "@utilities/arrayHelpers";

// This is used to rate limit large quantities of network requests.
// It is opt-in - see `_sendBatch` for an example.
// This configuration allows 5 per second, with only 1 request in flight at a time.
const rateLimiter = throttledQueue(5, 1000);

const INITIAL_STATE = "initializing";

// 60 second delay before we assume the websocket connection has failed
const WEBSOCKET_FALLBACK_DELAY = 60_000;

export class Uploader extends Component<UploaderProps, UploaderState> {
  setTimeoutID: ?TimeoutID = null;
  handleFetchedRecords: function;
  uploadChannel: Subscription;

  constructor(props: UploaderProps) {
    super(props);

    this.state = {
      batchesProcessed: [],
      currentRecordPage: 1,
      data: [],
      errorHash: {},
      fallbackTriggered: false,
      file: null,
      mapped: false,
      mappedData: [],
      mappingHash: {},
      state: INITIAL_STATE,
      totalRecordPages: 0,
      upload: {},
      uploadChunks: 0,
      uploadFetched: false,
      uploadProcessedChunks: 0
    };

    // fix for Flow(method-unbinding) errors
    const self: any = this;

    self._cancelUpload = self._cancelUpload.bind(this);
    self._finalizeUpload = self._finalizeUpload.bind(this);
    self._handleBatchProcessed = self._handleBatchProcessed.bind(this);
    self._onColumnMappingComplete = self._onColumnMappingComplete.bind(this);
    self._onParseComplete = self._onParseComplete.bind(this);
    self._setUpload = self._setUpload.bind(this);
    self._sendBatch = self._sendBatch.bind(this);
    self._sendBatches = self._sendBatches.bind(this);
    self._handleWebsocketEvent = self._handleWebsocketEvent.bind(this);
    self._setFallbackTimer = self._setFallbackTimer.bind(this);
    self._handleFallbackTimerExpired = self._handleFallbackTimerExpired.bind(this);
    self._checkBatchStatus = self._checkBatchStatus.bind(this);
    self._onFileSelectComplete = self._onFileSelectComplete.bind(this);
    self.changeState = self.changeState.bind(this);
  }

  componentDidMount() {
    fetchUpload({
      url: this.props.uploadUrl,
      onComplete: (upload: UploadResponse) => {
        this.uploadChannel = consumer.subscriptions.create(
          { channel: "UploadChannel", upload_id: upload.id },
          {
            connected: this._setUpload(upload),
            received: (data) => {
              this._handleWebsocketEvent(data);
            }
          }
        );
      }
    });
  }

  /**
   * Clear fallback timer and unsubscribe from websockets on unmount
   */
  componentWillUnmount() {
    clearTimeout(this.setTimeoutID);
    this.uploadChannel.unsubscribe();
  }

  _handleWebsocketEvent(data: WebsocketData) {
    const { uploadUrl } = this.props;
    const { event, batch_id }: WebsocketData = data;

    console.log(event, batch_id, data);
    if (event === "batch_processed") {
      this._handleBatchProcessed(data);
    }
  }

  changeState(state: UploaderStates) {
    this.setState({ state });
  }

  _setUpload(upload: UploadResponse) {
    const { id } = upload;
    const { state } = this.state;
    const newState = state === INITIAL_STATE ? "file_select" : state;

    // If this is not valid response we do not want to be
    // updating state else it will corrupt our URLs.
    if (id) {
      Sentry.configureScope((scope) => scope.setExtra("upload_id", id));
      this.setState({ upload, state: newState });
    } else {
      Sentry.captureMessage("Invalid Upload Response");
      console.error("Invalid upload response");
      this.changeState("error");
    }
  }

  _onFileSelectComplete(file: File) {
    this.setState({ file: file });
    this.changeState("parsing");
  }

  _onParseComplete(results: Array<Record>, _file: File) {
    this.setState({ data: results });
    // Waits 1 second before getting rid of the progress bar so that very small files will show "progress" for at least 1 second
    setTimeout(() => this.changeState("column_mapper"), 1000);
  }

  _onColumnMappingComplete(mappedData: Array<Record>) {
    this.changeState("uploading");
    this.setState({ mappedData }, () => this._sendBatches());
  }

  _sendBatches(): void {
    const { mappedData } = this.state;
    const records: Array<Object> = DeepCopyArrayOfObjects(mappedData);
    const chunks: Array<Array<Object>> = _.chunk(records, 1000);

    // $FlowIgnore[method-unbinding]
    _.forEach(chunks, this._sendBatch);

    this.setState({ uploadChunks: chunks.length });
  }

  _sendBatch(records: Array<Object>): void {
    const { upload } = this.state;
    const { records_url } = upload;

    rateLimiter(() =>
      sendBatch({
        id: uuidv4(),
        records,
        url: records_url,
        onComplete: (uploadResponse) => this._setUpload(uploadResponse)
      })
    )
      .then((_result) => {
        const { uploadProcessedChunks } = this.state;
        const newUploadProcessedChunks = uploadProcessedChunks + 1;

        this.setState(
          {
            uploadProcessedChunks: newUploadProcessedChunks
          },
          () => this._setFallbackTimer()
        );
      })
      .catch((error) => {
        // If we run out of retries, this function gets called for the batch.
        Sentry.captureMessage(error);
        console.error("rateLimiter catch", error);
        this.changeState("error");
      });
  }

  _handleBatchProcessed({ records: batchErrors, batch_id }: WebsocketData) {
    const { uploadUrl } = this.props;
    const { errorHash, uploadChunks, batchesProcessed, fallbackTriggered } = this.state;
    const newBatchesProcessed = [...batchesProcessed, batch_id].filter(distinct);
    const newErrorHash = updateRecordsHash(batchErrors, errorHash);
    const uploadComplete = uploadChunks > 0 && uploadChunks === newBatchesProcessed.length;

    this.setState({ errorHash: newErrorHash, batchesProcessed: newBatchesProcessed });

    if (uploadComplete) {
      console.log("uploadComplete");
      clearTimeout(this.setTimeoutID);
      fetchUpload({
        url: uploadUrl,
        onComplete: (uploadResponse) => this._setUpload(uploadResponse)
      });
      console.info(
        `${
          Object.keys(errorHash).length
        } rows checked for errors (this should match the number of records)`
      );
      this.setState({ uploadFetched: true });
    } else if (!fallbackTriggered) {
      this._setFallbackTimer();
    }
  }

  _cancelUpload(): void {
    const { upload } = this.state;
    const { cancel_url } = upload;

    cancelUpload({
      url: cancel_url,
      onComplete: (response) => this._handleUploadActionResponse(response, "Cancel", "cancelled")
    });
  }

  _finalizeUpload(options: ?Object): void {
    const { config } = this.props;
    const { destination_type } = config;
    const { upload } = this.state;
    const { finalize_url, edit_url } = upload;

    if (destination_type === "ManualSend") {
      processUpload({
        url: edit_url,
        options: options,
        onComplete: (response) => this._handleUploadActionResponse(response, "Finalize")
      });
    } else {
      processUpload({
        url: finalize_url,
        options: options,
        onComplete: (response) =>
          this._handleUploadActionResponse(response, "Finalize", "ready_to_process")
      });
    }
  }

  _handleUploadActionResponse(
    upload: UploadResponse,
    responseType: string,
    requiredState?: string
  ) {
    const { id, state } = upload;
    const { redirectUrl } = this.props;

    if (id && (!requiredState || state === requiredState)) {
      window.location.replace(redirectUrl);
    } else {
      const message = `Invalide ${responseType} Response`;
      Sentry.captureMessage(message);
      console.error(message, `state: ${state}`);
      this.changeState("error");
    }
  }

  /**
   * Fallback methods
   *
   * If there are no websocket events for 60 seconds, we assume the websocket connection has failed,
   * and begin polling the upload API for upload status. Once the upload has completed processing
   * the upload API is used to fetch the processed batches.
   */
  _setFallbackTimer(): void {
    const { uploadChunks, uploadProcessedChunks, batchesProcessed } = this.state;
    clearTimeout(this.setTimeoutID);
    if (
      uploadChunks > 0 &&
      uploadProcessedChunks === uploadChunks &&
      batchesProcessed.length < uploadChunks
    ) {
      // $FlowIgnore[method-unbinding]
      this.setTimeoutID = setTimeout(this._handleFallbackTimerExpired, WEBSOCKET_FALLBACK_DELAY);
    }
  }

  _handleFallbackTimerExpired() {
    const { uploadUrl } = this.props;
    const { upload } = this.state;
    Sentry.setContext("Upload", {
      ID: upload.id
    });
    Sentry.captureMessage("Upload WebSocket Connection Failed");
    this.setState({ fallbackTriggered: true });
    this._checkBatchStatus(upload, 0);
  }

  _checkBatchStatus(upload: UploadResponse, delay: number = 3000): void {
    const {
      status_url,
      id,
      metadata: { batches_ready, batches }
    } = upload;

    if (id && batches_ready) {
      this._fetchBatches(batches);
    } else if (!["cancelled", "failed"].includes(upload.state)) {
      setTimeout(
        () =>
          fetchUpload({
            url: status_url,
            onComplete: (statusResponse) => this._checkBatchStatus(statusResponse)
          }),
        delay
      );
    }
  }

  _fetchBatches(batches: Array<string>) {
    const {
      batchesProcessed,
      upload: { batch_url }
    } = this.state;
    console.log(batch_url, batches);
    batches.forEach((batch) => {
      if (!batchesProcessed.includes(batch)) {
        rateLimiter(() => {
          fetchBatch({
            url: `${batch_url}${batch}`,
            onComplete: (batchResponse) => this._handleBatchProcessed(batchResponse)
          });
        });
      }
    });
  }

  render(): React$Element<any> {
    const {
      data,
      errorHash,
      file,
      mappingHash,
      state,
      upload,
      uploadChunks,
      uploadFetched,
      uploadProcessedChunks,
      mappedData
    } = this.state;
    const { config, mergeTags, addressStrictness } = this.props;

    let component;

    switch (state) {
      // Phase 1: Fetch the upload and set state
      case "initializing":
        component = <LoadingIndicator />;
        break;
      // Phase 2: Display the File Select
      default:
      case "file_select":
        // $FlowIgnore[method-unbinding]
        component = <FileSelect onDrop={this._onFileSelectComplete} config={config} />;
        break;
      // Phase 3: Parse the CSV file to create the data array
      case "parsing":
        // $FlowIgnore[method-unbinding]
        component = <FileParser file={file} onParseComplete={this._onParseComplete} />;
        break;
      // Phase 4: Show the column mapper
      case "column_mapper":
        component = (
          <ColumnMapper
            exampleColumns={2}
            csvRows={data}
            // $FlowIgnore[method-unbinding]
            onMappingComplete={this._onColumnMappingComplete}
            fields={config.fields}
            mergeTags={mergeTags}
            destinationType={config.destination_type}
          />
        );
        break;
      // Phase 5: Upload the mapped CSV data in chunks
      case "uploading":
        component = (
          <UploaderProgress
            totalBatches={uploadChunks}
            uploadedBatches={uploadProcessedChunks}
            totalRecords={data.length}
            processedRecords={Object.keys(errorHash).length}
            onComplete={() => this.changeState("review")}
          />
        );
        break;
      // Phase 6: File upload is complete, display the review screen
      case "review":
        component = (
          <div>
            <Review
              upload={upload}
              uploadComplete={uploadFetched}
              recordCount={data.length}
              errorHash={errorHash}
              mappedData={mappedData}
              // $FlowIgnore[method-unbinding]
              onCancel={this._cancelUpload}
              // $FlowIgnore[method-unbinding]
              onFinalize={this._finalizeUpload}
              options={config.options || {}}
              minimumValidRecords={config.minimum_valid_records}
              minimumValidRecordsErrorMessage={config.minimum_valid_records_error_message}
              destinationType={config.destination_type}
              addressStrictness={addressStrictness}
            />
          </div>
        );
        break;
      case "error":
        component = <NetworkIssueWarning />;
        break;
    }

    return (
      <div className="uploader__container">
        <div className="uploader__component">
          <StateErrorBoundary>{component}</StateErrorBoundary>
        </div>
      </div>
    );
  }
}

export const LoadingIndicator = (): React$Element<any> => (
  <div className="spinner">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export const NetworkIssueWarning = (): React$Element<any> => (
  <div>
    <p>
      Sorry, we were having trouble connecting to Poplar. Please verify your internet connection,
      reload and try again.
    </p>
  </div>
);

export default Uploader;
