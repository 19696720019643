import { Controller } from "stimulus"
import consumer from "@channels/consumer";

export default class extends Controller {
  static targets = ["id", "front", "back", "thumbnail"];

  static values = {
    id: String,
    campaign: String,
    creative: String,
    view: String
  }

  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "CreativeProofChannel",
        creative_proof_id: this.idValue,
        creative_id: this.creativeValue
      },
      {
        received: (data) => {
          if (data.status === "completed") {
            this.reloadFrame(data);
          }
        }
      }
    )
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  submit(_event) {}

  reloadFrame(data) {
    const frame = document.querySelector(`turbo-frame[id='creative_${this.creativeValue}']`);
    const campaignId = this.campaignValue;
    const creativeId = this.creativeValue;
    const src = `/campaigns/${campaignId}/creative/${creativeId}/proofs/${data.id}`;

    if (frame) {
      // Reload the frame by resetting its src attribute
      frame.src = src;
    }
  }
}
