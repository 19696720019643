// @flow

// Remove unexpected characters from strings that might cause downstream issues with
// data persistance / passing data to APIs.

const STRICT_REGEX = /[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g;
const BASIC_REGEX = /[\x00-\x08\x0E-\x1F]/g;

const regexTypes = {
  strict: STRICT_REGEX,
  basic: BASIC_REGEX
};

export const removeInvalidChars = (value: string) => {
  return value.replace(regexTypes.basic, "");
};
