import React from "react";
import RailsFormData from "@utilities/FormatRailsFormData";
import Mapper from "./Mapper";

class Details extends React.Component {
  constructor(props){
    super(props);

    const geofences = [props.geofence, ...props.children];
    const count = geofences.length;

    this.state = {
      count,
      index: 0,
      geofences,
      coordinates: {},
      maxIndex: count - 1
    };

    this._nextGeofence = this._nextGeofence.bind(this);
    this._previousGeofence = this._previousGeofence.bind(this);
    this._onCoordinatesChange = this._onCoordinatesChange.bind(this);
  }

  _nextGeofence(){
    const { index, maxIndex } = this.state;
    let nextIndex = (index + 1);
    if (nextIndex > maxIndex) nextIndex = 0;

    this.setState({ index: nextIndex });
  }

  _previousGeofence(){
    const { index, maxIndex } = this.state;
    let previousIndex = (index - 1);
    if (previousIndex < 0) previousIndex = maxIndex;

    this.setState({ index: previousIndex });
  }

  _onCoordinatesChange(id, newCoordinates){
    const { coordinates } = this.state;
    coordinates[id] = newCoordinates;

    this.setState({ coordinates });
  }

  render(){
    const { index, count, geofences, coordinates } = this.state;
    const { googleApiKey } = this.props;

    return (
      <div className="geofence-details">
        <Header
          index={index}
          count={count}
          show={count > 1}
          onNextClick={this._nextGeofence}
          onPreviousClick={this._previousGeofence}
        />
        {geofences.map((geofence, i) => (
          <Geofence
            key={geofence.id}
            geofence={geofence}
            display={i === index}
            coordinates={coordinates[geofence.id]}
            onCoordinatesChange={this._onCoordinatesChange}
            googleApiKey={googleApiKey}
          />
        ))}
      </div>
    );
  }
}

const Header = ({
  index,
  count,
  show,
  onNextClick,
  onPreviousClick
}) => {
  if (!show) return null;

  return (
    <div className="geofence-details__header">
      <Count
        index={index}
        count={count}
      />
      <Navigation
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </div>
  );
};

const Count = ({ index, count }) => (
  <p className="geofence-details__count">{`${index + 1} of ${count}`}</p>
);

const Navigation = ({ onNextClick, onPreviousClick }) => (
  <div className="geofence-details__navigation">
    <button
      type="button"
      aria-label="Previous"
      onClick={onPreviousClick}
      className="geofence-details__button geofence-details__button--previous"
    />
    <button
      type="button"
      aria-label="Next"
      onClick={onNextClick}
      className="geofence-details__button geofence-details__button--next"
    />
  </div>
);

const Geofence = ({
  display,
  geofence,
  coordinates,
  onCoordinatesChange,
  googleApiKey
}) => (
  <div className="geofence-details__geofence" style={{ display: display ? "inherit" : "none" }}>
    {coordinates?.length > 0
        && <RailsFormData path={geofence.object_name} data={{ coordinates }} />}
    <Mapper
      display={display}
      geofences={[geofence]}
      googleApiKey={googleApiKey}
      onCoordinatesChange={onCoordinatesChange}
      editable={Object.keys(geofence.error).length === 0}
    />
    <div
      className="geofence-details__form"
      dangerouslySetInnerHTML={{ __html: geofence.form }}
    />
  </div>
);

export default Details;
