import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "errorBanner"];

  connect() {
    this.fields = Array.from(this.formTarget.elements).filter((f) => f.tagName === "SELECT");
    this.audienceMembersCount = 0;
    this.formTarget.noValidate = true;
    this.formTarget.addEventListener("submit", this.submitHandler.bind(this), true);

    this.loadMergeTagsStats();
    this.initializeIconTooltips();
  }

  // These are the merge tags coming from the creative and mapping the missing % of audience members.
  // Example: {"offer-code":"100%","expiry-date":"100%","another-offer-code":"100%"}
  loadMergeTagsStats() {
    const mergeTagsStatsText = document.getElementById('merge-tags-stats')?.textContent || '';

    try {
      this.mergeTagsStats = JSON.parse(mergeTagsStatsText)
    } catch {
      console.warn('Merge tags stats element not found');
    }
  }

  submitHandler(e) {
    if(e.submitter.name === "save_and_close") { return true; }

    this.fields.forEach(this.toggleErrorClass);

    this.toggleErrorBanner();

    if (this.showErrorBanner){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onChangeHandler(e) {
    const input = e.target;

    this.toggleErrorClass(input);

    if (this.showErrorBanner) {
      this.toggleErrorBanner();
    }

    this.toggleIconTooltip(input);
  }

  toggleErrorClass(input) {
    input.closest("tr").classList.toggle("has-error", !input.checkValidity());
  }

  toggleErrorBanner() {
    this.showErrorBanner = !this.formTarget.checkValidity();

    this.errorBannerTarget.classList.toggle("hidden", !this.showErrorBanner);
  }

  initializeIconTooltips() {
    this.element.querySelectorAll('select').forEach((input) => {
      this.toggleIconTooltip(input);
    });
  }

  toggleIconTooltip(input) {
    const selectedValue = input.value;
    const tr = input.closest("tr");
    const tooltipIcon = tr.querySelector('span[data-react-class="Tooltip"]');
    const mergeTagMissingPercentage = this.mergeTagsStats?.[selectedValue];

    if (!tooltipIcon) {
      return;
    }

    if (!selectedValue || !mergeTagMissingPercentage || mergeTagMissingPercentage === "0%") {
      return tooltipIcon.classList.add("hidden");
    }

    tooltipIcon.dataset.reactProps = tooltipIcon.dataset.reactProps.replace(
      /%{percentage}/,
      mergeTagMissingPercentage
    );

    tooltipIcon.classList.remove("hidden");
    ReactRailsUJS.mountComponents('span[data-react-class="Tooltip"]'); // Re-mount React components so it picks up the new props and refreshes the tooltip
  }
}
