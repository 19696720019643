import _ from "lodash";
import React from "react";
import pluralize from "pluralize";
import RailsFormData from "@utilities/FormatRailsFormData";

class Suppressions extends React.Component {
  constructor(props){
    super(props);

    const suppressionAudiencesMatchCampaign = _.isEqual(
      _.sortBy(_.map(props.suppressionAudiences, "audience_id")),
      _.sortBy(_.map(props.campaignSuppressionAudiences, "audience_id"))
    );

    const source = _.isEmpty(props.suppressionAudiences)
      || suppressionAudiencesMatchCampaign ? "campaign" : "selected";

    const selectedAudiences = source === "campaign"
      ? props.audiences : _.unionBy(props.suppressionAudiences, props.audiences, "audience_id");

    const mappedSuppressionAudiences = _.keyBy(props.suppressionAudiences, "audience_id");
    const mappedCampaignSuppressionAudiences = _.keyBy(props.campaignSuppressionAudiences, "audience_id");

    const suppressionAudiences = source === "campaign"
      ? calculateSuppressionChangeset(mappedSuppressionAudiences, mappedCampaignSuppressionAudiences) : [];

    const campaignAudiences = _.intersectionWith(
      props.audiences,
      props.campaignSuppressionAudiences,
      (audience, campaignAudience) => (audience.audience_id === campaignAudience.audience_id)
    );

    this.state = {
      source,
      suppressionAudiences,
      mappedSuppressionAudiences,
      mappedCampaignSuppressionAudiences,
      campaignAudiences: campaignAudiences,
      campaignAudienceCountTag: campaignAudienceCountTag(props.campaignSuppressionAudiences),
      selectedAudiences: _.mapValues(
        _.keyBy(selectedAudiences, "audience_id"), (a) => { return (source === "campaign" ? true : !!a.id); }
      )
    };

    this._selectionChanged = this._selectionChanged.bind(this);
    this._sourceChanged = this._sourceChanged.bind(this);
  }

  _selectionChanged(e){
    const { selectedAudiences, mappedSuppressionAudiences } = this.state;
    const { audiences, doNotMailId } = this.props;

    if (e.target.value === "all"){
      _.forEach(audiences, (audience) => {
        if (audience.audience_id === doNotMailId){ return; }
        selectedAudiences[audience.audience_id] = e.target.checked;
      });
    } else {
      selectedAudiences[e.target.value] = e.target.checked;
    }

    this.setState({
      selectedAudiences,
      suppressionAudiences: calculateSuppressionChangeset(mappedSuppressionAudiences, _.pickBy(selectedAudiences))
    });
  }

  _sourceChanged(e){
    const source = e.target.value;

    const { selectedAudiences, mappedSuppressionAudiences, mappedCampaignSuppressionAudiences } = this.state;

    const proposedSuppressionAudiences = source === "campaign"
      ? mappedCampaignSuppressionAudiences : _.pickBy(selectedAudiences);

    this.setState({
      source: e.target.value,
      suppressionAudiences: calculateSuppressionChangeset(mappedSuppressionAudiences, proposedSuppressionAudiences)
    });
  }

  render(){
    const {
      campaignAudienceCountTag,
      campaignAudiences,
      selectedAudiences,
      source,
      suppressionAudiences
    } = this.state;
    const { audiences, doNotMailId } = this.props;
    const formData = { suppression_audiences_attributes: suppressionAudiences };

    return (
      <div className="manual-send-suppressions">
        <RailsFormData path="manual_send[list_attributes]" data={formData} />
        <div className="manual-send-suppressions__sources">
          <CampaignSettings
            source={source}
            audiences={campaignAudiences}
            onSourceChange={this._sourceChanged}
            audienceCountTag={campaignAudienceCountTag}
          />
          <AudiencePicker
            source={source}
            audiences={audiences}
            doNotMailId={doNotMailId}
            onSourceChange={this._sourceChanged}
            selectedAudiences={selectedAudiences}
            onSelectionChange={this._selectionChanged}
          />
        </div>
      </div>
    );
  }
}

const CampaignSettings = ({
  audienceCountTag,
  audiences,
  onSourceChange,
  source
}) => (
  <div className="manual-send-suppressions__campaign-settings">
    <input
      id="campaign"
      name="source"
      type="radio"
      value="campaign"
      onChange={onSourceChange}
      checked={source === "campaign"}
      className="manual-send-suppressions__source"
    />
    <label
      htmlFor="campaign"
      className="manual-send-suppressions__source-label"
    >
      {`Use my campaign settings ${audienceCountTag}`}
    </label>
    <CampaignAudiences
      audiences={audiences}
    />
  </div>
);

const CampaignAudiences = ({ audiences }) => {
  const audienceMedian = Math.ceil(audiences.length / 2);

  return (
    <div className="manual-send-suppressions__campaign-audiences grid-x medium-offset-1">
      <div className="cell medium-6">
        <ul>
          {audiences.slice(0, audienceMedian).map(audience => (
            <CampaignAudience audience={audience} />
          ))}
        </ul>
      </div>
      <div className="cell medium-6">
        <ul>
          {audiences.slice(audienceMedian).map(audience => (
            <CampaignAudience audience={audience} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const CampaignAudience = ({ audience }) => (
  <li key={audience.audience_id} className="manual-send-suppressions__campaign-audience">
    <p className="preview-attribute__value">
      <AudienceTag name={audience.name} />
    </p>
  </li>
);

const AudiencePicker = ({
  audiences,
  doNotMailId,
  onSelectionChange,
  onSourceChange,
  selectedAudiences,
  source
}) => (
  <div className="manual-send-suppressions__audience-picker">
    <input
      id="selected"
      name="source"
      type="radio"
      value="selected"
      onChange={onSourceChange}
      checked={source === "selected"}
      className="manual-send-suppressions__source"
    />
    <label
      htmlFor="selected"
      className="manual-send-suppressions__source-label"
    >
      Pick specific audiences
    </label>
    <Audiences
      source={source}
      audiences={audiences}
      doNotMailId={doNotMailId}
      onChange={onSelectionChange}
      selectedAudiences={selectedAudiences}
    />
  </div>
);

const Audiences = ({
  audiences,
  doNotMailId,
  onChange,
  selectedAudiences,
  source
}) => {
  if (source === "campaign") return null;

  const audienceMedian = Math.ceil(audiences.length / 2);
  const allChecked = _.every(_.values(selectedAudiences));
  const allUnchecked = _.every(_.values(selectedAudiences), (a) => (!a));

  return (
    <div className="manual-send-suppressions__audiences">
      <div className="medium-offset-1">
        <label htmlFor="all" className="preview-attribute__value">
          <input
            id="all"
            value="all"
            type="checkbox"
            checked={allChecked}
            onChange={onChange}
            ref={el => el && (el.indeterminate = (!allChecked && !allUnchecked))}
          />
          All
        </label>
      </div>
      <div className="grid-x medium-offset-1">
        <div className="cell medium-6">
          {audiences.slice(0, audienceMedian).map(audience => (
            <Audience
              audience={audience}
              onChange={onChange}
              doNotMailId={doNotMailId}
              key={audience.audience_id}
              checked={selectedAudiences[audience.audience_id]}
            />
          ))}
        </div>
        <div className="cell medium-6">
          {audiences.slice(audienceMedian).map(audience => (
            <Audience
              audience={audience}
              onChange={onChange}
              doNotMailId={doNotMailId}
              key={audience.audience_id}
              checked={selectedAudiences[audience.audience_id]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Audience = ({
  audience,
  checked,
  onChange,
  doNotMailId
}) => (
  <div className="manual-send-suppressions__audience">
    <label htmlFor={audience.audience_id}>
      <input
        id={audience.audience_id}
        value={audience.audience_id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={audience.audience_id === doNotMailId}
      />
      <AudienceTag
        name={audience.name}
      />
    </label>
  </div>
);

const campaignAudienceCountTag = (campaignSuppressionAudiences) => {
  const count = _.size(campaignSuppressionAudiences);

  return (`(${count.toLocaleString()} ${pluralize("audience", count)})`);
};

const AudienceTag = ({name}) => (
  <div className="manual-send-suppressions__audience-tag">
    {name}
  </div>
);

const calculateSuppressionChangeset = (persistedSuppressionAudiences, proposedSuppressionAudiences) => {
  const suppressionChangeset = [];
  const difference = _.xor(_.keys(persistedSuppressionAudiences), _.keys(proposedSuppressionAudiences));

  _.forEach(difference, (audience_id) => {
    const existingAudience = persistedSuppressionAudiences[audience_id];

    if (existingAudience){
      suppressionChangeset.push({ id: existingAudience.id, _destroy: true });
    } else {
      suppressionChangeset.push({ audience_id: audience_id });
    }
  });

  return suppressionChangeset;
};

export default Suppressions;
