import React from "react";
import * as Sentry from "@sentry/browser";

export class StateErrorBoundary extends React.Component {
  static getDerivedStateFromError(_error){
    return { hasError: true };
  }

  constructor(props){
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, _errorInfo){
    Sentry.captureException(error);
  }

  render(){
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError){
      return (
        <p>Something went wrong.</p>
      );
    }

    return children;
  }
}

export default StateErrorBoundary;
