// @flow
import React, { useState, useEffect, Fragment } from "react";
import type { Node } from "react";

export function LazyLoadedBarChart(barProps: any): Node {
  const [barComponent, setBarComponent] = useState(null);

  useEffect(() => {
    import("react-chartjs-2").then(({ Bar }) => {
      setBarComponent(React.createElement(Bar, barProps));
    });
  }, [barProps]);

  if (!barComponent) {
    return <Fragment />;
  } else {
    return barComponent;
  }
}

export function LazyLoadedLineChart(lineProps: any): Node {
  const [lineComponent, setLineComponent] = useState(null);

  useEffect(() => {
    import("react-chartjs-2").then(({ Line }) => {
      setLineComponent(React.createElement(Line, lineProps));
    });
  }, [lineProps]);

  if (!lineComponent) {
    return <Fragment />;
  } else {
    return lineComponent;
  }
}

export function useLazyLoadedAnnotationImport():any {
  const [library, setLibrary] = useState("loading");

  useEffect(() => {
    import("chartjs-plugin-annotation").then((loadedLibrary) => {
      setLibrary(loadedLibrary.default);
    });
  }, []);

  return library;
}
