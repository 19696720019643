// @flow
import React from "react";
import classNames from "classnames";
import navigateToUrl from "@utilities/navigateToUrl";
import type { Campaign, TableBodyProps } from "./types";
import type { Node } from "react";
import buildSearchQueryForApi from "./BuildSearchQueryForApi";

export default function TableBody({
  campaigns,
  selectedColumns,
  startDate,
  endDate
}: TableBodyProps): Node {
  return (
    <tbody>
      {campaigns.map(
        ({
          archived,
          campaign_id,
          name,
          last_updated,
          last_mailed,
          campaign_icon_url,
          status,
          ...campaign
        }: Campaign) => (
          <tr
            className={classNames("campaign-row", { disabled: archived })}
            key={campaign_id}
            onClick={(e) => {
              e.preventDefault();
              navigateToUrl(campaignUrl(campaign_id, startDate, endDate), e);
            }}
            data-href={`/campaigns/${campaign_id}`}
          >
            <CreativePreviewAndCampaignName
              campaign_id={campaign_id}
              name={name}
              creativePreviewImage={campaign_icon_url}
            />
            <CampaignStatus status={status} />
            <td className="text-left">{last_updated}</td>
            <td className="text-left">{last_mailed}</td>
            {selectedColumns.map((columnKey) => {
              if (campaign[columnKey]?.value) {
                const { value, holdout } = campaign[columnKey];

                return (
                  <td className="text-right" key={campaign_id + columnKey}>
                    {value}
                    {holdout && (
                      <div className="row__secondary">
                        <span className="sr-only">Holdout:</span>
                        {holdout}
                      </div>
                    )}
                  </td>
                );
              } else {
                return <td />;
              }
            })}
          </tr>
        )
      )}
    </tbody>
  );
}

/**
 * Get the campaign URL with the search query if set
 */
function campaignUrl(campaignId: string, startDate: string, endDate: string): string {
  let campaignUrl = `/campaigns/${campaignId}`;
  if (window.location.search && startDate && endDate) {
    const searchQuery = buildSearchQueryForApi({
      start_date: startDate,
      end_date: endDate
    });
    campaignUrl += `?${searchQuery.toString()}`;
  }
  return campaignUrl;
}

function CreativePreviewAndCampaignName({ name, creativePreviewImage, campaign_id }) {
  return (
    <th scope="row" className="sticky-column">
      <a
        href={`/campaigns/${campaign_id}`}
        className="row__link"
        title={name || "Untitled Campaign"}
      >
        <div className="row__icon">
          <img alt="" src={creativePreviewImage} />
        </div>
        <div className="row__info">
          <div className="row__title">
            <span>{name || "Untitled Campaign"}</span>
          </div>
          <div className="row__secondary">Holdout</div>
        </div>
      </a>
    </th>
  );
}

function CampaignStatus({ status }) {
  return (
    <td className="text-left">
      <div className={`status ${status.toLowerCase()}`}>
        <span className="status__label">{status}</span>
      </div>
    </td>
  );
}
