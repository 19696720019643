// @flow

import React, { useState, useCallback } from "react";
import type { Node } from "react";
import Button from "@elements/Button";
import TeamMemberModal from "@components/AccountTeam/TeamMemberModal";
import type { Role } from "./types";

type Props = {
  userRoles: Array<Role>,
  showRoles: boolean
};

export default function AddTeamMember({ userRoles, showRoles }: Props): Node {
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <React.Fragment>
      <Button
        label="Add Team Member"
        onClick={() => setShowModal(true)}
        onKeyPress={(e) => e.key === "Enter" && setShowModal(true)}
        tabIndex={0}
      />
      <TeamMemberModal
        heading="Add Team Member"
        formAction="/account/team"
        userRoles={userRoles}
        showRoles={showRoles}
        open={showModal}
        closeModal={closeModal}
      />
    </React.Fragment>
  );
}
