// @flow
import React, { useState } from "react";
import AdditionalRatesFields from "./AdditionalRatesFields";
import CreativeTypesFields from "./CreativeTypesFields";
import RateDetailsFields from "./RateDetailsFields";
import { RateGroupProvider } from "./context";
import { generateInitialState } from "./helpers";
import type { RateGroupFormProps } from "./types";

function RateGroupForm({
  rateGroup,
  clone,
  rateFields,
  rates
}: RateGroupFormProps): React$Node {
  const [initialState] = useState(() => generateInitialState({ rateGroup, clone, rateFields, rates }));

  return (
    <div id="rate-group-form">
      <RateGroupProvider initialState={initialState}>
        <RateDetailsFields />
        <CreativeTypesFields />
        <AdditionalRatesFields fields={rateFields.additionalFields} />
      </RateGroupProvider>
    </div>
  );
}

export default RateGroupForm;
