import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.input = this.element.querySelector('input[type="datetime-local"]')

    if (this.element.tagName !== 'INPUT', this.element.type !== 'datetime-local') {
      return;
    }
  }

  now() {
    if (this.input) {
      this.input.value = new Date().toISOString().substr(0, 16)
      this.input.dispatchEvent(new Event('input', {bubbles: true}))
    }
  }
}
