import { Controller } from "@hotwired/stimulus";

/**
 * Toggle Items Based on Field Input Events
 *
 * Checkbox Usage Example:
 *
 * <div data-controller="field-toggle" data-field-toggle-method-value="disable">
 *   f.check_box :enable_refill, data: { field_toggle_target: 'input' }
 *   f.input :refill_amount, data: { field_toggle_target: 'item' }, disabled: true
 * </div>
 *
 * Radio Usage Example:
 *
 * <div data-controller="field-toggle" data-field-toggle-method-value="hide">
 *   button_group(f, :budget_type, [:circulation, :cost], '', data: {field_toggle_target: 'input' })
 *   form.text_field :circulation_budget, data: { field_toggle_target: 'item', field_toggle_show_value: 'circulation'}, class: 'hidden'
 *   form.text_field :cost_budget, data: { field_toggle_target: 'item', field_toggle_show_value: 'cost'}, class: 'hidden'
 * </div>
 */
export default class extends Controller {
  static targets = [
    "input", // The element that triggers the input event
    "item" // The items that are toggled
  ];

  static values = {
    method: String // The method for toggling (disable | hide)
  };

  connect() {
    this.changeHandler = this.changeHandler.bind(this);
    this.radioChangeHandler = this.radioChangeHandler.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);

    // Assign the correct toggle method based on the `method` value
    this.toggleMethod =
      this.methodValue === "disable"
        ? this.toggleDisabled.bind(this)
        : this.toggleHidden.bind(this);

    this.inputTarget.addEventListener("input", this.changeHandler);

    this.initializeDisabledInputs();
    this.initializeRadioSelection();
  }

  /**
   * Track inputs that are disabled on page load to prevent enabling when toggling elements
   */
  initializeDisabledInputs() {
    const inputs = this.element.querySelectorAll("input, select, textarea");
    inputs.forEach((input) => {
      if (input.disabled) {
        input.dataset.disabled = true
      }
    })
  }

  initializeRadioSelection() {
    const checkedRadioButton = this.inputTarget.querySelector('input[type="radio"]:checked');
    if (checkedRadioButton) {
      this.toggleRadioTargets(checkedRadioButton.value);
    }
  }

  /**
   * Trigger the appropriate change handler based on input type
   */
  changeHandler(e) {
    switch (e.target.type) {
      case "radio":
        this.radioChangeHandler(e);
        break;

      case "checkbox":
        this.checkboxChangeHandler(e);
        break;
    }
  }

  /**
   * Toggle items based on whether or not the checkbox is checked
   */
  checkboxChangeHandler(e) {
    this.itemTargets.forEach((element) => {
      this.toggleMethod(element, !e.target.checked);
    });
  }

  radioChangeHandler(e) {
    this.toggleRadioTargets(e.target.value);
  }

  /**
   * Toggle items based on whether their show value equals the value of the checked radio
   */
  toggleRadioTargets(value) {
    this.itemTargets.forEach((element) => {
      // "apples, oranges" => ["apples", "oranges"]
      const showValues =
        element.dataset.fieldToggleShowValue?.split(",")?.map((i) => i.trim()) || [];
      this.toggleMethod(element, !showValues.includes(value));
    });
  }

  /**
   * Show/Hide an Element
   */
  toggleHidden(element, hide) {
    element.classList.toggle("hidden", hide);

     this.disableChildInputs(element, hide)
  }

  /**
   * Enable/disable all inputs within the hidden element
   * If `hidden == true`, this prevents inputs from being submitted to server.
   */
  disableChildInputs(element, hide) {
    const inputs = element.querySelectorAll("input, select, textarea");

    inputs.forEach((input) => {
      const disable = hide ? true : !!input.dataset.disabled
      this.toggleDisabled(input, disable);
    });
  }

  /**
   * Enable/Disable an Element
   */
  toggleDisabled(element, disable) {
    element.disabled = disable;
  }
}
