import _ from "lodash";
import React from "react";

class CreativePreviewer extends React.Component {
  constructor(props){
    super(props);

    const count = props.creatives.length;

    this.state = {
      index: 0,
      count: count,
      max_index: count - 1
    };

    this._nextCreative = this._nextCreative.bind(this);
    this._previousCreative = this._previousCreative.bind(this);
  }

  _nextCreative(){
    const { index, max_index } = this.state;
    let next_index = (index + 1);
    if (next_index > max_index) next_index = 0;

    this.setState({ index: next_index });
  }

  _previousCreative(){
    const { index, max_index } = this.state;
    let previous_index = (index - 1);
    if (previous_index < 0) previous_index = max_index;

    this.setState({ index: previous_index });
  }

  render(){
    const { index, count } = this.state;
    const { creatives } = this.props;
    const creative = creatives[index];

    return (
      <div className="manual-send-creative-previewer">
        <Header
          showNavigation={count > 1}
          onNextClick={this._nextCreative}
          onPreviousClick={this._previousCreative}
        />
        <div className="manual-send-creative-previewer__content">
          <Artworks creative={creative} />
          <Details
            index={index}
            count={count}
            creative={creative}
          />
        </div>
      </div>
    );
  }
}

const Header = ({ showNavigation, onNextClick, onPreviousClick }) => (
  <div className="manual-send-creative-previewer__header">
    <h5 className="manual-send-creative-previewer__title">Artwork(s)</h5>
    <Navigation
      show={showNavigation}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    />
  </div>
);

const Navigation = ({ show, onNextClick, onPreviousClick }) => {
  if (!show) return null;

  return (
    <div className="manual-send-creative-previewer__navigation">
      <button
        type="button"
        aria-label="Previous"
        onClick={onPreviousClick}
        className="manual-send-creative-previewer__button manual-send-creative-previewer__button--previous"
      />

      <button
        type="button"
        aria-label="Next"
        onClick={onNextClick}
        className="manual-send-creative-previewer__button manual-send-creative-previewer__button--next"
      />
    </div>
  );
};

const Artworks = ({ creative }) => (
  <div className="manual-send-creative-previewer__artworks">
    {creative.artworks.map(artwork => <Artwork url={artwork.url} key={artwork.url} />)}
  </div>
);

const Artwork = ({ url }) => (
  <img className="manual-send-creative-previewer__artwork" src={url} alt="artwork" />
);

const Details = ({ index, count, creative }) => (
  <div className="manual-send-creative-previewer__details">
    <p className="manual-send-creative-previewer__count">{`${index + 1} of ${count}`}</p>

    <Detail
      label="Creative Name"
      value={creative.name}
    />

    <Detail
      label="Postage Type"
      value={creative.mail_type}
    />

    <Detail
      label="Creative Type"
      value={creative.creative_type}
    />

    {creative.details.map(detail => <Detail key={detail.slug} label={detail.title} value={detail.value} />)}
  </div>
);

const Detail = ({ label, value }) => {
  let casted_value = value;

  if (_.isBoolean(value)) casted_value = (value ? "Yes" : "No");

  return (
    <div>
      <label className="manual-send-creative-previewer__detail-label">{label}</label>
      <p className="manual-send-creative-previewer__detail-value">{casted_value}</p>
    </div>
  );
};

export default CreativePreviewer;
