// @flow

import React from "react";
import _ from "lodash";
import pluralize from "pluralize";
import Button from "@elements/Button";
import Tooltip from "@components/Tooltip";
import ModalActions from "@elements/ModalPortals";

type ArtworkHash = {
  url: string,
  slug: string
};

type MergeTagHash = {
  [name: string]: string
};

type CreativeMergeTagHash = {
  [name: string]: boolean
};

type UploadMergeTagHash = {
  [name: string]: number
};

type CreativeObject = {
  id: string,
  name: string,
  merge_tags: MergeTagHash,
  artworks: ArtworkHash[]
};

type CheckMergeTagsProps = {
  creativeMergeTags: CreativeMergeTagHash,
  creatives: CreativeObject[],
  previousWizardPath: string,
  uploadMergeTags: UploadMergeTagHash,
  validRecords: number
};

type CheckMergeTagsState = {
  creatives: CreativeObject[],
  alerts: MergeTagHash
};

class CheckMergeTags extends React.Component<CheckMergeTagsProps, CheckMergeTagsState> {
  constructor(props: CheckMergeTagsProps) {
    super(props);

    const alerts: MergeTagHash = {};

    const { creativeMergeTags, creatives, uploadMergeTags, validRecords } = props;

    _.forEach(Object.keys(creativeMergeTags), (mergeTag: string) => {
      if (mergeTag in uploadMergeTags) {
        const difference: number = validRecords - uploadMergeTags[mergeTag];

        if (difference > 0) {
          alerts[mergeTag] = `Missing data in ${difference} ${pluralize("row", difference)}`;
        }
      } else {
        alerts[mergeTag] = "Missing column";
      }
    });

    const filteredCreatives: CreativeObject[] = _.filter(creatives, (c: CreativeObject) => {
      return Object.keys(c.merge_tags).length >= 1;
    });

    this.state = { alerts, creatives: filteredCreatives };
  }

  render(): React$Element<"div"> {
    const { previousWizardPath } = this.props;
    const { alerts, creatives } = this.state;
    const severeIssue = Object.keys(alerts).length > 0;

    return (
      <div className="manual-send-merge-tags">
        <SevereIssueMessage severeIssue={severeIssue} />
        <Creatives alerts={alerts} creatives={creatives} />
        <ModalActions>
          <Button secondary label="Back" onClick={stepBack.bind(this, previousWizardPath)} />
          <Button label="Next" onClick={submitForm.bind(this, severeIssue)} />
        </ModalActions>
      </div>
    );
  }
}

const submitForm = (issue: boolean): void => {
  const result: boolean = issue ? window.confirm(NEXT_MESSAGE) : true; // eslint-disable-line no-alert
  const form: ?HTMLElement = document.getElementById("submit_target");

  if (result && form instanceof HTMLFormElement) {
    form.submit();
  }
};

const stepBack = (previousWizardPath: string): void => {
  const result: boolean = window.confirm(BACK_MESSAGE); // eslint-disable-line no-alert

  if (result) {
    window.location.href = previousWizardPath;
  }
};

const SevereIssueMessage = ({
  severeIssue
}: {
  severeIssue: boolean
}): React$Element<"div"> | null => {
  if (!severeIssue) {
    return null;
  }

  return (
    <div className="callout alert">
      <p>{SEVERE_MESSAGE}</p>
    </div>
  );
};

const creativeBlocks = (
  creatives: CreativeObject[],
  alerts: MergeTagHash
): React$Element<typeof Creative>[] =>
  creatives.map((creative: CreativeObject) => (
    <Creative alerts={alerts} key={creative.id} creative={creative} />
  ));

const Creatives = ({
  creatives,
  alerts
}: {
  creatives: CreativeObject[],
  alerts: MergeTagHash
}): React$Element<"div"> => (
  <div className="manual-send-merge-tags__creatives">{creativeBlocks(creatives, alerts)}</div>
);

const Creative = ({
  creative,
  alerts
}: {
  creative: CreativeObject,
  alerts: MergeTagHash
}): React$Element<"div"> => (
  <div key={creative.id} className="manual-send-merge-tags__creative">
    <h2>{creative.name}</h2>
    <Artworks artworks={creative.artworks} />
    <MergeTags alerts={alerts} mergeTags={creative.merge_tags} />
  </div>
);

const artworkThumbnails = (artworks: ArtworkHash[]): React$Element<typeof Artwork>[] =>
  artworks.map((artwork) => <Artwork key={artwork.url} url={artwork.url} />);

const Artworks = ({ artworks }: { artworks: ArtworkHash[] }): React$Element<"div"> => (
  <div className="manual-send-merge-tags__creative-artworks">{artworkThumbnails(artworks)}</div>
);

const Artwork = ({ url }: { url: string }): React$Element<"div"> => (
  <div className="manual-send-merge-tags__creative-artwork">
    <img src={url} alt="artwork" />
  </div>
);

const mergeTagRows = (mergeTags: MergeTagHash, alerts): React$Element<typeof MergeTag>[] => {
  const sortedMergeTags: string[] = _.sortBy(Object.keys(mergeTags), (mergeTag: string) => {
    return !(alerts[mergeTag] && !mergeTags[mergeTag]);
  });

  return sortedMergeTags.map((mergeTag: string) => (
    <MergeTag key={mergeTag} name={mergeTag} alerts={alerts} defaultValue={mergeTags[mergeTag]} />
  ));
};

const MergeTags = ({
  mergeTags,
  alerts
}: {
  mergeTags: MergeTagHash,
  alerts: MergeTagHash
}): React$Element<"div"> => (
  <div className="manual-send-merge-tags__creative-merge-tags">
    <table>
      <thead>
        <tr>
          <th>Merge Tag</th>
          <th>Alerts</th>
          <th>Default Value</th>
        </tr>
      </thead>
      <tbody>{mergeTagRows(mergeTags, alerts)}</tbody>
    </table>
  </div>
);

const MergeTag = ({
  name,
  alerts,
  defaultValue
}: {
  name: string,
  alerts: MergeTagHash,
  defaultValue: string
}): React$Element<"tr"> => {
  const severe: boolean = name in alerts;
  const warning: boolean = name in alerts && !!defaultValue;

  const prefix: string = severe && !warning ? "Issue" : "Warning";
  const alert: string = name in alerts ? `${prefix}: ${alerts[name]}` : "";

  const baseClass: string = "manual-send-merge-tags__creative-merge-tag";
  const tooltip: React$Element<typeof Tooltip> | null = warning ? (
    <Tooltip value={TOOLTIP_MESSAGE} />
  ) : null;
  const icon: React$Element<typeof Tick> | React$Element<typeof Checkmark> = severe ? (
    <Tick />
  ) : (
    <Checkmark />
  );

  return (
    <tr
      className={`${baseClass} ${
        severe ? "manual-send-merge-tags__creative-merge-tag--severe" : ""
      }`}
    >
      <td className="manual-send-merge-tags__creative-merge-tag-name">
        <span className="manual-send-merge-tags__creative-merge-tag-icon">{icon}</span>
        <p>{name}</p>
      </td>
      <td className="manual-send-merge-tags__creative-merge-tag-alert">
        <p>{alert}</p>
        {tooltip}
      </td>
      <td className="manual-send-merge-tags__creative-merge-tag-default-value">
        <p>{defaultValue}</p>
      </td>
    </tr>
  );
};

const Tick = (): React$Element<"svg"> => (
  <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="#eb5757">
      <path d="m13.159637 3-11.159637 10.023099" />
      <path d="m2 3 11.159693 10.023036" />
    </g>
  </svg>
);

const Checkmark = (): React$Element<"svg"> => (
  <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m.3623 4.40140058 4.0877 3.57033942 7.9123-7"
      fill="none"
      stroke="#00a373"
      transform="translate(2 4)"
    />
  </svg>
);

const SEVERE_MESSAGE: string =
  "Your data file is missing merge tags needed for the Creative(s) below." +
  " Please review and go back if you need to upload missing columns.";

const NEXT_MESSAGE: string =
  "Are you sure you want to continue?" +
  "\r\n\r\nSome of your Creative merge tags are missing and/or don't have default values to fall back on.";

const TOOLTIP_MESSAGE: string =
  "You can still continue. We'll use the default value if the data is missing.";

const BACK_MESSAGE: string =
  "Note: Your current file will be removed and you will have to re-upload a new file.";

export default CheckMergeTags;
