//@flow

import React, { Component } from "react";
import Tooltip from "@components/Tooltip";
import SelectTag from "@elements/SelectTag";
import Button from "@elements/Button";
import ModalActions from "@elements/ModalPortals";
import classNames from "classnames";
import AlertIcon from "react-feather/dist/icons/alert-triangle";

import type { Node } from "react";
import {
  generateInitialMappingHash,
  selectWithDefaults,
  mapCsvData
} from "@utilities/UploadHelpers";
import type { MappingHash, FieldsObject } from "@utilities/UploadHelpers";
import type { Record } from "./types";

const HIDE_COLUMNS = ["merge_tags", "_uuid"];

type ColumnMapperProps = {
  csvRows: Array<Record>,
  onMappingComplete: (mappedData: Array<Record>) => void,
  fields: FieldsObject,
  exampleColumns: number,
  destinationType: string,
  mergeTags?: Array<string>
};

type ColumnMapperState = {
  requiredFields: Array<string>,
  blankPercents: BlankPercent,
  ignoreUnmapped: boolean,
  mappingHash: MappingHash
};

type BlankPercent = {
  [field: string]: number
};

type SelectChoice = {
  value: string,
  label: string
};
// eslint-disable-next-line react/prefer-stateless-function
export class ColumnMapper extends Component<ColumnMapperProps, ColumnMapperState> {
  constructor(props: ColumnMapperProps) {
    super(props);

    const { fields, mergeTags, csvRows } = props;
    const mappingHash = generateInitialMappingHash(fields, mergeTags, csvRows);
    const requiredFields = this._requiredFields(props);
    const blankPercents = {};
    Object.keys(csvRows[0]).forEach((field: string) => {
      blankPercents[field] = Math.ceil(
        (100 * csvRows.filter((cur) => cur[field] === "").length) / csvRows.length
      );
    });

    this.state = {
      requiredFields: requiredFields,
      blankPercents: blankPercents,
      ignoreUnmapped: false,
      mappingHash
    };

    this._requiredFields = this._requiredFields.bind(this);
    this._selectChoices = this._selectChoices.bind(this);
    this._onMappingChange = this._onMappingChange.bind(this);
    this._onMappingComplete = this._onMappingComplete.bind(this);
  }

  _onMappingChange(originalHeader: string, newHeader: string) {
    const { mappingHash } = this.state;
    const newMappingHash = mappingHash;
    if (newHeader === "") {
      delete newMappingHash[originalHeader];
    } else {
      newMappingHash[originalHeader] = newHeader;
    }
    this.setState({ mappingHash: newMappingHash });
  }

  _onMappingComplete() {
    const { mappingHash }: { mappingHash: MappingHash } = this.state;
    const { csvRows, fields, onMappingComplete } = this.props;
    const mappedData = mapCsvData(csvRows, mappingHash, fields);

    onMappingComplete(mappedData);
  }

  _requiredFields({ fields }: Object): Array<string> {
    return Object.entries(fields).flatMap(([field, { required }]) => (required ? [field] : []));
  }

  _selectChoices(originalKey: string): Array<SelectChoice> {
    const { fields, mergeTags } = this.props;
    const { mappingHash } = this.state;
    const selectChoices = [];
    selectChoices.push({ value: " ", label: "" });
    Object.keys(fields).forEach((slmField: string) => {
      if (
        !fields[slmField].accepts_merge_tags &&
        (!(
          mappingHash[originalKey] !== slmField && Object.values(mappingHash).includes(slmField)
        ) ||
          fields[slmField].allow_multiple_values === true)
      ) {
        selectChoices.push({
          value: slmField,
          label: fields[slmField].required
            ? `${fields[slmField].label} (Required)`
            : fields[slmField].label
        });
      } else if (mergeTags && fields[slmField].accepts_merge_tags) {
        mergeTags.forEach((mergeTag) => {
          if (
            !(
              mappingHash[originalKey] !== mergeTag && Object.values(mappingHash).includes(mergeTag)
            )
          ) {
            selectChoices.push({
              value: mergeTag,
              label: `${mergeTag} (Merge Tag)`
            });
          }
        });
      } else if (
        !Object.keys(fields).includes(originalKey) &&
        this.props.destinationType == "Audience" &&
        fields[slmField].accepts_merge_tags
      ) {
        // Allow unrecognized fields by SLM to be tracked as _merge tags_ for `Audiences`
        selectChoices.push({
          value: `${originalKey}`,
          label: "Merge Tag"
        });
      }
    });
    selectChoices.push({ value: "", label: "Ignore this column" });
    return selectChoices;
  }

  render(): React$Element<any> {
    const { csvRows, fields, onMappingComplete, exampleColumns, destinationType } = this.props;
    const { requiredFields, blankPercents, ignoreUnmapped, mappingHash } = this.state;
    const safeExampleColumns = Math.min(exampleColumns, csvRows.length);
    const requiredFieldsMissing = requiredFields.filter(
      (requiredField) => !Object.values(mappingHash).includes(requiredField)
    );

    const unmappedColumns = Object.values(mappingHash).filter((value: any) => value === " ");

    const mappingError = getMappingError({
      fields,
      ignoreUnmapped,
      mappingHash,
      requiredFieldsMissing,
      unmappedColumns
    });
    const disableSubmit = !!mappingError;

    return (
      <div>
        {destinationType === "ManualSend" ? (
          <ModalActions>
            <Button secondary label="Back" onClick={stepBack.bind(this)} />
            <Button
              label="Next"
              disabled={disableSubmit}
              onClick={submitForm.bind(this, this._onMappingComplete)}
            />
          </ModalActions>
        ) : null}
        <p>Please map each column from the uploaded file to the appropriate field.</p>
        <div className="w-max">
          <ErrorMessage message={mappingError} />
          <div className="tableholder">
            <table className="slm-column-mapping-table">
              <thead>
                <tr>
                  <th>CSV Column</th>
                  <th className="p-0 w-6"></th>
                  <th>Mapping</th>
                  <th>Example Data</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {Object.keys(csvRows[0]).map((originalKey: string) => {
                  if (HIDE_COLUMNS.includes(originalKey)) {
                    return null;
                  }
                  const items = this._selectChoices(originalKey);
                  const selected = selectWithDefaults(items, mappingHash[originalKey]);
                  return (
                    <tr key={originalKey}>
                      <td
                        className={classNames("slm-column-mapping-table__primary-column", {
                          "slm-column-mapping-table__primary-column-disabled": !selected
                        })}
                      >
                        {originalKey}
                      </td>
                      <td className="p-0 w-6">
                        <MissingValuesTooltip blankPercent={blankPercents[originalKey]} />
                      </td>
                      <td>
                        <SelectTag
                          data={items.map(({ value, label }) => ({ id: value, name: label }))}
                          onChange={({ target }) =>
                            this._onMappingChange(originalKey, target.value)
                          }
                          label={`Mapping for "${originalKey}" column`}
                          labelClass="sr-only"
                          value={selected}
                          className="w-full"
                        />
                      </td>
                      <td className="space-y-1">
                        <ExampleValues
                          safeExampleColumns={safeExampleColumns}
                          csvRows={csvRows}
                          originalKey={originalKey}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="my-4">
            <label className="text-base">
              <input
                className="mr-2"
                type="checkbox"
                checked={ignoreUnmapped}
                onChange={({ target }) => this.setState({ ignoreUnmapped: target.checked })}
              />
              Ignore all unmapped columns
            </label>
          </div>

          {destinationType !== "ManualSend" && (
            <>
              <button
                className="slm-button"
                type="submit"
                onClick={this._onMappingComplete}
                disabled={disableSubmit}
              >
                Continue
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

type getMappingErrorProps = {
  fields: FieldsObject,
  ignoreUnmapped: boolean,
  mappingHash: MappingHash,
  requiredFieldsMissing: Array<string>,
  unmappedColumns: Array<string>
};

const getMappingError = ({
  fields,
  ignoreUnmapped,
  mappingHash,
  requiredFieldsMissing,
  unmappedColumns
}) => {
  const unmappedCount = unmappedColumns.length;

  if (requiredFieldsMissing.length) {
    return <RequiredFieldsMessage fields={fields} requiredFieldsMissing={requiredFieldsMissing} />;
  } else if (unmappedCount && !ignoreUnmapped) {
    return `You have ${unmappedCount} unmapped column${unmappedCount === 1 ? "" : "s"}`;
  } else if (unmappedCount === Object.values(mappingHash).length) {
    return "Please provide a mapping for at least 1 CSV column to continue.";
  }
  return null;
};

export const ErrorMessage = ({ message }: { message: Node }): Node => {
  return message ? (
    <div className="p-3 mb-6 rounded alert flash flash-error">
      <p className="m-0">{message}</p>
    </div>
  ) : null;
};

type RequiredFieldsMessageProps = {
  fields: FieldsObject,
  requiredFieldsMissing: string[]
};

export const RequiredFieldsMessage = ({
  fields,
  requiredFieldsMissing
}: RequiredFieldsMessageProps): Node => {
  const fieldsString = requiredFieldsMissing
    .map((field) => (
      <strong className="font-semibold" key={field}>
        {fields[field].label}
      </strong>
    ))
    .reduce((prev, curr, i) => [
      prev,
      i === requiredFieldsMissing.length - 1 ? " and " : ", ",
      curr
    ]);

  return (
    <>
      A CSV column must be mapped to the {fieldsString}
      {requiredFieldsMissing.length > 1 ? " fields" : " field"} to continue.
    </>
  );
};

type ExampleValuesProps = {
  safeExampleColumns: number,
  csvRows: Array<CsvRow>,
  originalKey: string
};

export const ExampleValues = ({
  safeExampleColumns,
  csvRows,
  originalKey
}: ExampleValuesProps): React$Element<any>[] =>
  [...new Array(safeExampleColumns).keys()].map((i) => (
    <div
      className="text-sm text-gray-500 truncate slm-column-mapping-table__example-data"
      key={`example-cell-${i}`}
      title={csvRows[i][originalKey]}
    >
      {csvRows[i][originalKey] || "—"}
    </div>
  ));

type MissingValuesTooltipProps = {
  blankPercent: number
};

export const MissingValuesTooltip = ({ blankPercent }: MissingValuesTooltipProps): Node => {
  if (blankPercent > 0) {
    return (
      <Tooltip
        className="ml-1"
        value={`This column is missing a value in ${blankPercent}% of rows`}
      >
        <AlertIcon size="18" className="text-gray-950 fill-yellow-500" />
      </Tooltip>
    );
  } else {
    return null;
  }
};

const submitForm = (onMappingComplete: () => void): void => {
  onMappingComplete();
};

const stepBack = (): void => {
  window.location.reload();
};

export default ColumnMapper;
