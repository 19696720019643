import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  retry(event) {
    event.target.value = "Processing...";

    setTimeout(() => {
      window.location.reload(true);
    }, 30000);
  }
}
