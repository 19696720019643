// @flow

import React, { useEffect, useState } from "react";
import ProgressBar from "@elements/ProgressBar";
import { parseCsv } from "@utilities/FileParsing";

type FileParserProps = {
  file: File | null,
  onParseComplete: (csvRows: any[], file: File) => void
};

/**
 * Displays a progress bar while parsing a CSV file
 */
function FileParser({ file, onParseComplete }: FileParserProps): React$Element<any> {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (file) {
      parseCsv(file, setProgress, onParseComplete);
    }
  }, [file]);

  return (
    <div className="uploader__thumb-container flex-align-center">
      <div className="uploader__progress">
        <ProgressBar percent={progress} message="Upload in Progress" />
      </div>
    </div>
  );
}

export default FileParser;
