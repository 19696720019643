// @flow

import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "react-feather/dist/icons/search";
import Toolbar from "@components/Toolbar";
import TextInput from "@elements/TextInput";
import LoadingSpinner from "@elements/LoadingSpinner";

import type { Node } from "react";
import type { ToolbarProps } from "./CampaignsTable/types";

type Props = {
  initialSearchTerm: string,
  toolbarProps: ToolbarProps
};

export default function HistoryToolbar({ initialSearchTerm, toolbarProps }: Props): Node {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm || "");
  const [loading, setLoading] = useState(false);
  const loadingTimeoutId = useRef();

  function onSearchInputChange(e) {
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    const hiddenTurboFrameSearchInputElement: HTMLInputElement =
      document.getElementById("history-search");

    if (hiddenTurboFrameSearchInputElement) {
      hiddenTurboFrameSearchInputElement.value = searchTerm;
      turboFrameFormSubmit(hiddenTurboFrameSearchInputElement.form);

      // debounce loading state to reduce loading flicker
      clearTimeout(loadingTimeoutId.current);
      loadingTimeoutId.current = setTimeout(() => {
        setLoading(true);
      }, 200);
    }

    return () => clearTimeout(loadingTimeoutId.current);
  }, [searchTerm]);

  useEffect(() => {
    const onFrameLoaded = () => {
      clearTimeout(loadingTimeoutId.current);
      setLoading(false);
    };
    document.addEventListener("turbo:frame-load", onFrameLoaded);

    return () => window.removeEventListener("turbo:frame-load", onFrameLoaded);
  }, []);

  return (
    <div className="grid-x grid-margin-x">
      <div className="mt-2 toolbar-grid">
        <div className="flex">
          <TextInput
            autoFocus
            className="mb-0 search-input w-72"
            label="Search"
            placeholder="name, address, city, zip, email..."
            icon={<SearchIcon size="18" />}
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={onSearchInputChange}
          />
          {loading && (
            <div className="flex items-center justify-center ml-4 text-gray-500">
              <LoadingSpinner />
              Loading Mailings
            </div>
          )}
        </div>
        <Toolbar
          {...toolbarProps}
          urlQuery={`&search_term=${searchTerm}`}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
}

function turboFrameFormSubmit(form) {
  form.requestSubmit();
}
