// @flow
import React from "react";
import classNames from "classnames";
import ChevronDownIcon from "react-feather/dist/icons/chevron-down";
import ChevronUpIcon from "react-feather/dist/icons/chevron-up";
import CodeIcon from "react-feather/dist/icons/code";
import type {
  TableHeadProps,
  ColumnHeaderProps,
  ColumnHeaderType,
  SortableColumnHeaderProps,
  SortIconProps
} from "./types";

const DEFAULT_COLUMN_HEADERS: { [key: string]: ColumnHeaderType } = {
  name_lower: {
    heading: "Campaign Name",
    label: "Campaign Name",
    className: "sticky-column text-left"
  },
  status: {
    heading: "Status",
    label: "Status",
    className: "text-left"
  },
  last_updated: {
    heading: "Last Updated",
    label: "Last Updated",
    className: "text-left"
  },
  last_mailed: {
    heading: "Last Mailed",
    label: "Last Mailed",
    className: "text-left"
  }
};

const DEFAULT_COLUMNS: Array<string> = Object.keys(DEFAULT_COLUMN_HEADERS);

export default function TableHead({
  columnHeaders,
  selectedColumns,
  sortableColumns,
  sortBy,
  toggleSortDirectionForColumn
}: TableHeadProps): React$Element<"thead"> {
  const allColumnHeaders = {
    ...DEFAULT_COLUMN_HEADERS,
    ...columnHeaders
  };
  const { column: sortColumn = "last_updated", direction: sortDirection = "desc" } = sortBy;

  return (
    <thead>
      <tr>
        {[...DEFAULT_COLUMNS, ...selectedColumns].map((headerKey) => {
          const { heading, className = "text-right" } = allColumnHeaders[headerKey];
          return sortableColumns.includes(headerKey) ? (
            <SortableColumnHeader
              className={className}
              key={headerKey}
              onSortDirectionClick={() => toggleSortDirectionForColumn(headerKey)}
              sortDirection={sortColumn === headerKey && sortDirection}
              text={heading}
            />
          ) : (
            <ColumnHeader key={headerKey} className={className} text={heading} />
          );
        })}
      </tr>
    </thead>
  );
}

function ColumnHeader({ className, text }: ColumnHeaderProps): React$Element<"th"> {
  return <th className={className}>{text}</th>;
}

const SORT_LABELS = {
  asc: "ascending",
  desc: "descending"
};

function SortIcon({ direction }: SortIconProps): React$Element<"svg"> {
  switch (direction) {
    case "asc":
      return <ChevronUpIcon size={12} strokeWidth="3" />;
    case "desc":
      return <ChevronDownIcon size={12} strokeWidth="3" />;
    default:
      return <CodeIcon size={12} strokeWidth="3" className="rotate-90" />;
  }
}

function SortableColumnHeader({
  className,
  text,
  sortDirection,
  onSortDirectionClick
}: SortableColumnHeaderProps): React$Element<"th"> {
  return (
    <th className={className} aria-sort={sortDirection ? SORT_LABELS[sortDirection] : null}>
      <button
        type="button"
        onClick={onSortDirectionClick}
        className={classNames("inline-flex items-center w-max -mr-0.5", {
          "text-black": !!sortDirection
        })}
      >
        <span>{text}</span>
        <span className="flex flex-col flex-shrink-0 pl-2">
          <SortIcon direction={sortDirection} />
        </span>
      </button>
    </th>
  );
}
