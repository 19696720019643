// @flow
import React, { useMemo, useState } from "react";
import _uniqueId from "lodash/uniqueId";
import type { Node } from "react";
import Tooltip from "@components/Tooltip";

type TagProps = {
  includeBlank?: boolean,
  label: string,
  onChange: (e: SyntheticInputEvent<>) => void,
  tooltip?: string,
  data: Array<Option>,
  value: string | number,
  labelClass?: string
};

const SelectTag = ({
  includeBlank,
  label,
  labelClass,
  onChange,
  tooltip,
  value = "",
  data,
  ...rest
}: TagProps): Node => {
  const id = useMemo(() => _uniqueId("select_"), []);
  const [selected, setSelected] = useState(value);

  const handleSelect = (e) => {
    setSelected(e.target.value);
    onChange(e);
  };
  return (
    <div>
      <label htmlFor={id} className={labelClass}>
        {label}
        {!!tooltip && <Tooltip id={`${id}-tip`} value={tooltip} />}
      </label>
      <div className="input-field">
        <select {...rest} id={id} onChange={handleSelect} value={selected}>
          {includeBlank && <option value="">Select</option>}
          {data.map((option) => (
            <SelectOption {...option} key={option.id} />
          ))}
        </select>
      </div>
    </div>
  );
};

export type Option = {
  id: string | number,
  name: string | number
};

const SelectOption = ({ id, name }: Option) => <option value={id}>{name}</option>;

export default SelectTag;
