// @flow

import React, { useState, useCallback } from "react";
import type { Node } from "react";
import IconButton from "@components/IconButton";
import TeamMemberModal from "@components/AccountTeam/TeamMemberModal";
import type { Role, RoleId } from "./types";

type Props = {
  currentRoleId: RoleId,
  userRoles: Array<Role>,
  userEmail: string,
  updatePath: string,
  showRoles: boolean
};

export default function EditTeamMember({
  userEmail,
  currentRoleId,
  userRoles,
  updatePath,
  showRoles
}: Props): Node {
  const [showModal, setShowModal] = useState<boolean>(false);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);
  return (
    <React.Fragment>
      <IconButton
        iconName="edit"
        onClick={() => setShowModal(true)}
        onKeyPress={(e) => e.key === "Enter" && setShowModal(true)}
        tabIndex={0}
        toolTipText="Edit"
      />
      <TeamMemberModal
        formAction={updatePath}
        userEmail={userEmail}
        currentRoleId={currentRoleId}
        userRoles={userRoles}
        showRoles={showRoles}
        open={showModal}
        heading="Edit Team Member"
        closeModal={closeModal}
      />
    </React.Fragment>
  );
}
