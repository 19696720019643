import React from "react";
import ProgressBar from "@elements/ProgressBar";

class CreativePreviewLoader extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      percentage: 0,
      iteration: 0
    };

    this.run = this.run.bind(this);
  }

  componentDidMount(){
    setInterval(this.run, 300);
  }

  run(){
    let {
      percentage,
      iteration
    } = this.state;
    const diff = 94 - percentage;
    const pi2 = Math.PI / 2;
    const n = 100;

    if (iteration === n){
      percentage = 94;
    } else {
      percentage = 0 + diff * Math.sin(pi2 * ((iteration + 1) / n));
      iteration += 1;
    }

    this.setState({ percentage, iteration });
  }

  render(){
    const { percentage } = this.state;

    return (
      <div className="creative-preview-loader">
        <div className="creative-preview-loader__progress">
          <ProgressBar
            percent={percentage}
            message="Rendering preview, please wait."
          />
        </div>
      </div>
    );
  }
}

export default CreativePreviewLoader;
