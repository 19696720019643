// @flow

import React, { Fragment, useState } from "react";
import type { Node } from "react";

import RadioButtonGroup from "@elements/RadioButtonGroup";

import type { PaymentCadence, Plan } from "./types";

type Props = {
  name: string,
  plan: Plan,
  screenReaderLegend: string,
  selectionOptions: Array<{ value: PaymentCadence, label: string }>,
  plan: Plan
};

export default function PlanPaymentCadence({
  name,
  plan,
  screenReaderLegend,
  selectionOptions
}: Props): Node {
  const [paymentCadence, setPaymentCadence] = useState<PaymentCadence>("annual");

  return (
    <Fragment>
      <RadioButtonGroup
        name={name}
        screenReaderLegend={screenReaderLegend}
        selectionOptions={selectionOptions}
        selectedValue={paymentCadence}
        valueOnChange={setPaymentCadence}
        plan={plan}
      />
      <PaymentCadenceSelectionDisplay paymentCadence={paymentCadence} plan={plan} />
    </Fragment>
  );
}

type PaymentCadenceSelectionDisplayProps = {
  paymentCadence: PaymentCadence,
  plan: Plan
};

function PaymentCadenceSelectionDisplay({
  paymentCadence,
  plan: { monthly_cost, annual_cost }
}: PaymentCadenceSelectionDisplayProps): Node {
  const display =
    paymentCadence === "monthly"
      ? { cost: monthly_cost, label: "month" }
      : { cost: annual_cost, label: "year" };

  return (
    <div className="flex items-center font-semibold whitespace-nowrap">
      <strong className="block my-8 text-5xl font-bold">{display.cost}</strong>
      <div className="mt-2 ml-4">
        <strong className="block leading-none">/ {display.label}</strong>
      </div>
    </div>
  );
}
