// @flow

/**
 * Returns a Promise that will resolve after the provided delay
 *
 * @example
 * await sleep(100)
 * foo()
 *
 * @example
 * sleep(100).then(foo)
 *
 * @example
 * foo().then(sleep(100)).then((fooResult) => bar(fooResult))
 */
const sleep =
  (delay: number): Function =>
    (x: any): Promise<any> =>
      new Promise((resolve: Function) => setTimeout(() => resolve(x), delay));

export default sleep;
