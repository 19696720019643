// @flow

import React, { useEffect, useState } from "react";
import ProgressBar from "@elements/ProgressBar";

type UploadProgressProps = {
  totalRecords: number,
  processedRecords: number,
  totalBatches: number,
  uploadedBatches: number,
  onComplete: () => void
};

type Message = "Processing" | "Uploading Records" | "Validating Records";

function UploaderProgress({
  totalRecords,
  processedRecords,
  totalBatches,
  uploadedBatches,
  onComplete
}: UploadProgressProps): React$Element<any> {
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<Message>("Processing");

  useEffect(() => {
    const uploadProgress = uploadedBatches / totalBatches;
    const validationProgress = processedRecords / totalRecords;
    const message = uploadProgress < 1 ? "Uploading Records" : "Validating Records";
    const progress = +(uploadProgress * 25 + validationProgress * 75).toFixed(2);

    setMessage(message);
    setProgress(progress);

    if (progress === 100) {
      onComplete();
    }
  }, [totalRecords, processedRecords, totalBatches, uploadedBatches]);

  return (
    <div className="uploader__progress-container">
      <ProgressBar percent={progress} message={message} />
    </div>
  );
}

export default UploaderProgress;
