// @flow
import React, { createContext, useContext, useMemo } from "react";
import { useImmer } from "use-immer";
import uniqueId from "lodash/uniqueId";
import type {
  CreativeTypeName,
  OffsetTiers,
  Rate,
  RateGroupDetails,
  RateGroupState
} from "./types";

const RateGroupContext: React$Context<any> = createContext([{}, () => {}]);

type RateGroupProviderProps = {
  initialState: RateGroupState,
  children: React$Node
};

function RateGroupProvider({ initialState, children }: RateGroupProviderProps): React$Node {
  const [state, setState]: [RateGroupState, () => void] = useImmer(initialState);

  const rateGroupContextValue = useMemo(() => [state, setState], [state, setState]);

  return (
    <RateGroupContext.Provider value={rateGroupContextValue}>{children}</RateGroupContext.Provider>
  );
}

/* eslint "no-param-reassign": ["error", { "props": false }] */
function useRateGroup(): {
  addOffsetTier: (creativeTypeId: string) => void,
  creativeTypes: RateGroupState["creativeTypes"],
  deleteOffsetTier: (creativeTypeId: string, tierId: string) => void,
  getRate: (id: string) => Rate,
  getOffsetTiers: (creativeTypeId: string) => OffsetTiers,
  setOffsetMinimum: (value: string, creativeTypeId: string, tierId: string) => void,
  setGroupValue: (key: $Keys<RateGroupDetails>, value: string|boolean) => void,
  setPrice: (id: string, price: string) => void,
  state: RateGroupState
  } {
  const [state, setState]: [RateGroupState, ({}) => void] = useContext(RateGroupContext);

  const addOffsetTier = (creativeTypeId: string): void => {
    setState((draft) => {
      draft.creativeTypes[creativeTypeId].offsetTiers[uniqueId()] = {};
    });
  };

  const deleteOffsetTier = (creativeTypeId: string, tierId: string): void => {
    setState((draft) => {
      draft.creativeTypes[creativeTypeId].offsetTiers[tierId].destroy = 1;
    });
  };

  const getOffsetTiers = (creativeTypeId: string): OffsetTiers => {
    return state.creativeTypes[creativeTypeId].offsetTiers || { minimum: "0" };
  };

  const getRate = (id: string): Rate => state.rates[id] || {};

  const setOffsetMinimum = (
    value: string,
    creativeTypeId: string,
    tierId: string
  ): void => {
    setState((draft) => {
      draft.creativeTypes[creativeTypeId].offsetTiers[tierId].minimum = value;
    });
  };

  const setGroupValue = (key: $Keys<RateGroupDetails>, value: string): void => {
    setState((draft) => {
      draft[key] = value;
    });
  };

  const setPrice = (id: string, price: string): void => {
    setState((draft) => {
      const rate = draft.rates[id];
      draft.rates[id] = { ...rate, price };
    });
  };

  return {
    addOffsetTier,
    creativeTypes: state.creativeTypes,
    deleteOffsetTier,
    getOffsetTiers,
    getRate,
    setOffsetMinimum,
    setGroupValue,
    setPrice,
    state
  };
}

export { RateGroupContext, RateGroupProvider, useRateGroup };
