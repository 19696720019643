// @flow
import type { SortBy } from "./types";

type BuildProps = {
  end_date?: string,
  searchTerm?: string,
  sortBy?: SortBy,
  start_date?: string
};

export default function buildSearchQueryForApi({
  end_date,
  searchTerm = "",
  sortBy: { column: sort_column, direction: sort_direction } = {},
  start_date
}: BuildProps): URLSearchParams {
  const query = new URLSearchParams();

  const sortParams = sort_column && sort_direction ? { sort_column, sort_direction } : {};
  const search_term = searchTerm?.length >= 3 ? searchTerm : "";

  const searchObj = {
    start_date,
    end_date,
    search_term,
    ...sortParams
  };

  Object.entries(searchObj).forEach(([key, value]) => {
    if (value) query.append(key, value);
  });

  return query;
}
