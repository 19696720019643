import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity", "totalDataCost"];

  static values = {
    pricePerPiece: Number
  };

  onQuantityChange() {
    this.totalDataCostTarget.textContent = this.formatCurrency(
      this.pricePerPieceValue * this.quantityTarget.value
    );
  }

  // 1234.56 --> $1,234.56
  formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }
}
