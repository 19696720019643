//@flow

import React, { useState } from "react";
import type { Node } from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import ConvertBytesToMegabytes from "@utilities/ConvertBytesToMegabytes";

type FileSelectProps = {
  onDrop: (file: File) => void,
  config: {
    documentation_footer_link?: string,
    documentation_footer_text?: string,
    documentation_header_link?: string,
    documentation_header_link_text?: string,
    documentation_header_text?: string,
    max_file_size_error?: string
  }
};

const ACCEPTED_TYPES: Array<string> = [
  "text/csv",
  "application/x-csv",
  "application/csv",
  "application/vnd.ms-excel",
  ""
];
const ACCEPTED_TYPES_HUMAN_READABLE: Array<string> = ["CSV"];
const MAXIMUM_FILE_SIZE: number = 20000000; // 20Mb
const MAXIMUM_FILE_SIZE_DEFAULT_ERROR_MSG: string =
  "The file size limit is 20MB. Please contact support@heypoplar.com for assistance.";

export function FileSelect({ config, onDrop }: FileSelectProps): Node {
  const [hasFileSizeError, setHasFileSizeError] = useState(false);
  const [hasUnknownError, setHasUnknownError] = useState(false);
  const dropzoneDisabled = false;

  function handleDrop(acceptedFiles: Array<File>, rejectedFiles: Array<File>) {
    if (rejectedFiles.length > 0) {
      if (rejectedFiles.some((file) => file.size > MAXIMUM_FILE_SIZE)) {
        setHasFileSizeError(true);
      } else {
        setHasUnknownError(true);
      }
    } else if (acceptedFiles.length > 0) {
      onDrop(acceptedFiles[0]);
    }
  }

  return (
    <div>
      <h5 className="mb-2 text-lg">Select a File</h5>
      {!!(
        config.documentation_header_text ||
        (config.documentation_header_link && config.documentation_header_link_text)
      ) && (
        <p>
          {config.documentation_header_text}
          <a
            target="_blank"
            href={config.documentation_header_link}
            style={{ paddingLeft: "2px" }}
            rel="noreferrer"
          >
            {config.documentation_header_link_text}
          </a>
        </p>
      )}
      {hasFileSizeError && <FileSizeError max_file_size_error={config.max_file_size_error} />}
      {hasUnknownError && <FileUnknownError />}
      <div className="uploader__file-select__container">
        <Dropzone
          accept={ACCEPTED_TYPES}
          disabled={dropzoneDisabled}
          maxSize={MAXIMUM_FILE_SIZE}
          onDrop={handleDrop}
        >
          {(props) => <InnerDropzone {...props} />}
        </Dropzone>
      </div>
      <div className="text-sm text-gray-500 cell medium-12 uploader__footer">
        <div>{`Supported format(s): ${ACCEPTED_TYPES_HUMAN_READABLE.join(", ")}`}</div>
        <div>{`Maximum filesize: ${ConvertBytesToMegabytes(MAXIMUM_FILE_SIZE)} MB`}</div>
        <a href={config.documentation_footer_link}>{config.documentation_footer_text}</a>
        <div>
          Questions? Need file upload help? Reach out to{" "}
          <a href="mailto:support@heypoplar.com">support@heypoplar.com</a>
        </div>
      </div>
    </div>
  );
}

type InnerDropzoneProps = {
  getRootProps: Function,
  getInputProps: Function,
  isDragActive: boolean,
  isDragAccept: boolean,
  isDragReject: boolean,
  isFileDialogActive: boolean,
  isFocused: boolean
};

function InnerDropzone({ getRootProps, getInputProps, isDragActive }: InnerDropzoneProps) {
  return (
    <div {...getRootProps()} className={classNames("dropzone", { dropzone__active: isDragActive })}>
      <input {...getInputProps()} />
      <p className="uploader__label">Click to select a file or drop to upload</p>
    </div>
  );
}

function FileSizeError({ max_file_size_error = MAXIMUM_FILE_SIZE_DEFAULT_ERROR_MSG }) {
  return (
    <div className="mb-4 uploader__error bg-red-100 items-center flex justify-center w-full p-4 text-sm max-w-4xl">
      <span
        dangerouslySetInnerHTML={{
          __html: max_file_size_error
        }}
      />
    </div>
  );
}

function FileUnknownError() {
  return (
    <div className="mb-4 uploader__error">
      Something went wrong when attaching the file, please try again.
    </div>
  );
}

export default FileSelect;
