// @flow
import React from "react";
import type { Node } from "react";
import type { Item } from "@elements/EstimateTable.js";

export type Rate = {
  skipFormatting?: boolean,
  label: string,
  prefix: string,
  value: number,
  suffix: string
};

type TableProps = {
  items: Array<Item>
};

type RowProps = {
  rate: Rate
};

type RateSectionProps = {
  section: Array<Rate>
};

export default function FullEstimateTable({ items }: TableProps): Node {
  return (
    <div className="full-estimate-container">
      <h4 className="full-estimate-title">Rates</h4>
      <dl className="full-estimate-rates">
        {items.map((item) => (
          <RateSection section={item.section} key={item.section[0].label} />
        ))}
      </dl>
    </div>
  );
}

function RateSection({ section }: RateSectionProps): React$Node {
  return (
    <div className="full-estimate-rates__section">
      {section.map((rate, index) => (
        <EstimateRow key={rate.label} rate={rate} />
      ))}
    </div>
  );
}

function EstimateRow({ rate: { label, prefix, value, suffix, skipFormatting } }: RowProps) {
  return (
    <div className="full-estimate-rates__item">
      <dt>{label}</dt>
      <dd>
        {!!prefix && <span className="full-estimate-rates__item-prefix">{prefix}</span>}
        {value} {suffix}
      </dd>
    </div>
  );
}
