import _ from "lodash";
import React from "react";
import pluralize from "pluralize";
import RailsFormData from "@utilities/FormatRailsFormData";

class ZipCodes extends React.Component {
  constructor(props){
    super(props);

    let source;

    const suppressionZipCodesMatchCampaign = _.isEqual(
      _.sortBy(_.map(props.suppressionZipCodes, "zip_code_id")),
      _.sortBy(_.map(props.campaignSuppressionZipCodes, "zip_code_id"))
    );

    if (props.error){
      source = "provided";
    } else if (_.isEmpty(props.suppressionZipCodes)){
      source = "all";
    } else if (suppressionZipCodesMatchCampaign){
      source = "campaign";
    } else {
      source = "provided";
    }

    const mappedSuppressionZipCodes = _.keyBy(props.suppressionZipCodes, "zip_code_id");
    const mappedCampaignSuppressionZipCodes = _.keyBy(props.campaignSuppressionZipCodes, "zip_code_id");
    const providedZipCodes = source === "provided" ? _.join(_.map(props.zipCodes, "number"), ", ") : "";
    const suppressionZipCodes = source === "provided" ? calculateSuppressionChangeset(mappedSuppressionZipCodes, {}) : [];

    this.state = {
      source,
      mappedSuppressionZipCodes,
      mappedCampaignSuppressionZipCodes,
      providedZipCodes: providedZipCodes,
      suppressionZipCodes: suppressionZipCodes,
      hideCampaignSettings: _.isEmpty(props.campaignSuppressionZipCodes),
      campaignZipCodeCountTag: campaignZipCodeCountTag(props.campaignSuppressionZipCodes)
    };

    this._providedChanged = this._providedChanged.bind(this);
    this._sourceChanged = this._sourceChanged.bind(this);
  }

  _providedChanged(e){
    this.setState({ providedZipCodes: e.target.value });
  }

  _sourceChanged(e){
    let proposedSuppressionZipCodes;

    const source = e.target.value;
    const { mappedSuppressionZipCodes, mappedCampaignSuppressionZipCodes } = this.state;

    if (source === "campaign"){
      proposedSuppressionZipCodes = mappedCampaignSuppressionZipCodes;
    } else {
      proposedSuppressionZipCodes = {};
    }

    this.setState({
      source,
      suppressionZipCodes: calculateSuppressionChangeset(mappedSuppressionZipCodes, proposedSuppressionZipCodes)
    });
  }

  render(){
    const { error } = this.props;
    const {
      campaignZipCodeCountTag,
      hideCampaignSettings,
      providedZipCodes,
      source,
      suppressionZipCodes
    } = this.state;

    const formData = { suppression_zip_codes_attributes: suppressionZipCodes };

    if (source === "provided"){ formData.zip_codes = providedZipCodes; }

    return (
      <div className="manual-send-zip-codes">
        <RailsFormData path="manual_send[list_attributes]" data={formData} />
        <div className="manual-send-zip-codes__sources">
          <AllZipCodes
            source={source}
            onSourceChange={this._sourceChanged}
          />
          <CampaignSettings
            source={source}
            onSourceChange={this._sourceChanged}
            zipCodeCountTag={campaignZipCodeCountTag}
            hideCampaignSettings={hideCampaignSettings}
          />
          <ProvidedZipCodes
            error={error}
            source={source}
            providedZipCodes={providedZipCodes}
            onSourceChange={this._sourceChanged}
            onProvidedChange={this._providedChanged}
          />
        </div>
      </div>
    );
  }
}

const AllZipCodes = ({
  onSourceChange,
  source
}) => (
  <div className="manual-send-zip-codes__all-zip-codes">
    <input
      id="all"
      value="all"
      type="radio"
      name="source"
      onChange={onSourceChange}
      checked={source === "all"}
      className="manual-send-zip-codes__source"
    />
    <label
      htmlFor="all"
      className="manual-send-zip-codes__source-label"
    >
      All
    </label>
  </div>
);

const CampaignSettings = ({
  hideCampaignSettings,
  onSourceChange,
  source,
  zipCodeCountTag
}) => {
  if (hideCampaignSettings){ return null; }

  return (
    <div className="manual-send-zip-codes__campaign-zip-codes">
      <input
        id="campaign"
        name="source"
        type="radio"
        value="campaign"
        onChange={onSourceChange}
        checked={source === "campaign"}
        className="manual-send-zip-codes__source"
      />
      <label
        htmlFor="campaign"
        className="manual-send-zip-codes__source-label"
      >
        {`Use my campaign settings ${zipCodeCountTag}`}
      </label>
    </div>
  );
};

const ProvidedZipCodes = ({
  error,
  onProvidedChange,
  onSourceChange,
  providedZipCodes,
  source
}) => (
  <div className="manual-send-zip-codes__provided-zip-codes">
    <input
      id="provided"
      name="source"
      type="radio"
      value="provided"
      onChange={onSourceChange}
      checked={source === "provided"}
      className="manual-send-zip-codes__source"
    />
    <label
      htmlFor="provided"
      className="manual-send-zip-codes__source-label"
    >
      Input list of zip codes
    </label>
    <ProvidedZipCodesArea
      error={error}
      source={source}
      onChange={onProvidedChange}
      zipCodes={providedZipCodes}
    />
  </div>
);

const ProvidedZipCodesArea = ({
  error,
  onChange,
  source,
  zipCodes
}) => {
  if (source !== "provided"){ return null; }

  return (
    <div className="manual-send-zip-codes__provided-zip-codes-area medium-offset-1">
      <p>Type or paste zip codes below. One per line, or separated using commas.</p>
      <textarea
        rows="5"
        value={zipCodes}
        onChange={onChange}
        className="manual-send-zip-codes__provided-zip-codes-text-area"
      />
      <Error
        error={error}
      />
    </div>
  );
};

const Error = ({ error }) => {
  if (!error){ return null; }

  return (
    <div className="callout warning">
      <p>{error}</p>
    </div>
  );
};

const campaignZipCodeCountTag = (campaignSuppressionZipCodes) => {
  const count = _.size(campaignSuppressionZipCodes);

  return (`(${count} ${pluralize("zip code", count)})`);
};

const calculateSuppressionChangeset = (persistedSuppressionZipCodes, proposedSuppressionZipCodes) => {
  const suppressionChangeset = [];
  const difference = _.xor(_.keys(persistedSuppressionZipCodes), _.keys(proposedSuppressionZipCodes));

  _.forEach(difference, (zip_code_id) => {
    const existingZipCode = persistedSuppressionZipCodes[zip_code_id];

    if (existingZipCode){
      suppressionChangeset.push({ id: existingZipCode.id, _destroy: true });
    } else {
      suppressionChangeset.push({ zip_code_id: zip_code_id });
    }
  });

  return suppressionChangeset;
};

export default ZipCodes;
