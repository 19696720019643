import React from "react";

class GeofenceMap extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      drawnCoordinates: [] //Coordinates of the user's drawn Polygon
    };
    this._getCoordinates = this._getCoordinates.bind(this);
    this._launchDrawingMode = this._launchDrawingMode.bind(this);
    this._launchViewingMode = this._launchViewingMode.bind(this);
  }

  componentDidMount(){
    const { mode } = this.props;
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 4,
      center: new google.maps.LatLng(37.0902, -95.7129),
      streetViewControl: false,
      mapTypeControl: false
    });
    switch (mode){
      case 0:
        this._launchDrawingMode(map);
        break;
      case 1:
        this._launchViewingMode(map);
        break;
    }
  }

  //DRAWING
  _launchDrawingMode(map){
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true, //disables selection of other modes
      drawingControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
          //google.maps.drawing.OverlayType.RECTANGLE,
          //google.maps.drawing.OverlayType.CIRCLE,
        ]
      },
      polygonOptions: {
        clickable: true,
        editable: true,
        zIndex: 1
      }
    });
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, "polygoncomplete", this._getCoordinates);
  }

  _getCoordinates(polygon){
    const { drawnCoordinates } = this.state;
    const coordinates = [];
    for (let i = 0; i < polygon.getPath().getLength(); i++){
      coordinates.push(polygon.getPath().getAt(i).toUrlValue(6));
    }
    drawnCoordinates.push(coordinates);
    this.setState({ drawnCoordinates });
  }

  //VIEWING
  _launchViewingMode(map){
    const { coordinatesArray } = this.props;
    this._mapCoordinates(coordinatesArray, map);
    console.log(coordinatesArray);
    console.log(map);
  }

  _mapCoordinates(coordinatesArray, map){
    coordinatesArray.forEach(coordinates => new google.maps.Polygon({
      paths: coordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: this.props.editable
    }).setMap(map));
  }

  render(){
    return (
      <div id="map" style={{ height: "80vh" }} />
    );
  }
}

export default GeofenceMap;
