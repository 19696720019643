// @flow

import React, { useState } from "react";
import type { Node } from "react";
import Modal from "@elements/Modal";
import RadioButton from "@elements/RadioButton";
import TextInput from "@elements/TextInput";
import type { Role, RoleId } from "./types";

type Props = {
  currentRoleId?: RoleId,
  userRoles: Array<Role>,
  userEmail?: string,
  formAction: string,
  showRoles: boolean,
  heading: string,
  open: boolean,
  closeModal: () => void
};

export default function TeamMemberModal({
  userEmail,
  currentRoleId,
  userRoles,
  formAction,
  showRoles,
  open,
  closeModal,
  heading
}: Props): Node {
  const [email, setEmail] = useState<string>("");

  function onSubmit(e) {
    if (!e.target.reportValidity()) {
      e.preventDefault();
    }
  }

  const Roles = (): Node => (
    <fieldset>
      <legend className="mb-1 font-normal text-gray-500">Role</legend>
      <ul className="mb-6 radio-list">
        {userRoles.map(({ id, display_name, description }: Role) => (
          <li key={id}>
            <RadioButton
              id={id}
              defaultChecked={id === currentRoleId}
              label={
                <React.Fragment>
                  <div className="text-sm">
                    <strong>{display_name}</strong> &ndash; {description}
                  </div>
                </React.Fragment>
              }
              name="role"
              value={id}
              required
            />
          </li>
        ))}
      </ul>
    </fieldset>
  );

  const token = (document.querySelector("meta[name=csrf-token]"): any).content;

  return (
    <Modal header={heading} onClose={closeModal} show={open}>
      <form acceptCharset="UTF-8" action={formAction} method="post" onSubmit={onSubmit}>
        <input type="hidden" name="_method" value={userEmail ? "put" : "post"} />
        <input type="hidden" name="authenticity_token" value={token} />
        {userEmail ? (
          <TextInput label="Email" type="email" value={userEmail} disabled />
        ) : (
          <TextInput
            autoComplete="off"
            label="Email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            type="email"
          />
        )}
        {showRoles && <Roles userRoles={userRoles} />}
        <div className="flex justify-end gap-4">
          <button type="button" onClick={closeModal} className="w-32 button secondary">
            Cancel
          </button>
          <button type="submit" className="w-32 slm-button">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
