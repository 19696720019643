// @flow
import React from "react";
import useUniqueId from "@utilities/useUniqueId";
import ClipboardButton from "@components/ClipboardButton";
import type { Node } from "react";

const ClipboardWidget = ({
  value,
  label,
  containerClasses = "max-w-sm"
}: ClipboardWidgetProps): Node => {
  const id = useUniqueId("clipboard");

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className={containerClasses}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="relative input-field clipboard-field readonly">
        <input
          type="text"
          readOnly
          value={value}
          className="clipboard-input"
          onFocus={handleFocus}
          id={id}
        />
        <ClipboardButton value={value} />
      </div>
    </div>
  );
};

type ClipboardWidgetProps = {
  value: string,
  label?: string,
  containerClasses: string
};

export default ClipboardWidget;
