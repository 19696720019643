import React from "react";
import AlertTriangle from "react-feather/dist/icons/alert-triangle";

const FormErrors = ({ errors = [] }) => {
  if (errors.length < 1) return null;

  return (
    <div className="callout alert">
      <div className="alert-container">
        <div className="alert-header">
          <AlertTriangle size={25} color="#7F1D1D" />
          <div className="alert-text">
            Please ensure you have selected all required options to proceed.
          </div>
        </div>
        <div className="alert-body">
          <ul>
            {errors.map((error) => (
              <li>
                {error.message} <b>{error.field}</b>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormErrors;
