import React from "react";
import {
  isPlainObject,
  flatMap,
  isArray,
  isEmpty,
  isObject
} from "lodash";

const toFields = (path, data) => {
  if (data == null) return false;

  if (isPlainObject(data)){
    return flatMap(data, (value, key) => {
      const name = path ? `${path}[${key}]` : key;
      return toFields(name, value);
    });
  }

  if (isEmpty(path)){
    throw new TypeError(`Only keyed objects can be turned into form data, received ${data}`);
  }

  if (isArray(data)){
    // We need to explicitly key the resulting object.
    if (isObject(data[0])){
      return flatMap(data, (value, key) => toFields(`${path}[${key}]`, value));
    }

    const name = `${path}[]`;
    return data.map((value, _index) => value != null
      && <input type="hidden" key={`${path}`} name={name} value={value} />);
  }

  return (
    <input key={path} type="hidden" name={path} value={data} />
  );
};

const RailsFormData = ({path, data, ...props}) => (
  <div style={{display: "none"}} {...props}>
    {toFields(path, data)}
  </div>
);

export default RailsFormData;
