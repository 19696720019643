import _ from "lodash";
import React from "react";
import RailsFormData from "@utilities/FormatRailsFormData";

class States extends React.Component {
  constructor(props){
    super(props);

    const suppressionStatesMatchCampaign = _.isEqual(
      _.sortBy(_.map(props.suppressionStates, "state_id")),
      _.sortBy(_.map(props.campaignSuppressionStates, "state_id"))
    );

    const source = _.isEmpty(props.suppressionStates)
      || suppressionStatesMatchCampaign ? "campaign" : "selected";

    const selectedStates = source === "campaign"
      ? props.states : _.unionBy(props.suppressionStates, props.states, "state_id");

    const mappedSuppressionStates = _.keyBy(props.suppressionStates, "state_id");
    const mappedCampaignSuppressionStates = _.keyBy(props.campaignSuppressionStates, "state_id");

    const suppressionStates = source === "campaign"
      ? calculateSuppressionChangeset(props.states, mappedSuppressionStates, mappedCampaignSuppressionStates) : [];

    const inactiveStates = _.differenceWith(
      props.states,
      props.campaignSuppressionStates,
      (state, campaignState) => (state.state_id === campaignState.state_id)
    );

    const activeStates = (props.states).filter(a => suppressionStates.some(b => a.state_id === b.state_id));

    this.state = {
      source,
      suppressionStates,
      mappedSuppressionStates,
      showInactiveStates: false,
      mappedCampaignSuppressionStates,
      inactiveStates: _.map(inactiveStates, "name"),
      activeStates: _.map(activeStates, "name"),
      campaignStateCountTag: campaignStateCountTag(props.campaignSuppressionStates, props.states),
      selectedStates: _.mapValues(
        _.keyBy(selectedStates, "state_id"), (s) => { return (source === "campaign" ? true : !!s.id); }
      )
    };

    this._selectionChanged = this._selectionChanged.bind(this);
    this._showInactiveStates = this._showInactiveStates.bind(this);
    this._sourceChanged = this._sourceChanged.bind(this);
  }

  componentDidMount(){
    const { selectedStates} = this.state;
    const { states } = this.props;
    selectedStates[states.find(state => state.name === "Alaska").state_id] = false;
    selectedStates[states.find(state => state.name === "Hawaii").state_id] = false;
  }

  _selectionChanged(e){
    const { selectedStates, mappedSuppressionStates } = this.state;
    const { states } = this.props;

    if (e.target.value === "all"){
      _.forEach(states, (state) => { selectedStates[state.state_id] = e.target.checked; });
    } else {
      selectedStates[e.target.value] = e.target.checked;
    }

    this.setState({
      selectedStates,
      suppressionStates: calculateSuppressionChangeset(states, mappedSuppressionStates, _.pickBy(selectedStates))
    });
  }

  _showInactiveStates(_e){
    const { showInactiveStates } = this.state;

    this.setState({ showInactiveStates: !showInactiveStates });
  }

  _sourceChanged(e){
    const source = e.target.value;

    const { selectedStates, mappedSuppressionStates, mappedCampaignSuppressionStates } = this.state;
    const { states } = this.props;

    const proposedSuppressionStates = source === "campaign"
      ? mappedCampaignSuppressionStates : _.pickBy(selectedStates);

    this.setState({
      source: e.target.value,
      suppressionStates: calculateSuppressionChangeset(states, mappedSuppressionStates, proposedSuppressionStates)
    });
  }

  render(){
    const {
      campaignStateCountTag,
      activeStates,
      inactiveStates,
      selectedStates,
      showInactiveStates,
      source,
      suppressionStates
    } = this.state;
    const { states } = this.props;
    const formData = { suppression_states_attributes: suppressionStates };

    return (
      <div className="manual-send-states">
        <RailsFormData path="manual_send[list_attributes]" data={formData} />
        <div className="manual-send-states__sources">
          <CampaignSettings
            source={source}
            activeStates={activeStates}
            inactiveStates={inactiveStates}
            showInactiveStates={showInactiveStates}
            onButtonClick={this._showInactiveStates}
            onSourceChange={this._sourceChanged}
            stateCountTag={campaignStateCountTag}
          />
          <StatePicker
            source={source}
            states={states}
            selectedStates={selectedStates}
            onSourceChange={this._sourceChanged}
            onSelectionChange={this._selectionChanged}
          />
        </div>
      </div>
    );
  }
}

const CampaignSettings = ({
  activeStates,
  inactiveStates,
  onButtonClick,
  onSourceChange,
  showInactiveStates,
  source,
  stateCountTag
}) => (
  <div className="manual-send-states__campaign-settings">
    <input
      id="campaign"
      name="source"
      type="radio"
      value="campaign"
      onChange={onSourceChange}
      checked={source === "campaign"}
      className="manual-send-states__source"
    />
    <label
      htmlFor="campaign"
      className="manual-send-states__source-label"
    >
      {`Use my campaign settings ${stateCountTag}`}
    </label>
    <button
      type="button"
      onClick={onButtonClick}
      className="manual-send-states__inactive-states-button"
    >
      { showInactiveStates ? "Hide" : "Show" }
    </button>
    <CampaignInfo
      activeStates={activeStates}
      inactiveStates={inactiveStates}
      showInactiveStates={showInactiveStates}
    />
  </div>
);

const CampaignInfo = ({ activeStates, inactiveStates, showInactiveStates }) => {
  if (!showInactiveStates) return null;

  let message;
  if (_.isEmpty(inactiveStates)){
    message = CAMPAIGN_INFO_ALL_DC;
  } else if (inactiveStates.length < 26){
    message = `${CAMPAIGN_INFO_ALL} except for ${_.join(inactiveStates, ", ")}`;
  } else {
    message = CAMPAIGN_INFO + _.join(activeStates, ", ");
  }

  return (
    <div className="manual-send-states__campaign-info medium-offset-1">
      <p className="preview-attribute__value">
        {message}
      </p>
    </div>
  );
};

const StatePicker = ({
  source,
  states,
  selectedStates,
  onSourceChange,
  onSelectionChange
}) => (
  <div className="manual-send-states__state-picker">
    <input
      id="selected"
      name="source"
      type="radio"
      value="selected"
      onChange={onSourceChange}
      checked={source === "selected"}
      className="manual-send-states__source"
    />
    <label
      htmlFor="selected"
      className="manual-send-states__source-label"
    >
      Pick specific states
    </label>
    <StateList
      source={source}
      states={states}
      onChange={onSelectionChange}
      selectedStates={selectedStates}
    />
  </div>
);

const StateList = ({
  source,
  states,
  onChange,
  selectedStates
}) => {
  if (source === "campaign") return null;

  const allChecked = _.every(_.values(selectedStates));
  const allUnchecked = _.every(_.values(selectedStates), (s) => (!s));

  document.getElementById("submit_trigger").disabled = allUnchecked;

  return (
    <div className="manual-send-states__states">
      <div className="medium-offset-1">
        <label htmlFor="all" className="preview-attribute__value">
          <input
            id="all"
            value="all"
            type="checkbox"
            onChange={onChange}
            checked={allChecked}
            ref={el => el && (el.indeterminate = (!allChecked && !allUnchecked))}
          />
          All
        </label>
      </div>
      <div className="grid-x medium-up-3 medium-offset-1">
        {states.map(state => (
          <div className="cell">
            <State
              state={state}
              key={state.state_id}
              onChange={onChange}
              checked={selectedStates[state.state_id]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const State = ({
  state,
  checked,
  onChange
}) => (
  <div className="manual-send-states__state">
    <label htmlFor={state.state_id} className="preview-attribute__value">
      <input
        id={state.state_id}
        value={state.state_id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {state.name}
    </label>
  </div>
);

const campaignStateCountTag = (campaignSuppressionStates, states) => {
  const statesCount = states.length;
  const campaignStatesCount = campaignSuppressionStates.length;

  return (`(${campaignStatesCount}/${statesCount})`);
};

const calculateSuppressionChangeset = (states, persistedSuppressionStates, proposedSuppressionStates) => {
  if (_.isEmpty(persistedSuppressionStates)
    && (_.isEmpty(proposedSuppressionStates) || proposedSuppressionStates.length === states.length)
  ) return [];

  const suppressionChangeset = [];
  const difference = _.xor(_.keys(persistedSuppressionStates), _.keys(proposedSuppressionStates));

  _.forEach(difference, (state_id) => {
    const existingState = persistedSuppressionStates[state_id];

    if (existingState){
      suppressionChangeset.push({ id: existingState.id, _destroy: true });
    } else {
      suppressionChangeset.push({ state_id: state_id });
    }
  });

  return suppressionChangeset;
};

const CAMPAIGN_INFO_ALL_DC = "Mail: All states and D.C.";
const CAMPAIGN_INFO_ALL = "Mail: All states";
const CAMPAIGN_INFO = "Mail: ";

export default States;
