import React from "react";
import _ from "lodash";
import USAMap from "react-usa-map";
import { fetch } from "whatwg-fetch";

class StateSelectForm extends React.Component {
  constructor(props){
    super(props);

    const { states } = this.props;
    this.states = JSON.parse(states);

    this.state = {
      selected_states: this._initSelectedStates(),
      saving: false
    };

    this._generateMapConfig = this._generateMapConfig.bind(this);
    this._handleDeselectAll = this._handleDeselectAll.bind(this);
    this._handleMapClick = this._handleMapClick.bind(this);
    this._handleSelectAll = this._handleSelectAll.bind(this);
    this._handleSyncResponse = this._handleSyncResponse.bind(this);
    this._toggleSelection = this._toggleSelection.bind(this);
  }

  _initSelectedStates(){
    const { selected_states } = this.props;
    let parsed_states = JSON.parse(selected_states);

    if (parsed_states.length === 0){
      parsed_states = _.map(this.states, state => state.id);
    } else {
      parsed_states = _.map(parsed_states, state => state.state_id);
    }
    return parsed_states;
  }

  _generateMapConfig(){
    const { selected_states } = this.state;
    const config = {};

    selected_states.forEach(state_id => {
      const state = _.find(this.states, s => state_id === s.id);
      config[state.abbreviation] = {
        fill: "#00a373"
      };
    });
    return config;
  }

  _sync(){
    const { url } = this.props;
    const { selected_states } = this.state;
    const token = $("meta[name=\"csrf-token\"]").attr("content");
    this.setState({ saving: true });

    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        selected_states: selected_states
      }),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": token
      }
    }).then(response => response.json())
      .then(this._handleSyncResponse);
  }

  _handleSyncResponse(response){
    this.setState({
      saving: false,
      selected_states: response.selected_states
    });
  }

  _handleSelectAll(){
    const selected_states = _.map(this.states, state => state.id);
    this.setState({ selected_states }, this._sync);

    return false;
  }

  _handleDeselectAll(){
    this.setState({ selected_states: [] }, this._sync);

    return false;
  }

  _handleMapClick(event){
    const state = _.find(this.states, s => s.abbreviation === event.target.dataset.name);
    this._toggleSelection(state.id);
  }

  _toggleSelection(state_id){
    let {selected_states} = this.state;
    selected_states = _.indexOf(selected_states, state_id) === -1 ? _.union(selected_states, [state_id]) : _.without(selected_states, state_id);

    this.setState({ selected_states }, this._sync);
  }

  render(){
    const { selected_states, saving } = this.state;

    return (
      <div className="grid gap-4 lg:grid-cols-[3fr_2fr] ">
        <div className="col-span-full">
          <div>
            <div className="font-semibold heading-main">
              <StateCount
                total={this.states.length}
                count={selected_states.length}
              />
              <SaveIndicator saving={saving} />
            </div>
          </div>
          <div className="toolbar__controls gap-x-4 text-sm">
            <button
              className="button-link"
              type="button"
              onClick={this._handleSelectAll}
            >
              Select All
            </button>
            <button
              className="button-link"
              type="button"
              onClick={this._handleDeselectAll}
            >
              Deselect All
            </button>
          </div>
        </div>
        <div>
          <USAMap
            customize={this._generateMapConfig()}
            onClick={this._handleMapClick}
          />
        </div>
        <div>
          <div className="grid grid-cols-2 2xl:grid-cols-3 gap-1">
            {this.states.map(
              state => (
                <StateCheckbox
                  key={state.id}
                  onChange={() => this._toggleSelection(state.id)}
                  checked={_.includes(selected_states, state.id)}
                  {...state}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SaveIndicator = ({ saving }) => {
  if (!saving) return null;
  return <span className="font-normal"> — Saving</span>;
};

const StateCount = ({ count, total }) => {
  if (count === 0){
    return "Mailing to all states";
  } else if (count === 1){
    return "Mailing to 1 state";
  } else if (count === total){
    return "Mailing to all states";
  } else {
    return `Mailing to ${count} states`;
  }
};

const StateCheckbox = ({
  id, name, checked, onChange
}) => (
  <label htmlFor={id} className="cursor-pointer">
    <input
      id={id}
      type="checkbox"
      value={1}
      checked={checked}
      name="state[{id}]"
      onChange={onChange}
      className="mr-1"
    />
    {name}
  </label>
);

export default StateSelectForm;
