import React from "react";
import { DateRangePicker } from "react-dates";
import { formatDate, today, daysAgo, isToday, isAfterToday } from "@utilities/MomentHelpers";

import CalendarIcon from "react-feather/dist/icons/calendar";
import ArrrowIcon from "react-feather/dist/icons/arrow-right";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: formatDate(props.start_date, "DD-MM-YYYY"),
      endDate: formatDate(props.end_date, "DD-MM-YYYY"),
      focusedInput: null,
      repositioned: false
    };

    this._onClose = this._onClose.bind(this);
    this._handleDateChange = this._handleDateChange.bind(this);
    this._handleFocusChange = this._handleFocusChange.bind(this);
    this._handlePresetRange = this._handlePresetRange.bind(this);
    this._areDatesPresent = this._areDatesPresent.bind(this);
    this._datesChanged = this._datesChanged.bind(this);
  }

  _areDatesPresent() {
    const { startDate, endDate } = this.state;
    return startDate && endDate;
  }

  _datesChanged() {
    const { start_date, end_date } = this.props;
    const { startDate, endDate } = this.state;
    return (
      formatDate(start_date, "DD-MM-YYYY").dayOfYear() !== startDate.dayOfYear() ||
      formatDate(end_date, "DD-MM-YYYY").dayOfYear() !== endDate.dayOfYear()
    );
  }

  _handleDateChange({ startDate, endDate }) {
    // eslint-disable-next-line react/destructuring-assignment
    if (startDate < this.state.startDate) {
      this.setState({ startDate, endDate: null });
    } else if (endDate) {
      this.setState({ startDate, endDate });
    } else {
      this.setState({ startDate, endDate: null });
    }
  }

  _handleFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  _handlePresetRange(days) {
    if (
      daysAgo(days).isSame(this.state.startDate, "day") &&
      today().isSame(this.state.endDate, "day")
    ) {
      this._handleFocusChange(null);
    } else {
      this.setState(
        {
          startDate: daysAgo(days),
          endDate: today()
        },
        this._onClose
      );
    }
  }

  _onClose() {
    // Need timeout to work around race condition where we raise up state to Toolbar.js before the state has been updated.
    setTimeout(() => {
      const { onChange } = this.props;
      const { startDate, endDate } = this.state;
      if (this._areDatesPresent() && this._datesChanged()) {
        onChange(startDate, endDate);
      }
    }, 500);
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const { start_date, end_date } = this.props;

    return (
      <DateRangePicker
        calendarInfoPosition="top"
        customArrowIcon={
          <label htmlFor="end-date" aria-hidden>
            <ArrrowIcon size="18" />
          </label>
        }
        customInputIcon={<CalendarIcon size="18" />}
        displayFormat="MMM D, YYYY"
        endDate={endDate}
        endDateId="end-date"
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        horizontalMargin={20}
        isDayHighlighted={isToday}
        isOutsideRange={isAfterToday}
        minimumNights={0}
        noBorder
        onClose={this._onClose}
        onDatesChange={this._handleDateChange}
        onFocusChange={this._handleFocusChange}
        renderCalendarInfo={() => <CalendarInfo clickHandler={this._handlePresetRange} />}
        startDate={startDate}
        startDateId="start-date"
      />
    );
  }
}

const CalendarInfo = ({ clickHandler }) => (
  <div className="flex items-center px-6 py-4 space-x-4 border-b">
    <h4 className="m-0">Quick Select:</h4>
    <input
      className="h-8 m-0 text-sm border text-button"
      type="button"
      onClick={() => clickHandler(29)}
      value="Last 30 days"
    />
    <input
      className="h-8 m-0 text-sm border text-button"
      type="button"
      onClick={() => clickHandler(59)}
      value="Last 60 days"
    />
    <input
      className="h-8 m-0 text-sm border text-button"
      type="button"
      onClick={() => clickHandler(89)}
      value="Last 90 days"
    />
    <input
      className="h-8 m-0 text-sm border text-button"
      type="button"
      onClick={() => clickHandler(364)}
      value="Last 12 months"
    />
  </div>
);

export default DatePicker;
