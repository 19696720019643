// @flow

import React from "react";

import SelectTag from "@elements/SelectTag";
import TextInput from "@elements/TextInput";
import type { Node } from "react";

import type { CreativeOption, HandleOptionChange, SelectedCreativeOptions } from "./types";

type CreativeOptionsProps = {
  options: Array<CreativeOption>,
  onChange: HandleOptionChange,
  values: SelectedCreativeOptions
};

export default function CreativeOptions({ options, onChange, values }: CreativeOptionsProps): Node {
  return options.map((option) => {
    if (option.internal_name === "perforated_page" && values.return_envelope !== "true")
      return null;

    return (
      <div className="input-container" key={option.internal_name}>
        <OptionInputPicker
          option={option}
          onChange={onChange}
          value={values[option.internal_name]}
          key={option.internal_name}
        />
      </div>
    );
  });
}

type OptionInputPickerProps = {
  option: CreativeOption,
  onChange: HandleOptionChange,
  value: string
};

function OptionInputPicker({ option, onChange, value }: OptionInputPickerProps) {
  switch (option.option_type) {
    case "boolean":
      return <BooleanOption {...option} onChange={onChange} value={value} />;
    case "integer":
      return <NumberOption {...option} onChange={onChange} value={value} />;
    default:
      return null;
  }
}

const BOOLEAN_OPTIONS = [
  { id: "true", name: "Yes" },
  { id: "false", name: "No" }
];

const LABELS = {
  double_sided: "Double Sided",
  perforated_page: "Perforated Page",
  return_envelope: "Return Envelope"
};

const TOOLTIPS = {
  perforated_page: "Select 0 for no perforation."
};

function BooleanOption({ internal_name, onChange, value }) {
  return (
    <SelectTag
      label={LABELS[internal_name]}
      tooltip={TOOLTIPS[internal_name] || null}
      data={BOOLEAN_OPTIONS}
      value={value}
      onChange={(event) => onChange(event.target.value, internal_name)}
    />
  );
}

function NumberOption({ internal_name, onChange, value }) {
  return (
    <TextInput
      label={LABELS[internal_name]}
      tooltip={TOOLTIPS[internal_name]}
      value={value}
      onChange={(event) => onChange(event.target.value, internal_name)}
      type="number"
      min="-1"
    />
  );
}
