import React, { useRef } from "react";
import uniqueId from "lodash/uniqueId";
import classNames from "classnames";
import Tooltip from "@components/Tooltip";

const TextInput = React.forwardRef(
  (
    {
      bottomLabel,
      className = "",
      error,
      errorId,
      icon,
      id: inputId,
      label,
      tooltip,
      disabled = null,
      ...props
    },
    inputRef
  ) => {
    const { current: id } = useRef(inputId || uniqueId("textinput_"));

    return (
      <div
        className={classNames("input-container", className, {
          disabled
        })}
      >
        <label htmlFor={id}>
          {label}
          {!!tooltip && <Tooltip value={tooltip} />}
        </label>
        <div className="input-field">
          {!!icon && <span className="input-decorator prefix">{icon}</span>}
          <input ref={inputRef} id={id} type="text" disabled={disabled} {...props} />
        </div>
        {bottomLabel && (
          <label className="bottom-label" htmlFor={id}>
            {bottomLabel}
          </label>
        )}
        <Error error={error} errorId={errorId || uniqueId("error")} />
      </div>
    );
  }
);

const Error = ({ error, errorId }) => {
  if (!error) return null;

  return (
    <span role="alert" className="form-error" id={errorId}>
      {error}
    </span>
  );
};

export default TextInput;
