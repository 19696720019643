import { Controller } from "@hotwired/stimulus";

const defaults = {
  containerSelector: ".input-container",
  errorClass: "has-error"
};

export default class extends Controller {
  initialize() {
    this.touched = [];
    this.inputs = [];

    this.containerSelector = this.data.get("containerSelector") || defaults.containerSelector;
    this.errorClass = this.data.get("errorClass") || defaults.errorClass;
  }

  connect() {
    const form = this.element;
    form.noValidate = true;
    this.inputs = Array.from(form.elements).filter(this.shouldValidate);

    form.addEventListener("submit", this.submit.bind(this), true);
    form.addEventListener("ajax:beforeSend", this.submit.bind(this), true);
    form.addEventListener("invalid", this.handleInvalid.bind(this), true);
    form.addEventListener("blur", this.handleBlur.bind(this), true);
    form.addEventListener("change", this.handleInput.bind(this), true);
  }

  /**
   * Mark a field as touched
   * A field is touched on blur and submit
   */
  touchField(field) {
    if (!this.touched.includes(field)) {
      this.touched.push(field);
    }
  }

  handleBlur(e) {
    const { target: field } = e;
    if (this.shouldValidate(field)) {
      this.touchField(field);
      field.checkValidity();
    }
  }

  /**
   * Handle input change
   */
  handleInput(e) {
    const { target: field } = e;
    // only validate fields after they've been touched
    if (this.touched.includes(field)) {
      this.toggleErrors(field);
    }
  }

  /**
   * Handle input invalid event
   * Triggered when an input is invalid on submit or blur
   */
  handleInvalid(e) {
    const { target: field } = e;
    e.preventDefault();
    this.toggleErrors(field);
  }

  /**
   * Toggle error states on inputs
   */
  toggleErrors(field, clearError = false) {
    const hasError = !field.validity.valid && !clearError;

    if (hasError) {
      field.setAttribute("aria-invalid", "true");
    } else {
      field.removeAttribute("aria-invalid");
    }

    const container = field.closest(this.containerSelector);
    if (container) {
      container.classList.toggle(this.errorClass, hasError);
    }
  }

  /**
   * Determine if a element should be validated
   */
  shouldValidate(field) {
    return (
      field.willValidate &&
      !field.disabled &&
      !["button", "hidden", "submit", "fieldset"].includes(field.type)
    );
  }

  /**
   * Form submit handler
   */
  submit(e) {
    // set all fields as touched
    this.touched = this.inputs;

    const isValid = this.element.reportValidity();
    if (!isValid) {
      e.preventDefault();
      e.stopPropagation();
      this.element.querySelector("[aria-invalid]").focus();
    }
  }
}
