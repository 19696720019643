import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { toDatetimeLocalFieldValue } from "@utilities/MomentHelpers";

export default class extends Controller {
  AMOUNT_FIELD     = 'subscription[payment_amount]';
  CADENCE_FIELD    = 'subscription[payment_cadence]';
  EXP_DATE_FIELD   = 'subscription[expiration_date]';
  ORG_FIELD        = 'subscription[organization_id]';
  PLAN_FIELD       = 'subscription[plan_id]';
  START_DATE_FIELD = 'subscription[start_date]';

  connect() {
    if (this.element.tagName !== 'FORM') {
      return
    }

    this.changeHandler         = this.changeHandler.bind(this)
    this.setPaymentAmountValue = this.setPaymentAmountValue.bind(this)
    this.fields                = this.element.elements
    this.plansData             = this.parsePlansData();

    this.element.addEventListener('input', this.changeHandler)
    this.fields[this.PLAN_FIELD]?.selectize?.on('change', this.setPaymentAmountValue)

    if (this.element.dataset.upgrade) {
      this.disableUpgradeFields()
    }
  }

  parsePlansData() {
    try {
      return JSON.parse(document.getElementById("plans-data")?.innerText);
    } catch {
      return;
    }
  }

  changeHandler(e) {
    switch (e.target.name) {
      case this.START_DATE_FIELD:
        this.setExpirationDateValue(e.target.value)
        break;
      case this.PLAN_FIELD:
        this.setPaymentAmountValue()
        break;
      case this.CADENCE_FIELD:
        this.setPaymentAmountValue()
        break;

      default:
        break;
    }
  }

  setPaymentAmountValue() {
    const amountField = this.fields[this.AMOUNT_FIELD]
    const cadence     = this.fields[this.CADENCE_FIELD]?.value
    const plan        = this.fields[this.PLAN_FIELD]?.value

    if (!amountField || !cadence || !plan || !this.plansData) {
      return;
    }

    const price = this.plansData.find(p => p.id === plan)?.[`${cadence}_cost`];
    amountField.value = price;
  }

  setExpirationDateValue(startDate) {
    if (this.fields[this.EXP_DATE_FIELD].value) {
      return
    }

    const expDate = new Date(startDate)
    expDate.setFullYear(expDate.getFullYear() + 1);
    expDate.setDate(expDate.getDate() - 1)
    expDate.setUTCMinutes(59)
    expDate.setUTCHours(23);
    expDate.setUTCSeconds(59);

    this.fields[this.EXP_DATE_FIELD].value = toDatetimeLocalFieldValue(expDate.toLocaleString())
  }

  disableUpgradeFields() {
    this.fields[this.START_DATE_FIELD].readOnly = true
    this.fields[this.ORG_FIELD]?.selectize?.lock()
  }
}
