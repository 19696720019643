// @flow

import React, { Component, Fragment } from "react";
import Papa from "papaparse";
import classNames from "classnames";
import _ from "lodash";
import ProgressBar from "@elements/ProgressBar";
import Tooltip from "@components/Tooltip";
import { getMailableCount, buildErrorDownload } from "@utilities/UploadHelpers";
import { formatWithCommas } from "@utilities/FormatNumbers";
import Button from "@elements/Button";
import ModalActions from "@elements/ModalPortals";
import AlertIcon from "images/icons/alert.svg";
import type { RecordHash } from "@utilities/UploadHelpers";
import type { UploadResponse } from "@utilities/api/Upload";
import DownloadIcon from "react-feather/dist/icons/download";
import type { Record } from "./types";

type ReviewProps = {
  destinationType: string,
  errorHash: RecordHash,
  onCancel: () => void,
  onFinalize: (options: ?any) => void,
  mappedData: Array<Record>,
  options: OptionsConfig,
  minimumValidRecords: number,
  minimumValidRecordsErrorMessage: string,
  recordCount: number,
  upload: UploadResponse,
  uploadComplete: boolean,
  addressStrictness?: string
};

type ReviewState = {
  errorRetrievalProgress: number,
  options: OptionsHash,
  optionsConfig: OptionsConfig,
  progress: number,
  progressBarMessage: string,
  uploadProgress: number,
  validationProgress: number
};

type OptionsHash = {
  [option: string]: string
};

type OptionsConfig = {
  [field: string]: OptionField
};

type OptionField = {
  label: string,
  tooltip?: string,
  default: string | boolean,
  type?: string,
  choices?: Array<string>
};

type CalculateErrorsReturn = {
  disableNext: boolean,
  hasDownloadableErrors: boolean,
  mailableCount: number | null,
  minimumValidRecordsError: string | null
};

export class Review extends Component<ReviewProps, ReviewState> {
  constructor(props: ReviewProps) {
    super(props);

    const { options = {} } = this.props;
    const optionDefaults: OptionsHash = {};
    Object.keys(options).forEach((option: string) => {
      if (options[option].default === "use props") {
        optionDefaults[option] = this.props[_.camelCase(option)];
      } else {
        optionDefaults[option] = options[option].default;
      }
    });

    this.state = {
      errorRetrievalProgress: 0,
      options: optionDefaults,
      optionsConfig: options,
      progress: 0,
      progressBarMessage: "",
      uploadProgress: 0,
      validationProgress: 0
    };

    this._handleStateChange = this._handleStateChange.bind(this);
    this._handleStateChangeRadio = this._handleStateChangeRadio.bind(this);
    this._handleDownloadClick = this._handleDownloadClick.bind(this);
  }

  _handleStateChange(e: Event): void {
    console.info(e.target);
    const { options } = this.state;
    const newOptions = options;
    newOptions[e.target.id] = e.target.checked;
    console.info(newOptions);
    this.setState({
      options: newOptions
    });
  }

  _handleStateChangeRadio(e: Event, option: string): void {
    const { options } = this.state;
    const newOptions = options;
    newOptions[option] = e.target.value;
    this.setState({
      options: newOptions
    });
  }

  _minimumMailableRecordsError(mailableCount: number | null): string {
    if (mailableCount == null) return "";

    const { minimumValidRecordsErrorMessage, minimumValidRecords = 0 } = this.props;

    return mailableCount < minimumValidRecords ? minimumValidRecordsErrorMessage : "";
  }

  _handleDownloadClick() {
    const { mappedData, errorHash } = this.props;

    const csv = Papa.unparse(buildErrorDownload(mappedData, errorHash));
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(csvData);
    const a = document.createElement("a");
    a.href = url;
    a.download = "errors.csv";
    a.click();
  }

  _calculateErrors(): CalculateErrorsReturn {
    const { minimumValidRecords, upload } = this.props;
    const { options } = this.state;

    let mailableCount = null;
    let minimumValidRecordsError = null;
    const addressStrictness = minimumValidRecords ? "normal" : options.address_strictness;
    if (addressStrictness) {
      mailableCount = getMailableCount(upload.metadata.addresses, addressStrictness);
      minimumValidRecordsError = this._minimumMailableRecordsError(mailableCount);
    }

    const hasDownloadableErrors = checkForDownloadableError(
      mailableCount,
      upload.metadata,
      addressStrictness
    );
    const disableNext = shouldDisableNext(
      minimumValidRecordsError,
      mailableCount,
      upload.metadata.valid_count,
      addressStrictness
    );

    return { disableNext, hasDownloadableErrors, mailableCount, minimumValidRecordsError };
  }

  render(): React$Element<any> {
    const { upload, uploadComplete, onFinalize, onCancel, destinationType, minimumValidRecords } =
      this.props;
    const { progress, progressBarMessage, options, optionsConfig } = this.state;

    const { disableNext, hasDownloadableErrors, mailableCount, minimumValidRecordsError } =
      this._calculateErrors();
    return (
      <div>
        <Fragment>
          {minimumValidRecordsError ? (
            <h2 className="errorheader">
              <img className="alerticon" alt="Warning" src={AlertIcon} />
              {minimumValidRecordsError}
            </h2>
          ) : (
            <h5 className="mb-2 text-lg">Review &amp; Confirm</h5>
          )}
          <Summary
            {...upload.metadata}
            destinationType={destinationType}
            hasDownloadableErrors={hasDownloadableErrors}
            onDownload={this._handleDownloadClick}
            onFinalize={onFinalize}
            onCancel={onCancel}
            options={options}
            optionsConfig={optionsConfig}
            handleStateChange={this._handleStateChange}
            handleStateChangeRadio={this._handleStateChangeRadio}
            mailableCount={mailableCount}
            minimumValidRecords={minimumValidRecords}
          />
          {destinationType === "ManualSend" ? (
            <ModalActions>
              <Button secondary label="Back" onClick={stepBack.bind(this)} />
              <Button
                disabled={disableNext}
                label="Next"
                onClick={submitForm.bind(this, onFinalize, options)}
              />
            </ModalActions>
          ) : null}
        </Fragment>
      </div>
    );
  }
}

function checkForDownloadableError(mailableCount, metadata, address_strictness) {
  if (metadata.invalid_count > 0) return true;

  if (address_strictness) {
    return mailableCount < metadata.record_count;
  }

  return false;
}

function shouldDisableNext(
  minimumValidRecordsError,
  mailableCount,
  valid_count,
  address_strictness
) {
  if (address_strictness && mailableCount === 0) {
    return true;
  }

  return !!minimumValidRecordsError || valid_count === 0;
}

const submitForm = (onFinalize: (options: ?any) => void, options: ?any): void => {
  onFinalize(options);
};

const stepBack = (): void => {
  window.location.reload();
};

type SummaryProps = {
  hasDownloadableErrors: boolean,
  record_count: number,
  valid_count: number,
  invalid_count: number,
  onDownload: () => void,
  onCancel: () => void,
  onFinalize: (options?: OptionsHash) => void,
  handleStateChange: (e: Event) => void,
  handleStateChangeRadio: (e: Event, option: string) => void,
  options: OptionsHash,
  optionsConfig: OptionsConfig,
  destinationType: string,
  mailableCount: number | null,
  minimumValidRecords: ?number
};
export const Summary = ({
  destinationType,
  hasDownloadableErrors,
  handleStateChange,
  handleStateChangeRadio,
  invalid_count,
  onCancel,
  onDownload,
  onFinalize,
  options,
  optionsConfig,
  mailableCount,
  minimumValidRecords,
  record_count,
  valid_count
}: SummaryProps): React$Element<any> => (
  <div>
    <div className="grid-x review-stats">
      <Stat key="record_count" value={record_count} label="Total Records" />
      <Stat
        key="valid_count"
        value={valid_count}
        label={validRecordsLabel()}
        hide={
          destinationType === "ManualSend" && !!(options.address_strictness || minimumValidRecords)
        }
      />
      <Stat key="mailable_count" value={mailableCount} label={mailableRecordsLabel()} />
      <Stat
        key="invalid_count"
        value={invalid_count}
        label="Invalid Records"
        warning={invalid_count > 0}
        hide={
          destinationType === "ManualSend" && !!(options.address_strictness || minimumValidRecords)
        }
      />
    </div>
    <div className="grid-x">
      <div className="column small-12">
        <ImportWarning visible={valid_count > 0 && invalid_count > 0} />
      </div>
      <div className="column small-12">
        <DownloadErrors visible={hasDownloadableErrors} onClick={onDownload} />
      </div>
      <div className="column small-12">
        <Options
          onChange={handleStateChange}
          onChangeRadio={handleStateChangeRadio}
          options={options}
          optionsConfig={optionsConfig}
        />
      </div>
    </div>
    <div className="grid-x">
      <Actions
        visible={valid_count > 0 && destinationType !== "ManualSend"}
        onCancel={onCancel}
        onFinalize={onFinalize}
        options={options}
      />
    </div>
  </div>
);

type StatProps = {
  value: number | null,
  label: string | React$Element<any>,
  warning?: boolean,
  hide?: boolean
};
export function Stat({ value, label, warning, hide }: StatProps): boolean | React$Element<any> {
  return (
    value !== null &&
    !hide && (
      <div
        className={classNames(
          "medium-3",
          "column",
          "modal-stat",
          warning ? "modal-stat__warning" : null
        )}
      >
        <div className="modal-stat__value">{formatWithCommas(value)}</div>
        <div className="modal-stat__label">{label}</div>
      </div>
    )
  );
}

export const validRecordsLabel = (): React$Element<any> => (
  <div>
    Valid Records
    <Tooltip
      className="ml-1"
      value="The records we imported successfully with required fields present"
    />
  </div>
);

export const mailableRecordsLabel = (): React$Element<any> => (
  <div>
    Mailable Records
    <Tooltip
      className="ml-1"
      value="The quantity we can mail based on your address strictness selection"
    />
  </div>
);

export const ImportWarning = ({ visible }: { visible: boolean }): boolean | React$Element<any> =>
  visible && <p>If you choose to proceed, any invalid records will be skipped.</p>;

export const DownloadErrors = ({
  visible,
  onClick
}: {
  visible: boolean,
  onClick: () => void
}): boolean | React$Element<any> =>
  visible && (
    <p>
      <button
        type="button"
        className="inline-flex items-center button-link link-button"
        onClick={onClick}
      >
        <DownloadIcon size={16} className="mr-2" />
        Download File with Error Report
      </button>
    </p>
  );

export const Actions = ({
  visible,
  onFinalize,
  onCancel,
  options
}: {
  visible: boolean,
  onFinalize: (options: OptionsHash) => void,
  onCancel: () => void,
  options: OptionsHash
}): boolean | React$Element<any> =>
  visible && (
    <div className="flex gap-2 uploader__actions">
      <input
        className="slm-button"
        type="submit"
        value="Finish Importing"
        onClick={() => onFinalize(options)}
      />
      <input className="slm-button secondary" type="button" value="Cancel" onClick={onCancel} />
    </div>
  );
/**
 * Additional Options Shown on the Review screen
 * these options are defined in the upload config yml file
 */
export const Options = ({
  onChange,
  onChangeRadio,
  options,
  optionsConfig
}: {
  onChange: (e: Event) => void,
  onChangeRadio: (e: Event, option: string) => void,
  options: OptionsHash,
  optionsConfig: OptionsConfig
}): React$Element<any>[] =>
  Object.keys(options).map((option: string) => {
    switch (optionsConfig[option].type) {
      case "radio":
        return (
          <form key={option}>
            <div className="input-container">
              <label>Address Strictness</label>
              <div className="button-group round toggle">
                {optionsConfig[option].choices.map((choice) => (
                  <Fragment key={choice}>
                    <input
                      id={choice}
                      type="radio"
                      value={choice}
                      checked={options[option] === choice}
                      onChange={(e) => onChangeRadio(e, option)}
                    />
                    <label className="button" htmlFor={choice}>
                      {_.capitalize(choice)}
                    </label>
                  </Fragment>
                ))}
              </div>
            </div>
          </form>
        );
      case "hidden":
        return (
          <input
            id={option}
            key={option}
            type="hidden"
            name={option}
            value={options[option]}
            onChange={(e) => onChangeRadio(e, option)}
          />
        );
      default:
        return (
          <label key={option} className="preview-attribute__value pb-2">
            <input
              type="checkbox"
              id={option}
              checked={options[option]}
              onChange={onChange}
              className="mr-2"
            />
            {optionsConfig[option].label}
            {optionsConfig[option].tooltip ? (
              <Tooltip className="ml-1" value={optionsConfig[option].tooltip} />
            ) : null}
          </label>
        );
    }
  });

export default Review;
