// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  checked?: boolean,
  defaultChecked?: boolean,
  disabled?: boolean,
  id: string,
  label: string | Node,
  name: string,
  onChange?: () => void,
  required?: boolean,
  value?: string | number
};

export default function RadioButton({
  checked,
  defaultChecked,
  disabled,
  id,
  label,
  name,
  onChange,
  required,
  value
}: Props): Node {
  return (
    <React.Fragment>
      <input
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        type="radio"
        value={value}
      />
      <label htmlFor={id}>{label}</label>
    </React.Fragment>
  );
}
