//@flow
import React, { useState } from "react";
import type { Node } from "react";
import X from "react-feather/dist/icons/x";

type Props = {
  description: string,
  dismissible: boolean,
  theme: "blue" | "green" | "yellow" | "red",
  id: string
};

export default function PlatformNotice({ description, dismissible, theme, id }: Props): Node {
  const [hide, setHide] = useState(false);

  function hideNotice() {
    document.cookie = `dismissed_notice=${id}; max-age=${+(3600 * 24 * 30)}`;

    setHide(true);
  }

  if (hide) return null;

  // Explicitly define classes, otherwise css defined classes won't be picked up by Tailwind.
  // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  const platformTheme = {
    blue: "notice-bar--blue",
    green: "notice-bar--green",
    yellow: "notice-bar--yellow",
    red: "notice-bar--red"
  };

  return (
    <div className={`notice-bar notice-bar--platform ${platformTheme[theme]}`}>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      {dismissible && (
        <button aria-label="Dismiss" className="w-6 h-6 close" onClick={hideNotice} type="button">
          <X size={16} className="m-auto" />
        </button>
      )}
    </div>
  );
}
