// @flow
import React from "react";
import formatCurrency from "@utilities/FormatCurrency";
import type { Node } from "react";
import type { Rate } from "@elements/FullEstimateTable";

export type Item = {
  skipFormatting?: boolean,
  value: number,
  label: string,
  section: Array<Rate>
};

type TableProps = {
  items: Array<Item>,
  total: number,
  total_label: string
};

export default function EstimateTable({ items, total, total_label }: TableProps): Node {
  return (
    <table className="estimate-table">
      <tbody className="estimate-table__body">
        {items.map((item) => (
          <EstimateRow key={item.label} item={item} />
        ))}
      </tbody>
      <tfoot className="estimate-table__footer">
        <tr>
          <th>{total_label}</th>
          <th>{formatCurrency(total)}</th>
        </tr>
      </tfoot>
    </table>
  );
}

type RowProps = {
  item: Item
};

function EstimateRow({ item: { label, value, skipFormatting } }: RowProps) {
  const data = !skipFormatting ? formatCurrency(value) : value;

  return (
    <tr className="estimate-table__row">
      <td>{label}</td>
      <td>{data}</td>
    </tr>
  );
}
