import React from "react";
import moment from "moment";
import * as URI from "uri-js";
import fetchWithCsrfToken from "@utilities/PoplarRequest";
import classNames from "classnames";
import DownloadIcon from "react-feather/dist/icons/download";
import ColumnSelector from "./ColumnSelector";
import DatePicker from "./DatePicker";
import ReportDownload from "./ReportDownload";
import ToolbarFilter from "./ToolbarFilter";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(props.data.start_date, "DD-MM-YYYY"),
      endDate: moment(props.data.end_date, "DD-MM-YYYY")
    };

    this._onDatesChange = this._onDatesChange.bind(this);
    this._sendReport = this._sendReport.bind(this);
    this._dateParams = this._dateParams.bind(this);
  }

  componentDidUpdate(_prevProps, prevState) {
    const { startDate, endDate } = this.state;
    if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
      const {
        data: { state_names, manual_send_id },
        data_url,
        urlQuery
      } = this.props;
      const mailingStateParam = state_names ? `&states=${state_names}` : "";
      const manualSendParam = manual_send_id ? `&manual_send_id=${manual_send_id}` : "";
      const query = mailingStateParam + manualSendParam + this._dateParams() + urlQuery;

      window.location = URI.serialize({
        path: data_url,
        query
      });
    }
  }

  _dateParams() {
    const { startDate, endDate } = this.state;
    return `&start_date=${startDate.format("DD/MM/YYYY")}&end_date=${endDate.format("DD/MM/YYYY")}`;
  }

  _onDatesChange(start, end) {
    this.setState({
      startDate: start,
      endDate: end
    });
  }

  _sendReport() {
    const { report_url, data, report_type } = this.props;
    const { startDate, endDate } = this.state;
    const report = JSON.stringify({
      report: {
        campaign_id: data.campaign_id,
        end_date: endDate,
        environments: data.environments
          .split(",")
          .map((env) => env.slice(0, -4))
          .join(","),
        manual_send_id: data.manual_send_id,
        report_type: report_type,
        start_date: startDate,
        states: data.state_names
      }
    });
    fetchWithCsrfToken(report_url, { method: "post", body: report }).then((response) => {
      if (response.status === 200) {
        location.reload();
      }
    });
  }

  _switchRenderer(TagName) {
    const {
      data,
      data_url,
      report_type,
      campaignsTotals,
      toolbarText,
      searchTerm,
      csv_download_path
    } = this.props;

    switch (TagName) {
      case "AttributionFilter":
        return null;
      case "ToolbarText":
        return (
          <div key="toolbartext" className="flex items-center h-10">
            {toolbarText}
          </div>
        );
      case "CampaignsTotals":
        return <CampaignsTotalsSummary key="campaignstotals" campaignsTotals={campaignsTotals} />;
      case "DatePicker":
        return <DatePicker {...data} key="datepicker" onChange={this._onDatesChange} />;
      case "ToolbarFilter":
        return <ToolbarFilter {...data} key="toolbarfiler" data_url={data_url} />;
      case "ReportDownload":
        if (report_type == null) {
          return null;
        }
        return <ReportDownload key="reportdownload" downloadAction={this._sendReport} />;
      case "ColumnSelector":
        return (
          <div className="flex" key="columnselector">
            {csv_download_path && (
              <a
                className="-ml-2 text-gray-700 toolbar__button text-button hover:text-gray-700"
                href={`${csv_download_path}?search_term=${searchTerm}${this._dateParams()}`}
                data-turbo="false"
              >
                <DownloadIcon size={16} className="mr-2" />
                Download CSV
              </a>
            )}
            <ColumnSelector {...data} data_url={data_url} />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { components } = this.props;

    return (
      <React.Fragment>
        {Object.keys(components).map((TagName) => this._switchRenderer(TagName))}
      </React.Fragment>
    );
  }
}

function CampaignsTotalsSummary({ campaignsTotals }) {
  return (
    <div
      className={classNames("h-8 flex items-center", {
        invisible: !campaignsTotals.mailed
      })}
    >
      <span>Mailed: {campaignsTotals.mailed}</span>
      <span className="ml-6">Spend: {campaignsTotals.spend}</span>
    </div>
  );
}

export default Toolbar;
