import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.form = this.element.form;
    this.confirmationMessage = this.element.dataset.confirm;
    this.previousValue = this.element.value;

    this.element.addEventListener("change", (event) => {
      const { target } = event;
      if (this.confirmationMessage && !confirm(this.confirmationMessage)) {
        event.preventDefault();
        event.stopPropagation();
        target.value = this.previousValue;
        if (target.type === "checkbox") {
          target.checked = !target.checked;
        }
        return false;
      }
      this.previousValue = this.value;

      Rails.fire(this.form, "submit");
    });
  }
}
