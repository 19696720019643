// @flow
import React from "react";
import OffsetRates from "./OffsetRatesFields";
import RateField from "./RateField";
import { useRateGroup } from "./context";
import { rateKey } from "./helpers";
import type { CreativeTypeField } from "./types";

function CreativeTypesFields(): React$Node {
  const { creativeTypes } = useRateGroup();
  return (
    <React.Fragment>
      {Object.values(creativeTypes).map((creativeType: CreativeTypeField | any) => {
        const { name } = creativeType;
        return (
          <details key={name} className="-ml-4">
            <summary className="relative z-10 p-4 text-lg font-semibold cursor-pointer">
              <span className="ml-2">{name}</span>
            </summary>
            <div className="px-10 py-4">
              <div>
                <h4>Digital</h4>
                <BaseRates creativeType={creativeType} />
              </div>
              <div className="mt-12">
                <h4>Offset</h4>
                <OffsetRates creativeType={creativeType} />
              </div>
            </div>
          </details>
        );
      })}
    </React.Fragment>
  );
}

function BaseRates({ creativeType }: { creativeType: CreativeTypeField }): React$Node {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th className="pl-0">Postage</th>
            <th>Rate</th>
            <td aria-hidden />
          </tr>
        </thead>
        <tbody>
          {creativeType.mailTypes.map((mailType) => {
            const id = rateKey({ rateType: "base", creativeTypeId: creativeType?.id, mailTypeId: mailType?.id });
            return (
              <tr key={id} className="bg-white">
                <td className="w-48 pl-0">
                  <div className="w-48">
                    <label className="font-normal" htmlFor={id}>
                      {mailType.name}
                    </label>
                  </div>
                </td>
                <td className="w-20">
                  <div className="w-20">
                    <RateField
                      fieldId={id}
                      rateType="base"
                      creativeType={creativeType}
                      mailType={mailType}
                    />
                  </div>
                </td>
                <td aria-hidden />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CreativeTypesFields;
