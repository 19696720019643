// @flow

import React from "react";
import classNames from "classnames";
import LoadingSpinner from "@elements/LoadingSpinner";

type ButtonProps = {
  className?: string,
  dataMethod?: string,
  disabled?: boolean,
  hidden?: boolean,
  href?: string,
  id?: string,
  label: string,
  loading?: boolean,
  onClick?: Function,
  secondary?: boolean
};

const Button = ({
  className,
  disabled,
  hidden,
  href,
  id,
  label,
  loading,
  onClick,
  secondary,
  ...rest
}: ButtonProps): React$Element<"a"> | null => {
  if (hidden) return null;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      {...rest}
      id={id}
      className={classNames("slm-button inline-flex items-center justify-center", className, {
        secondary,
        disabled
      })}
      href={disabled ? "#" : href}
      onClick={disabled ? (event) => event.preventDefault() : onClick}
    >
      {loading && <LoadingSpinner />}
      {label}
    </a>
  );
};

export default Button;
