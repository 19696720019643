// @flow

export default function buildMetadataTooltipContent(metadata: { [string]: string }):Array<string> {
  const keys = Object.keys(metadata);
  const tooltipContent = [`${keys.length} custom fields:`];

  return keys.reduce((acc, key) => {
    const val = metadata[key];

    return [...acc, `${key} - ${val}`];
  }, tooltipContent);
}
