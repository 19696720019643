// @flow
import React, { useState } from "react";
import SelectTag from "@elements/SelectTag";
import Text from "@elements/Text";
import RailsFormData from "@utilities/FormatRailsFormData";
import type { Node } from "react";
import ModalActions from "@elements/ModalPortals";
import Button from "@elements/Button";

type Props = {
  creatives: Array<Creative>,
  persisted_creative_ids: Array<string>,
  cancelPath: string
};

type Creative = {
  id: string,
  name: string,
  mail_type: string,
  creative_type: string,
  merge_tags: string,
  thumbnail_url: string,
  default: string,
  format: string,
  image_formats: string
};

export default function CreativePicker({
  persisted_creative_ids,
  creatives,
  cancelPath
}: Props): Node {
  const [creative_ids, setCreativeIds] = useState<Array<string>>(
    persisted_creative_ids.length ? persisted_creative_ids : []
  );

  function changeCreativeType(id) {
    const check = creative_ids.includes(id);
    const newCreativeIds = [...creative_ids];
    if (check) {
      const index = newCreativeIds.indexOf(id);
      newCreativeIds.splice(index, 1);
    } else {
      newCreativeIds.push(id);
    }

    setCreativeIds(newCreativeIds);
  }

  const preview_creatives = creatives;

  function submitCreativeSelectionForm() {
    const form = document.forms.manual_send_creative_form;
    form.querySelector("input[type='submit']")?.click();
  }

  return (
    <div>
      <div>{creative_ids.length} selected</div>
      <RailsFormData path="manual_send[creative_ids]" data={creative_ids} />
      <ModalActions>
        <Button secondary label="Cancel" data-method="delete" href={cancelPath} />
        <Button
          disabled={creative_ids.length == 0}
          label="Next"
          onClick={submitCreativeSelectionForm}
        />
      </ModalActions>
      <CreativePreview
        creatives={preview_creatives}
        changeCreativeType={changeCreativeType}
        creative_ids={creative_ids}
      />
    </div>
  );
}

const ImageThumbnail = ({
  name,
  creative_type,
  image_formats,
  mail_type,
  status,
  src,
  selected,
  onChange
}) => (
  <label className={"manual-send-creative-picker__card"}>
    <div className="creative-title__image">
      <img className="manual-send-creative-picker__thumbnail" src={src} alt="Thumbnail" />
    </div>
    <div className="manual-send-creative__details">
      <h4>
        <span>{name}</span>
      </h4>
      <p>
        {image_formats}
        <br />
        {creative_type} - {mail_type}{" "}
        <input type="checkbox" checked={selected} onChange={onChange} />
      </p>
    </div>
  </label>
);

const CreativePreview = ({ creatives, changeCreativeType, creative_ids }) => {
  function isEmpty(creatives) {
    if (creatives == null) {
      return null;
    }
  }

  const { width, modifier } =
    creatives.length > 1
      ? { width: "12", modifier: "multiple" }
      : { width: "4", modifier: "single" };

  return (
    <div className="manual-send-creative-picker">
      {creatives.map((creative) => (
        <ImageThumbnail
          key={creative.id}
          src={creative.thumbnail_url}
          name={creative.name}
          creative_type={creative.creative_type}
          mail_type={creative.mail_type}
          image_formats={creative.image_formats}
          status={creative.default}
          selected={creative_ids.includes(creative.id)}
          onChange={() => changeCreativeType(creative.id)}
        />
      ))}
    </div>
  );
};
