// @flow
import React, { useState } from "react";
import type { Node } from "react";

const getAllCheckboxValues = (checkboxGroups): Array<string> =>
  checkboxGroups.reduce((vals, { checkboxes }) => [...vals, ...Object.keys(checkboxes)], []);

const CheckboxList = ({
  checkboxGroups,
  initialChecked,
  showSelectAll = true
}: CheckboxListProps): Node => {
  const [checked, setChecked] = useState(initialChecked);
  const [checkboxValues] = useState(() => getAllCheckboxValues(checkboxGroups));

  const handleSelectAllChange = () => {
    const allChecked = checked.length === checkboxValues.length;
    setChecked(allChecked ? [] : checkboxValues);
  };

  const handleCheckboxChange = ({ target: { id } }) => {
    if (checked.includes(id)) {
      setChecked((c) => c.filter((item) => item !== id));
    } else {
      setChecked((c) => [...c, id]);
    }
  };

  return (
    <div className="checkbox-list">
      <div className="p-4 min-w-60">
        {checkboxGroups.map(({ namespace, heading, checkboxes }) => (
          <React.Fragment key={namespace}>
            {!!heading && <h5>{heading}</h5>}
            <CheckboxGroup
              checkboxes={checkboxes}
              checked={checked}
              namespace={namespace}
              handleCheckboxChange={handleCheckboxChange}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="flex items-center justify-between p-4 border-t">
        <button type="submit" className="mr-4 button secondary checkbox-list__apply">
          Apply
        </button>
        {showSelectAll && (
          <label className="block">
            <input
              type="checkbox"
              id="column-selector-select-all"
              name="Select All"
              checked={checked.length === checkboxValues.length}
              onChange={handleSelectAllChange}
            />
            Select All
          </label>
        )}
      </div>
    </div>
  );
};

const CheckboxGroup = ({
  checkboxes,
  checked,
  handleCheckboxChange,
  namespace = "item"
}: CheckboxGroupProps): Node =>
  Object.entries(checkboxes).map(([key, name]) => (
    <label key={key} className="block">
      <input
        type="checkbox"
        name={`${namespace}[${key}]`}
        id={key}
        checked={checked.includes(key)}
        onChange={handleCheckboxChange}
      />
      {String(name)}
    </label>
  ));

type CheckboxValue = string;
type CheckboxLabel = string;

type CheckboxGroupProps = {
  checkboxes: { [key: CheckboxValue]: CheckboxLabel },
  checked: Array<CheckboxValue>,
  handleCheckboxChange: Function,
  namespace?: string
};

type CheckboxListProps = {
  checkboxGroups: [
    {
      checkboxes: { [key: CheckboxValue]: CheckboxLabel },
      heading?: string,
      namespace: string
    }
  ],
  initialChecked: Array<CheckboxValue>,
  showSelectAll: boolean
};

export default CheckboxList;
