import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["words", "wordCount"];

  static values = { emptyState: String, default: "0" };

  count() {
    const wordCount = (this.wordsTarget.value.match(/\w+/g) || []).length;

    this.wordCountTarget.textContent = wordCount === 0 ? this.emptyStateValue : wordCount;
  }
}
