// @flow

import { fetch } from "whatwg-fetch";
import wrapFetch from "fetch-retry";

const fetchWithRetries = wrapFetch(fetch);

// Here we delay the retry 1 seconds for the number of attempts. We don't really
// need to get fancy here with exponential backoff since the user probably won't
// hang around on the page for too long.
const retryDelay = (attempt: number): number => {
  return 1000 * attempt;
};

export const newFetch = (url: string, options: Object): Promise => {
  return fetchWithRetries(url, {
    ...options,
    retries: 50,
    retryOn: [504, 503, 502, 501, 500, 429],
    retryDelay: retryDelay
  })
    .then((response) => {
      if (!response.ok){
        throw new Error(
          `Request to ${url} returned non-OK status (${response.status})`
        );
      }

      return response;
    });
};

export default newFetch;
