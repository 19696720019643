// @flow

import React, { useState, useEffect } from "react";
import { fetch } from "whatwg-fetch";
import EstimateTable from "@elements/EstimateTable";
import FullEstimateTable from "@elements/FullEstimateTable";

import type { Node } from "react";
import type { Item } from "@elements/EstimateTable.js";
import type { SelectedCreativeOptions } from "./CreativeUploadWizard/types";

type CreativeEstimateProps = {
  estimateEndpoint: string,
  mail_type_id: string,
  fold_type_id: string,
  options: SelectedCreativeOptions,
  paper_type_id: string,
  creative_type_id: string,
  offsetEnabled: boolean
};

function CreativeEstimate({
  estimateEndpoint,
  mail_type_id,
  options,
  fold_type_id,
  paper_type_id,
  creative_type_id,
  offsetEnabled
}: CreativeEstimateProps): Node {
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<Item>>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const token = $("meta[name='csrf-token']").attr("content");

    fetch(estimateEndpoint, {
      method: "POST",
      body: JSON.stringify({
        mail_type_id,
        options,
        fold_type_id,
        paper_type_id,
        creative_type_id
      }),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": token
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setTotal(data.total);
        setItems(data.items);
      });
  }, [estimateEndpoint, mail_type_id, options, fold_type_id, paper_type_id, creative_type_id]);

  if (loading) return <div>Loading price per piece estimate</div>;

  if (offsetEnabled) {
    return <FullEstimateTable items={items} />;
  } else {
    return <EstimateTable items={items} total={total} total_label="Price per piece" />;
  }
}

export default CreativeEstimate;
