export function fetchWithCsrfToken(url, props) {
  const token = $('meta[name="csrf-token"]').attr("content");
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": token
    },
    ...props
  });
}

export default fetchWithCsrfToken;
