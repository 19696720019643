// @flow

import React, { useState, Fragment } from "react";
import type { Node } from "react";
import classNames from "classnames";
import TextInput from "@elements/TextInput";
import RailsFormData from "@utilities/FormatRailsFormData";

type Props = {
  passwordErrors: Array<string>,
  passwordConfirmationErrors: Array<string>
};

export default function NewPassword({ passwordErrors, passwordConfirmationErrors }: Props): Node {
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>(passwordErrors.join(" "));

  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [confirmationError, setConfirmationError] = useState<string>(
    passwordConfirmationErrors.join(" ")
  );

  function handlePasswordChange(event) {
    if (event.target.value === passwordConfirmation) {
      setConfirmationError("");
    }
    setPassword(event.target.value);
  }

  function handlePasswordBlur() {
    setPasswordError("");

    if (passwordConfirmation) {
      setConfirmationError(password === passwordConfirmation ? "" : "Passwords do not match");
    }
  }

  function handlePasswordConfirmationChange(event) {
    if (event.target.value === password) {
      setConfirmationError("");
    }
    setPasswordConfirmation(event.target.value);
  }

  function handlePasswordConfirmationBlur() {
    setConfirmationError(password === passwordConfirmation ? "" : "Passwords do not match");
  }

  return (
    <Fragment>
      <RailsFormData path="user" data={{ password, password_confirmation: passwordConfirmation }} />
      <div
        id="password__container"
        className={classNames("input-container relative", { "has-error": passwordError })}
      >
        <TextInput
          aria-errormessage="new-password-error"
          aria-invalid={!!passwordError}
          className="relative"
          error={passwordError}
          errorId="new-password-error"
          id="user_password"
          label="New Password"
          onBlur={handlePasswordBlur}
          onChange={handlePasswordChange}
          type="password"
          required
          value={password}
        />
      </div>
      <div className={classNames("input-container", { "has-error": confirmationError })}>
        <TextInput
          aria-errormessage="password-confirmation-error"
          aria-invalid={!!confirmationError}
          error={confirmationError}
          errorId="password-confirmation-error"
          label="Confirm New Password"
          onBlur={handlePasswordConfirmationBlur}
          onChange={handlePasswordConfirmationChange}
          type="password"
          required
          value={passwordConfirmation}
        />
      </div>
    </Fragment>
  );
}
