import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.launchConfetti();
  }

  launchConfetti() {
    for (let i = 0; i < 150; i += 1) {
      this.create(i);
    }
  }

  create(i) {
    const width = Math.random() * 10;
    const colour = ["yellow", "blue", "red"][Math.floor(Math.random() * 3)];

    const confetti = document.createElement("div");
    confetti.className = `confetti-${i} ${colour}`;
    confetti.style.width = `${width}px`;
    confetti.style.top = `${-Math.random() * 20}%`;
    confetti.style.left = `${Math.random() * 100}%`; // Randomly distribute along the width
    confetti.style.opacity = Math.random() + 0.5;
    confetti.style.transform = `rotate(${Math.random() * 360}deg)`;

    document.body.appendChild(confetti);

    this.drop(confetti);
  }

  drop(confetti) {
    $(confetti).animate(
      {
        top: "100%",
        left: `+=${Math.random() * 15}%`
      },
      Math.random() * 3000 + 3000,
      () => this.reset(confetti)
    );
  }

  reset(confetti) {
    $(confetti).animate(
      {
        top: `${-Math.random() * 20}%`,
        left: `-=${Math.random() * 15}%`
      },
      0,
      () => this.drop(confetti)
    );
  }
}
