// @flow

import React, { useState, useMemo, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import classNames from "classnames";

import RailsFormData from "@utilities/FormatRailsFormData";
import Tooltip from "@components/Tooltip";

import type { Node } from "react";

let counter = 0;
function uniqueId() {
  counter += 1;
  return `phone-input-${counter}`;
}

type Props = {
  className?: string,
  error?: boolean,
  initialNumber?: string,
  label?: string,
  modelName: string,
  modelColumn: string,
  tooltip?: string,
  required?: boolean
};

export default function PhoneNumberInput({
  className = "",
  error = false,
  initialNumber,
  label,
  modelName = "organization",
  modelColumn = "contact_phone",
  required,
  tooltip
}: Props): Node {
  const [number, setNumber] = useState<string>(initialNumber || "");
  const [focus, setFocus] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(error);
  const id = useMemo(uniqueId, []);
  const ref = useRef();

  return (
    <React.Fragment>
      <div
        className={`input-container ${className}`}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(false);

          if (ref.current?.reportValidity() === false) {
            setHasError(true);
          } else {
            setHasError(false);
          }
        }}
      >
        {label && (
          <label className={hasError ? "is-invalid-label" : ""} htmlFor={id}>
            {label}
            {!!tooltip && <Tooltip value={tooltip} className="ml-1" />}
          </label>
        )}
        <div className={classNames("input-field", { error: hasError, focus })}>
          <RailsFormData path={modelName} data={{ [modelColumn]: number }} />

          <PhoneInput
            aria-invalid={hasError}
            data-invalid={hasError}
            id={id}
            className={classNames("w-full", {
              "is-invalid-input": hasError
            })}
            defaultCountry="US"
            onChange={setNumber}
            required={required}
            value={number}
            initialValueFormat="national"
            type="tel"
            ref={ref}
          />
        </div>
        <span className={hasError ? "text-red-500 text-sm" : "form-error"}>
          Enter a valid phone number
        </span>
      </div>
    </React.Fragment>
  );
}
