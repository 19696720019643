// @flow
import React, { Fragment } from "react";
import type { Node } from "react";
import Eye from "react-feather/dist/icons/eye";
import EyeOff from "react-feather/dist/icons/eye-off";

type Props = {
  id: string,
  name: string,
  type: string,
  onClick: () => void,
  forgotPasswordUrl: string,
  autoComplete: string,
  dataMethod: string,
  autoFocus: boolean,
  label?: string
};

export default function PasswordInput({
  id,
  name,
  type,
  onClick,
  forgotPasswordUrl,
  autoComplete,
  dataMethod,
  label = 'Password',
  autoFocus = false,
}: Props): Node {
  return (
    <Fragment>
      <div
        className="password-labels"
        style={{ justifyContent: forgotPasswordUrl ? "left" : "flex-start" }}
      >
        <label htmlFor={id}>{label}</label>
      </div>
      <div className="input-field">
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className="input__with-icon"
          required="required"
          type={type}
          name={name}
          id={id}
        />
        <button onClick={onClick} type="button" className="input-icon">
          {type === "password" ? (
            <EyeOff size={20} aria-label="Hide Password" />
          ) : (
            <Eye size={20} aria-label="Show Password" />
          )}
        </button>
      </div>
      <div className="password-labels">
        {forgotPasswordUrl && (
          <a href={forgotPasswordUrl} data-method={dataMethod}>
            Forgot your password?
          </a>
        )}
      </div>
    </Fragment>
  );
}
