import { useEffect } from "react";
import { createPortal } from "react-dom";

export default function ModalActions({ children }) {
  return usePortal({ children, elementId: "header-actions" });
}

export function ModalTitle({ children }) {
  return usePortal({ children, elementId: "header-title" });
}

function usePortal({ children, elementId }) {
  const el = document.createElement("div");

  useEffect(() => {
    const mount = document.getElementById(elementId);
    mount.innerHTML = "";
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, elementId]);

  return createPortal(children, el);
}
