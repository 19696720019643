import _ from "lodash";
import ReactDOM from "react-dom";
import React, { Component } from "react";
import { MAP, POLYGON } from "react-google-maps/lib/constants";
import { withGoogleMap, GoogleMap, Polygon } from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import COLORS from "@config/colors";
import { US_CENTER, DEFAULT_STYLES } from "@config/google_maps";

import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAP_OPTIONS = {
  center: US_CENTER,
  clickableIcons: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  streetViewControl: false,
  styles: DEFAULT_STYLES,
  zoom: 4
};

const INCLUSIVE_POLYGON_OPTIONS = {
  fillColor: COLORS.jade,
  strokeColor: COLORS.jade
};

const EXCLUSIVE_POLYGON_OPTIONS = {
  fillColor: COLORS.gray,
  strokeColor: COLORS.gray
};

const Map = withGoogleMap(props => (
  <GoogleMap
    ref={props.mapMounted}
    options={GOOGLE_MAP_OPTIONS}
  >
    {props.drawable && (
      <DrawingManager
        defaultOptions={{
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            editable: props.editable,
            id: props.drawingId,
            ...INCLUSIVE_POLYGON_OPTIONS
          }
        }}
        onPolygonComplete={props.onPolygonComplete}
      />
    )}
    {_.reject(props.geofences, (g) => (_.isEmpty(g.coordinates))).map((geofence) => (
      <Polygon
        key={geofence.id}
        ref={props.polygonMounted}
        paths={geofence.coordinates}
        options={
          {
            editable: props.editable,
            id: geofence.id,
            ...(geofence.inclusive ? INCLUSIVE_POLYGON_OPTIONS : EXCLUSIVE_POLYGON_OPTIONS)
          }
        }
      />
    ))}
  </GoogleMap>
));

const DrawingTools = ({ onDragClick, onPolygonClick, onClearClick }) => (
  <div className="geofence-mapper__drawing-tools">
    <button type="button" onClick={onDragClick}>Move Map</button>
    <button type="button" onClick={onPolygonClick}>Draw Fence</button>
    <button type="button" className="clear" onClick={onClearClick}>Clear</button>
  </div>
);

class Mapper extends Component {
  constructor(props){
    super(props);

    const { display } = this.props;

    this.state = {
      drawable: false,
      bound: !!display,
      loadingGoogle: true
    };

    this._fitBounds = this._fitBounds.bind(this);
    this._mapMounted = this._mapMounted.bind(this);

    this._onDragClick = this._onDragClick.bind(this);
    this._onClearClick = this._onClearClick.bind(this);
    this._onPolygonClick = this._onPolygonClick.bind(this);

    this._polygonMounted = this._polygonMounted.bind(this);
    this._onPolygonComplete = this._onPolygonComplete.bind(this);
    this._registerListeners = this._registerListeners.bind(this);
    this._extractCoordinates = this._extractCoordinates.bind(this);
  }

  componentDidMount() {
    const { googleApiKey } = this.props;
    const loader = new Loader({
      apiKey: googleApiKey,
      libraries: ["drawing"]
    });

    loader.load().then(() =>{ this.setState({loadingGoogle: false}); });
  }

  componentDidUpdate(_prevProps){
    const { bound } = this.state;
    const { display } = this.props;

    if (!bound && display){ this._fitBounds(); }
  }

  _mapMounted(map){
    this._map = map.context[MAP];

    const { drawable } = this.props;

    if (drawable){
      const drawingToolsDiv = document.createElement("div");

      ReactDOM.render(
        <DrawingTools
          onDragClick={this._onDragClick}
          onClearClick={this._onClearClick}
          onPolygonClick={this._onPolygonClick}
        />,
        drawingToolsDiv
      );

      this._map.controls[google.maps.ControlPosition.TOP_LEFT].push(drawingToolsDiv);
    }

    this._fitBounds();
  }

  _onDragClick(){
    this.setState({ drawable: false });
  }

  _onPolygonClick(){
    const { polygon } = this.state;

    this.setState({ drawable: !polygon });
  }

  _onClearClick(){
    const { polygon } = this.state;
    const { onCoordinatesChange } = this.props;

    if (polygon && onCoordinatesChange){
      polygon.setMap(null);
      onCoordinatesChange(polygon.id, []);

      this.setState({ polygon: null });
    }
  }

  _polygonMounted(polygon){
    this._registerListeners(polygon.state[POLYGON]);

    this.setState({ polygon: polygon.state[POLYGON] });
  }

  _onPolygonComplete(polygon){
    this._registerListeners(polygon);
    this._extractCoordinates(polygon);

    this.setState({ polygon, drawable: false });
  }

  _fitBounds(){
    const { geofences } = this.props;
    const bounds = new google.maps.LatLngBounds();

    geofences.forEach((geofence) => {
      geofence.coordinates[0]?.forEach((path) => {
        bounds.extend(path);
      });
    });

    if (!bounds.isEmpty()){
      this._map.fitBounds(bounds);
    }
  }

  _registerListeners(polygon){
    const { drawable } = this.state;
    const { editable } = this.props;

    if (drawable || editable){
      polygon.getPaths().forEach((path) => {
        google.maps.event.addListener(path, "set_at", () => { this._extractCoordinates(polygon); });
        google.maps.event.addListener(path, "insert_at", () => { this._extractCoordinates(polygon); });
        google.maps.event.addListener(path, "remove_at", () => { this._extractCoordinates(polygon); });
      });
    }
  }

  _extractCoordinates(polygon){
    const { onCoordinatesChange } = this.props;

    if (onCoordinatesChange){
      const { id } = polygon;
      const coordinates = [];
      const rings = polygon.getPaths();

      rings.forEach((ring, i) => {
        const ringCoordinates = [];

        ring.getArray().forEach((point) => {
          ringCoordinates.push({ lng: point.lng(), lat: point.lat()});
        });

        coordinates.push(i === 0 ? ringCoordinates : ringCoordinates.reverse());
      });

      onCoordinatesChange(id, coordinates);
    }
  }

  render(){
    const { drawable, loadingGoogle } = this.state;
    const { editable, geofences, drawingId } = this.props;

    if (loadingGoogle) {
      return <React.Fragment/>;
    }

    return (
      <Map
        editable={editable}
        drawable={drawable}
        drawingId={drawingId}
        geofences={geofences}
        mapMounted={this._mapMounted}
        polygonMounted={this._polygonMounted}
        onPolygonComplete={this._onPolygonComplete}
        loadingElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ width: "100%", height: "100%" }} />}
        containerElement={<div style={{ width: "100%", height: "500px" }} />}
      />
    );
  }
}

export default Mapper;
