// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  name: string,
  screenReaderLegend: string,
  selectedValue: any,
  selectionOptions: Array<{
    value: any,
    label: string
  }>,
  valueOnChange: (any) => void
};

export default function RadioButtonGroup({
  name,
  screenReaderLegend,
  selectedValue,
  selectionOptions,
  valueOnChange
}: Props): Node {
  return (
    <fieldset>
      <legend className="sr-only">{screenReaderLegend}</legend>
      <div className="button-group">
        {selectionOptions.map(({ value, label }) => {
          return (
            <div key={value} className="button-group__item">
              <input
                checked={value === selectedValue}
                className="sr-only button-group__radio"
                id={value + label}
                name={name}
                onChange={() => valueOnChange(value)}
                type="radio"
                value={value}
              />
              <label className="button-group__button" htmlFor={value + label}>
                {label}
              </label>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}
