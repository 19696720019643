import React, { useEffect, useState } from "react";
import classNames from "classnames";
import useOutsideClick from "@utilities/useOutsideClick";

const SelectorFang = () => (
  <svg className="fang" fill="none" width="20" height="10">
    <path className="fang-shape fill-white" d="M0,10 20,10 10,0z" />
    <path className="fang-stroke stroke-gray-200" d="M0,10 10,0 20,10" />
  </svg>
);

function ToolbarDropdown({ children, icon, button_text }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  const toggleOpen = () => {
    setIsOpen((open) => !open);
  };

  return (
    <div className="toolbar-dropdown" ref={ref}>
      <button
        className="toolbar__button text-button"
        type="button"
        onClick={toggleOpen}
        aria-controls="column-dropdown"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {icon}
        {button_text}
      </button>
      <div
        id="column-dropdown"
        data-dropdown
        data-auto-focus="true"
        className={classNames("toolbar-dropdown__dropdown", { hidden: !isOpen })}
      >
        <SelectorFang />
        {children}
      </div>
    </div>
  );
}

export default ToolbarDropdown;
