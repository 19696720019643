import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    initialState: { type: String, default: "closed" }
  };

  connect() {
    this.dialog =
      this.element.nodeName === "DIALOG" ? this.element : this.element.querySelector("dialog");

    if (this.initialStateValue === "open") {
      this.open();
    }

    this.detectOutsideClick = this.detectOutsideClick.bind(this);
    this.dialog.addEventListener("click", this.detectOutsideClick);
  }

  detectOutsideClick(e) {
    const dialogBounds = this.dialog.getBoundingClientRect();
    if (
      e.clientX < dialogBounds.left ||
      e.clientX > dialogBounds.right ||
      e.clientY < dialogBounds.top ||
      e.clientY > dialogBounds.bottom
    ) {
      this.close();
    }
  }

  close() {
    this.dialog.close();
  }

  open() {
    this.dialog.showModal();
  }
}
