//@flow

import React, { useState } from "react";
import Uploader from "@components/Uploader";
import type { UploaderProps } from "@components/Uploader/types";
import SelectTag from "@elements/SelectTag";
import type { Option as SelectTagOption } from "@elements/SelectTag";
import type { Option as AgreementOption } from "@elements/SelectTag";

type UploaderOptions = {
  [string]: {
    default: string,
    type: string
  }
};

type AdminAudienceUploaderProps = UploaderProps & {
  dataProviders: SelectTagOption[],
  providerAgreements: AgreementOption[]
};

function AdminAudienceUploader({
  config,
  dataProviders,
  providerAgreements,
  ...props
}: AdminAudienceUploaderProps): React$Element<"fieldset" | typeof Uploader> {
  const { options: configOptions } = config;
  const [uploaderDisplay, setUploaderDisplay] = useState<boolean>(false);
  const [options, setOptions] = useState<UploaderOptions>(configOptions);

  /**
   * TODO: Create a method for passing option values without overriding the config defaults
   * - add an optionsValues prop to Uploader and use it to replace this and the `use props` workaround for address_strictness
   */
  const setOptionValue = (option, value) => {
    const newOptions = { ...options };
    newOptions[option].default = value;
    setOptions(newOptions);
  };

  return uploaderDisplay ? (
    <Uploader {...props} config={{ ...config, options: options }} />
  ) : (
    <fieldset className="max-width-2xl">
      <div className="input-container">
        <SelectTag
          data={dataProviders}
          label="Data Provider"
          value=""
          className="w-full"
          onChange={(e) => setOptionValue("data_provider", (e.target.value: any))}
          required
        />
      </div>
      <div className="input-container">
        <SelectTag
          data={providerAgreements}
          label="Provider Agreement"
          value=""
          className="w-full"
          onChange={(e) => setOptionValue("provider_agreement", (e.target.value: any))}
          required
        />
      </div>
      <button type="button" className="mt-4 slm-button" onClick={() => setUploaderDisplay(true)}>
        Continue
      </button>
    </fieldset>
  );
}

export default AdminAudienceUploader;
