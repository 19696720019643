// @flow
import React from "react";
import { useRateGroup } from "./context";
import type { RateGroupState, RateGroupDetails } from "./types";
import ClockIcon from "react-feather/dist/icons/clock";

function RateDetailsFields(): React$Node {
  const { state, setGroupValue } = useRateGroup();
  const { name, startDate, endDate, globalDefault }: RateGroupState = state;

  const changeHandler = (id: $Keys<RateGroupDetails>) => {
    return ({ target }: SyntheticInputEvent<HTMLInputElement | HTMLSelectElement>) => {
      setGroupValue(id, target.value);
    };
  };

  return (
    <div className="grid grid-cols-2 gap-4 mb-8">
      <div className="col-span-2">
        <label className="mb-1 text-sm font-normal" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          name="rate_group[name]"
          id="name"
          value={name}
          onChange={changeHandler("name")}
        />
      </div>
      <div className="col-span-2">
        <label className="mb-1 text-sm font-normal" htmlFor="globalDefault">
          Global Default
        </label>
        <select
          name="rate_group[global_default]"
          id="globalDefault"
          className="px-2 border rounded"
          value={globalDefault}
          onChange={changeHandler("globalDefault")}
        >
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
      </div>
      <div>
        <label className="mb-1 text-sm font-normal" htmlFor="startDate">
          Start Date
        </label>
        <div className="flex">
          <button
            type="button"
            className="flex w-10 p-0 text-gray-500 rounded-r-none secondary"
            onClick={() => {setGroupValue('startDate', new Date().toISOString().substr(0, 16))}}
          >
            <ClockIcon className="m-auto" size="18" />
          </button>
          <input
            type="datetime-local"
            name="rate_group[start_date]"
            id="startDate"
            value={startDate}
            onChange={changeHandler("startDate")}
            className="rounded-l-none"
            step="1"
          />
        </div>
      </div>
      <div>
        <label className="mb-1 text-sm font-normal" htmlFor="endDate">
          End Date
        </label>
        <input
          type="datetime-local"
          name="rate_group[end_date]"
          id="endDate"
          value={endDate}
          onChange={changeHandler("endDate")}
          step="1"
        />
      </div>
    </div>
  );
}

export default RateDetailsFields;
