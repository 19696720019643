// @flow
import React, { useState } from "react";
import CopyIcon from "react-feather/dist/icons/copy";
import CheckIcon from "react-feather/dist/icons/check";
import sleep from "@utilities/sleep";
import type { Node } from "react";
import Tooltip from "@components/Tooltip";

const clipboardEnabled = !!navigator.clipboard;

const ClipboardButton = ({
  value,
  className = "",
  title = "Copy to clipboard"
}: ClipboardButtonProps): Node => {
  const [copied, setCopied] = useState(false);

  const success = () => setCopied(true);
  const reset = () => setCopied(false);

  const handleClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(value).then(success).then(sleep(800)).then(reset);
  };
  return (
    clipboardEnabled && (
      <Tooltip className={`clipboard-button ${className}`} value={title}>
        <button type="button" aria-label={title} onClick={handleClick} disabled={copied}>
          {copied ? (
            <div className="flex items-center gap-1 text-xs text-gray-500">
              Copied
              <CheckIcon size="20" className="flex-shrink-0 text-jade-500 animate-scale-up" />
            </div>
          ) : (
            <CopyIcon size="20" className="animate-scale-up" />
          )}
        </button>
      </Tooltip>
    )
  );
};

type ClipboardButtonProps = {
  value: string,
  className?: string,
  title?: string
};

export default ClipboardButton;
