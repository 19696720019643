// @flow

import React, { useState, Fragment } from "react";
import type { Node } from "react";
import IconButton from "@components/IconButton";
import type { Role, RoleId } from "./types";
import EditTeamMember from "./EditTeamMember";

type Props = {
  accountTeamPath: string,
  allowEdit: boolean,
  allowRemove: boolean,
  currentRoleId: RoleId,
  initialInviteStatus: "accepted" | "not_invited" | "expired" | "invited",
  resendInvitationPath: string,
  updatePath: string,
  userEmail: string,
  userName: string,
  userRoles: Array<Role>
};

const INVITE_STATUS = {
  not_invited: "",
  accepted: "",
  expired: "Invitation Expired",
  invited: "Invited",
  resent: "Invite Resent",
  loading: "Sending Invite",
  error: "Error resending invite"
};

export default function AccountTeamTableRow({
  accountTeamPath,
  allowEdit,
  allowRemove,
  currentRoleId,
  initialInviteStatus,
  resendInvitationPath,
  updatePath,
  userEmail,
  userName,
  userRoles
}: Props): Node {
  const [inviteStatus, setInviteStatus] = useState<InviteStatusMessage>(
    INVITE_STATUS[initialInviteStatus]
  );

  function handleResendInvite() {
    const token = (document.querySelector("meta[name=csrf-token]"): any).content;

    setInviteStatus(INVITE_STATUS.loading);
    fetch(resendInvitationPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": token
      }
    }).then((response) => {
      if (response.ok) {
        setInviteStatus(INVITE_STATUS.resent);
      } else {
        setInviteStatus(INVITE_STATUS.error);
      }
    });
  }

  return (
    <Fragment>
      <td>{userName}</td>
      <td className="py-2">
        <div>{userEmail}</div>
        <InviteStatusDisplay inviteStatus={inviteStatus} />
      </td>
      <td>{userRoles.find(({ id }) => id === currentRoleId)?.display_name}</td>
      <td>
        <div className="flex items-center justify-end gap-2">
          {inviteStatus !== INVITE_STATUS.accepted ? (
            <IconButton
              onClick={handleResendInvite}
              iconName="repeat"
              toolTipText="Resend Invite"
            />
          ) : (
            allowEdit && (
              <EditTeamMember
                userRoles={userRoles}
                showRoles
                currentRoleId={currentRoleId}
                userEmail={userEmail}
                updatePath={updatePath}
              />
            )
          )}
          {allowRemove && (
            <IconButton
              iconName="trash2"
              href={accountTeamPath}
              rel="nofollow"
              toolTipText="Delete"
              data-confirm="Are you sure? This action cannot be undone."
              data-method="delete"
            />
          )}
        </div>
      </td>
    </Fragment>
  );
}

type DisplayProps = {
  inviteStatus: InviteStatusMessage
};

function InviteStatusDisplay({ inviteStatus }: DisplayProps): Node {
  if (inviteStatus === INVITE_STATUS.accepted) return null;

  const statusClass = [INVITE_STATUS.error, INVITE_STATUS.expired].includes(inviteStatus)
    ? "error"
    : "pending";

  return <div className={`${statusClass} status mt-2`}>{inviteStatus}</div>;
}

type InviteStatusMessage =
  | ""
  | "Invitation Expired"
  | "Invited"
  | "Invite Resent"
  | "Sending Invite"
  | "Error resending invite";
