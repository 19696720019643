// @flow

import React, { Fragment } from "react";
import type { Node } from "react";
import AlertTriangle from "react-feather/dist/icons/alert-triangle";
import Edit from "react-feather/dist/icons/edit";
import Info from "react-feather/dist/icons/info";
import Repeat from "react-feather/dist/icons/repeat";
import Trash2 from "react-feather/dist/icons/trash-2";
import Lock from "react-feather/dist/icons/lock";

export type FeatherIconName =
  | "alert-triangle"
  | "edit"
  | "info"
  | "lock"
  | "repeat"
  | "trash-2"
  | "trash2";

type Props = {
  iconName: FeatherIconName
};

export default function FeatherIcon({ iconName, ...rest }: Props): Node {
  switch (iconName) {
    case "alert-triangle":
      return <AlertTriangle {...rest} />;
    case "edit":
      return <Edit {...rest} />;
    case "info":
      return <Info {...rest} />;
    case "lock":
      return <Lock {...rest} />;
    case "repeat":
      return <Repeat {...rest} />;
    case "trash-2":
    case "trash2":
      return <Trash2 {...rest} />;
    default:
      return <Fragment />;
  }
}
