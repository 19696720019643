import React from "react";
import { Bar } from "react-chartjs-2";

class SoloInHomeStats extends React.Component {
  constructor(props) {
    // props:
    // chartData
    // barColor
    // lineColor
    super(props);
    this.setFontOptions();
    this.setYaxisMin();
  }

  setFontOptions() {
    Chart.defaults.global.defaultFontFamily = "Soleil";
  }

  setYaxisMin() {
    Chart.scaleService.updateScaleDefaults("linear", {
      ticks: {
        min: 0,
      },
    });
  }

  percents() {
    let pcts = [];
    let total = 0;
    Object.entries(this.props.chartData).forEach(entry => {
      const [_date, stats] = entry;
      total += stats["percent"];
      pcts.push(total.toFixed(2));
    });
    return pcts;
  }

  counts() {
    let cnts = [];
    Object.entries(this.props.chartData).forEach(entry => {
      const [_date, stats] = entry;
      cnts.push(stats["count"]);
    });
    return cnts;
  }

  dateRange() {
    return Object.keys(this.props.chartData);
  }

  data() {
    return {
      datasets: [
        {
          label: "% In Home",
          type: "line",
          data: this.percents(),
          fill: false,
          borderColor: this.props.lineColor,
          backgroundColor: this.props.lineColor,
          pointBorderColor: this.props.lineColor,
          pointBackgroundColor: this.props.lineColor,
          pointHoverBackgroundColor: this.props.lineColor,
          pointHoverBorderColor: this.props.lineColor,
          yAxisID: "y-axis-2",
        },
        {
          type: "bar",
          label: "In Home",
          data: this.counts(),
          fill: false,
          backgroundColor: this.props.barColor,
          borderColor: this.props.barColor,
          hoverBackgroundColor: this.props.barColor,
          hoverBorderColor: this.props.barColor,
          yAxisID: "y-axis-1",
        },
      ],
    };
  }

  options() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      legend: {
        position: 'bottom',
        labels: {
          fontFamily: 'Soleil',
          fontSize: 14,
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            labels: this.dateRange(),
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            gridLines: {
              display: false,
            },
            ticks: {
              callback: function (value, _index, _values) {
                return value + "%";
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div style={{height: '300px'}}>
        <Bar data={this.data()} options={this.options()} />
      </div>
    );
  }
}

export default SoloInHomeStats;
