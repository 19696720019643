const COLORS = {
  black: "#111111",
  black_light: "#272727",
  black_lighter: "#333",
  blue: "#0082a3",
  blue_dark: "#014559",
  blue_light: "#63cadc",
  blue_lighter: "#b8f0f4",
  gold: "#e08420",
  gold_dark: "#963e12",
  gold_light: "#f7ce8d",
  gold_lighter: "#fceec3",
  gray: "#707070",
  gray_light: "#dedede",
  gray_lighter: "#fafafa",
  gray_mid: "#ccc",
  jade: "#00a373",
  jade_dark: "#01594a",
  jade_light: "#89dec5",
  jade_lighter: "#eafbf2",
  purple: "#639",
  purple_dark: "#21114e",
  purple_light: "#c97ada",
  purple_lighter: "#ffdbc7",
  red: "#f86363",
  red_alert: "#ff0000",
  red_dark: "#e13d3d",
  red_light: "#c62424",
  red_lighter: "#ffcec7",
  red_lightest: "#FFF0EE",
  warning_yellow: "#FFD47C",
  white: "#fff",
  white_dark: "#fafafa",
  white_darker: "#efefef"
};

export default COLORS;
