// @flow
import React, { useEffect, useRef, useState, useCallback } from "react";
import { createPortal } from "react-dom";
import type { Node } from "react";
import FeatherIcon from "@elements/FeatherIcon";
import type { FeatherIconName } from "@elements/FeatherIcon";

type ToolTipProps = {
  id?: string,
  value: Node,
  children?: Node,
  className?: string,
  icon?: FeatherIconName
};

export default function Tooltip({
  value,
  children,
  icon = "info",
  className = "",
  id
}: ToolTipProps): Node {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({});
  const messageRef = useRef();
  const iconRef = useRef();

  const showMessage = useCallback(() => setIsOpen(true), []);
  const hideMessage = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (isOpen && iconRef.current && messageRef.current) {
      const coordinates = calculateCoordinates(iconRef.current, messageRef.current);
      setPosition(coordinates);
    }
  }, [isOpen]);

  return (
    !!value && (
      <div
        aria-label={value}
        className={`tooltip ${className}`}
        id={id}
        onBlur={hideMessage}
        onFocus={showMessage}
        onMouseEnter={showMessage}
        onMouseLeave={hideMessage}
        ref={iconRef}
      >
        {isOpen && (
          <TooltipPortal>
            <div
              className="tooltip-text"
              ref={messageRef}
              style={{ ...position }}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </TooltipPortal>
        )}
        {children || <FeatherIcon iconName={icon} size="16" className="tooltip-icon" />}
      </div>
    )
  );
}

type Coordinates = {
  left: number,
  top: number,
  "--fang-position": string
};

/**
 * Calculate the coordinates for tooltip text
 */
function calculateCoordinates(iconEl: any, messageEl: any): Coordinates {
  const gutter = 10;
  const { left: iconLeft, top: iconTop, width: iconWidth } = iconEl.getBoundingClientRect();
  const { width: textWidth, height: textHeight } = messageEl.getBoundingClientRect();

  const iconCenter = iconLeft + iconWidth / 2;
  const halfWidth = textWidth / 2;
  const docWidth = document.body.clientWidth;

  let left;

  if (iconCenter - halfWidth - gutter < 0) {
    // prevent text from flowing off the left side of the page
    left = gutter;
  } else if (iconCenter + halfWidth + gutter > docWidth) {
    // prevent text from flowing off the right side of the page
    left = docWidth - textWidth - gutter;
  } else {
    // default to centering text above the icon
    left = iconCenter - halfWidth;
  }

  const fangPosition = iconCenter - left + "px";

  return {
    left,
    top: iconTop - textHeight - 5,
    "--fang-position": iconCenter - left + "px"
  };
}

type TooltipPortalProps = {
  children: Node
};

/**
 * Create a portal for appending tooltip text to the document body
 */
function TooltipPortal({ children }: TooltipPortalProps): React$Portal {
  const el = document.createElement("div");
  el.id = "tooltip-portal";

  useEffect(() => {
    document.body.appendChild(el);
    // $FlowFixMe
    return () => document.body.removeChild(el);
  }, [el, document.body]);

  return createPortal(children, el);
}
