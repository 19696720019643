import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "rotation"];

  ROTATION_STEP = 90;

  connect() {
    this.currentRotation = 0;
  }

  flip() {
    this.elementTarget.classList.toggle("flipped");
  }

  rotateRight() {
    this.rotateTo(this.currentRotation + this.ROTATION_STEP);
  }

  rotateLeft() {
    this.rotateTo(this.currentRotation - this.ROTATION_STEP);
  }

  rotateTo(deg) {
    if (!this.hasRotationTarget) return;

    this.rotationTarget.style.transform = `rotate(${deg}deg)`;
    this.element.classList.toggle("rotated", deg % 180);
    this.currentRotation = deg;
  }

  fold({ currentTarget }) {
    this.elementTarget.classList.toggle("folded");
    this.toggleText(currentTarget.firstElementChild, "Fold", "Unfold");
  }

  toggleText(el, str1, str2) {
    el.innerHTML = el.textContent.trim() === str1 ? str2 : str1;
  }
}
