// @flow

import React, { useState } from "react";
import type { Node } from "react";
import Button from "@elements/Button";
import Modal from "@elements/Modal";
import ConfirmPlan from "./ConfirmPlan";

import type { Plan } from "./types";

type Props = {
  formAction: string,
  plan: Plan,
  start_date: string, // MM/DD/YYYY
  end_date: string // MM/DD/YYYY
};

export default function UpgradePlanButton({ formAction, plan, start_date, end_date }: Props): Node {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Button
        className="w-full"
        label="Upgrade Plan"
        onClick={() => setShowModal(true)}
        onKeyPress={(e) => e.key === "Enter" && setShowModal(true)}
        tabIndex={0}
      />
      <Modal onClose={() => setShowModal(false)} show={showModal}>
        <ConfirmPlan
          formAction={formAction}
          formMethod="post"
          plan={plan}
          startDate={start_date}
          endDate={end_date}
        />
      </Modal>
    </React.Fragment>
  );
}
