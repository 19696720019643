import { Controller } from "@hotwired/stimulus";

import pluralize from "pluralize";

export default class extends Controller {
  static targets = ["checkbox", "selectAll"];

  static values = {
    selectedCountId: { type: String, default: "selectedCount" },
    resourceName: { type: String, default: "" }
  };

  connect() {
    this.selectedCountDisplay = document.getElementById(this.selectedCountIdValue);
    this.updateSelectAllCheckbox();
  }

  selectAll(event) {
    const isChecked = event.target.checked;

    // Loop through all checkbox targets and set their checked state to match the 'selectAll' checkbox
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });

    this.updateSelectedCount();
  }

  onCheckboxChange() {
    this.updateSelectedCount();

    this.updateSelectAllCheckbox();
  }

  updateSelectedCount() {
    const selectedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;
    this.selectedCountDisplay ||= document.getElementById(this.selectedCountIdValue);

    if (this.selectedCountDisplay) {
      this.selectedCountDisplay.textContent = `${selectedCount} ${pluralize(
        this.resourceNameValue,
        selectedCount
      )}`;
    }
  }

  updateSelectAllCheckbox() {
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = this.checkboxTargets.every((checkbox) => checkbox.checked);
    }
  }
}
