// @flow
import { useEffect, useRef } from "react";

export default function useOutsideClick(onClickOutside?: () => void): any {
  const node = useRef();

  useEffect(() => {
    function handleClick(e) {
      if (!onClickOutside || !node.current) return;

      const userClickedInsideHtmlNode = node.current.contains(e.target);
      if (userClickedInsideHtmlNode) return;

      onClickOutside();
    }

    if (onClickOutside) {
      document.addEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClickOutside]);

  return node;
}
