import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.resizeObserver = new ResizeObserver((entries) => {
      const {borderBoxSize : [size]} = entries[0]

      document.querySelector(':root').style.setProperty('--header-height', size.blockSize + 'px');
    });
  }

  connect() {
    this.resizeObserver.observe(this.element)
  }

  disconnect() {
    this.resizeObserver.unobserve(this.element)
  }
}

