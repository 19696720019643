// @flow
import React, { Fragment } from "react";
import _get from "lodash/get";
import type { Node } from "react";
import { LazyLoadedBarChart, useLazyLoadedAnnotationImport } from "./ReactChart";

type GraphOptions = {
  tooltips: any,
  animation: any,
  legend: any,
  scales: any,
  layout?: any
};

const DEFAULT_OPTIONS: GraphOptions = {
  maintainAspectRatio: false,
  tooltips: {
    enabled: false
  },
  animation: {
    duration: 0
  },
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          display: true
        },
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          max: 15000,
          maxTicksLimit: 5,
          min: 0,
          color: "rgba(222,222,222,1)",
          callback: formatNumericValue
        },
        gridLines: {
          display: true,
          color: "rgba(222,222,222,1)",
          borderDash: [1, 3]
        },
        stacked: false
      }
    ]
  }
};

type GraphData = {
  labels: Array<string>,
  datasets: Array<any>
};

const DEFAULT_DATA: GraphData = {
  labels: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
  ],
  datasets: [
    {
      label: "Mailings",
      backgroundColor: "#89DEC6",
      borderColor: "#89DEC6",
      data: []
    }
  ]
};

function hideLastTick(scaleInstance) {
  // example ticks [417, 400, 300, 200, 100, 0].
  // 417 will appear right above 400 and text of 417 may even overlap 400.
  // Removing the last tick gives a cleaner look.
  // eslint-disable-next-line no-param-reassign
  scaleInstance.ticks[0] = null;
}

function addPercentSymbolToYAxesTicks(yAxes) {
  // eslint-disable-next-line no-param-reassign
  yAxes.ticks.callback = (value) =>
    yAxes.id === "percent" ? `${value}%` : formatNumericValue(value);
}

function formatNumericValue(value) {
  return "number" ? value.toLocaleString("en") : value;
}

type CampaignGraphProps = {
  data: GraphData,
  options: GraphOptions,
  notDaily: boolean,
  shouldHideLastTick: boolean
};

export default function CampaignGraph({
  data,
  options,
  notDaily,
  shouldHideLastTick
}: CampaignGraphProps): Node {
  const tooltipsCallbacks = {
    title: (tooltipItem, _data) => {
      let title = tooltipItem["0"].label || "";
      if (notDaily === true) {
        title = `Week of ${title}`;
      }
      return title;
    },
    label: (tooltipItem, { datasets }) => {
      let label = datasets[tooltipItem.datasetIndex].label || "";

      if (label === "Spend") {
        label += ": $";
      } else if (label) {
        label += ": ";
      }

      label += formatNumericValue(tooltipItem.yLabel);
      return label;
    }
  };
  const annotationPlugin = useLazyLoadedAnnotationImport();
  if (annotationPlugin === "loading") return <Fragment />;

  const nestedDataIsEmpty = data.datasets.every((dataset) => dataset.data.every((e) => e === 0));
  if (data.datasets[0].data.length === 0 || nestedDataIsEmpty) {
    DEFAULT_OPTIONS.tooltips.callbacks = tooltipsCallbacks;

    return (
      <div className="relative">
        <LazyLoadedBarChart
          height={300}
          data={DEFAULT_DATA}
          options={DEFAULT_OPTIONS}
          plugins={[annotationPlugin]}
        />
      </div>
    );
  } else {
    const optionsWithTooltipCallbacks = { ...options };
    optionsWithTooltipCallbacks.tooltips.callbacks = tooltipsCallbacks;

    if (shouldHideLastTick && Array.isArray(_get(options, "scales.yAxes"))) {
      optionsWithTooltipCallbacks.scales.yAxes[0].afterTickToLabelConversion = hideLastTick;
      optionsWithTooltipCallbacks.scales.yAxes[1].afterTickToLabelConversion = hideLastTick;
    }

    optionsWithTooltipCallbacks.scales.yAxes.forEach(addPercentSymbolToYAxesTicks);
    optionsWithTooltipCallbacks.layout = {
      padding: {
        top: 10 // top most tick mark can be cut off
      }
    };

    return (
      <div className="relative">
        <LazyLoadedBarChart
          height={300}
          data={data}
          options={optionsWithTooltipCallbacks}
          plugins={[annotationPlugin]}
        />
      </div>
    );
  }
}
