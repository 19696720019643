// @flow

import React, { useState, Fragment } from "react";
import type { Node } from "react";
import AlertIcon from "react-feather/dist/icons/alert-triangle";


import RailsFormData from "@utilities/FormatRailsFormData";
import TextInput from "@elements/TextInput";
import RadioButton from "@elements/RadioButton";

const BLANK_ADDRESS = {
  company: "",
  address_1: "",
  address_2: "",
  city: "",
  state_name: "",
  postal_code: ""
};

type Props = {
  organization_address: Address,
  location_return_address_mode: AddressMode,
  campaign_address: Address
};

export default function ReturnAddressForm({
  organization_address,
  location_return_address_mode,
  campaign_address,
  settings_url
}: Props): Node {
  const [locationReturnAddressMode, setLocationReturnAddressMode] = useState<AddressMode>(
    location_return_address_mode
  );
  const [addressAttributes, setAddressAttributes] = useState<Address>(
    campaign_address ?? BLANK_ADDRESS
  );

  function handleAddressChange(name: string, value: string) {
    setAddressAttributes((previousAddressAttributes) => {
      return { ...previousAddressAttributes, [name]: value };
    });
  }

  function organizationLabel(): Node {
    const { address_1, address_2, city, state_name, postal_code } = organization_address || {};
    const combinedAddressLines = `${address_1} ${address_2 || ""}`.trim();

    return (
      <span>
        Organization
        {organization_address ? (
          <small>{`${combinedAddressLines}, ${city}, ${state_name} ${postal_code}`}</small>
        ) : (
          <small className="!flex items-center">
            <AlertIcon size="16" className="text-yellow-500" />
            <span className="mx-1">Return address not set.</span>
            <a href={settings_url}>Set one here</a>.
            </small>
        )}
      </span>
    );
  }

  const address_attributes = locationReturnAddressMode === "campaign" ? addressAttributes : {};

  return (
    <Fragment>
      <RailsFormData
        path="campaign"
        data={{ location_return_address_mode: locationReturnAddressMode, address_attributes }}
      />
      <ul className="radio-list">
        <li>
          <RadioButton
            id="mode-organization"
            name="mode"
            value="organization"
            label={organizationLabel()}
            onChange={(event) => setLocationReturnAddressMode(event.target.value)}
            checked={locationReturnAddressMode === "organization"}
          />
        </li>
        <li>
          <RadioButton
            id="mode-campaign"
            name="mode"
            value="campaign"
            label="Campaign Return Address"
            onChange={(event) => setLocationReturnAddressMode(event.target.value)}
            checked={locationReturnAddressMode === "campaign"}
          />
        </li>
      </ul>
      <AddressForm
        hidden={locationReturnAddressMode !== "campaign"}
        onChange={handleAddressChange}
        address={addressAttributes}
      />
    </Fragment>
  );
}

const AddressForm = ({ onChange, hidden, address }) => {
  if (hidden) return null;

  const { company, name, address_1, address_2, city, state_name, postal_code } =
    address || BLANK_ADDRESS;

  return (
    <fieldset className="py-4 -mt-5 bg-white border border-t-0 rounded px-9">
      <div className="form-fields-grid">
        <TextInput
          label="Company"
          required
          value={company || name || ""}
          onChange={(e) => onChange("company", e.target.value)}
        />
        <TextInput
          label="Address 1"
          required
          value={address_1}
          onChange={(e) => onChange("address_1", e.target.value)}
          autoComplete="address-line1"
        />
        <TextInput
          label="Address 2"
          value={address_2}
          onChange={(e) => onChange("address_2", e.target.value)}
          autoComplete="address-line2"
        />
        <TextInput
          className="small"
          label="City"
          required
          value={city}
          onChange={(e) => onChange("city", e.target.value)}
          autoComplete="city"
        />
        <TextInput
          className="x-small"
          label="State"
          required
          value={state_name}
          onChange={(e) => onChange("state_name", e.target.value)}
          autoComplete="state"
        />
        <TextInput
          className="x-small"
          label="Zip code"
          required
          value={postal_code}
          onChange={(e) => onChange("postal_code", e.target.value)}
          autoComplete="postal-code"
        />
      </div>
    </fieldset>
  );
};

type Address = {
  company: string,
  name: string,
  address_1: string,
  address_2: string,
  city: string,
  state_name: string,
  postal_code: string
};

type AddressMode = "campaign" | "organization";
