// @flow

import React from "react";
import type { Node } from "react";

import Modal from "@elements/Modal";
import TextInput from "@elements/TextInput";
import PhoneNumberInput from "@components/PhoneNumberInput";

import useCsrfToken from "@utilities/useCsrfToken";

type Props = {
  closeModal: () => void,
  open: boolean,
  formAction: string
};

export default function FormModal({ closeModal, open, formAction }: Props): Node {
  const token = useCsrfToken();

  function onSubmit(e) {
    if (!e.target.reportValidity()) {
      e.preventDefault();
    }
  }

  return (
    <Modal onClose={closeModal} show={open}>
      <h1 className="text-2xl font-bold text-gray-700">Send a Sample</h1>
      <h2 className="font-bold text-gray-700">Address</h2>
      <form
        id="submit_target"
        acceptCharset="UTF-8"
        action={formAction}
        method="post"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="_method" value="post" autoComplete="off" />
        <input type="hidden" name="authenticity_token" value={token} />
        <AddressForm />
        <h2 className="pt-4 font-bold text-gray-700">Number of Copies</h2>
        <TextInput
          className="w-16"
          label="Qty"
          name="sample_request[copies]"
          type="number"
          min="1"
          required
        />
        <div className="flex justify-end">
          <button type="button" onClick={closeModal} className="w-24 mr-2 slm-button secondary">
            Cancel
          </button>
          <button type="submit" className="w-24 slm-button">
            Send
          </button>
        </div>
      </form>
    </Modal>
  );
}

function AddressForm() {
  const model = "sample_request[address_attributes]";
  return (
    <fieldset className="grid grid-cols-6 gap-x-6">
      <TextInput name={`${model}[full_name]`} className="col-span-3" label="Full Name" required />
      <TextInput name={`${model}[company]`} className="col-span-3" label="Company" required />
      <TextInput
        name={`${model}[address_1]`}
        className="col-span-5"
        label="Street Address"
        required
        autoComplete="address-line1"
      />
      <TextInput
        name={`${model}[address_2]`}
        className="col-span-1"
        label="Apt/Ste"
        autoComplete="address-line2"
      />
      <TextInput
        name={`${model}[city]`}
        className="col-span-2"
        label="City"
        required
        autoComplete="city"
      />
      <TextInput
        name={`${model}[state_name]`}
        className="col-span-2"
        label="State"
        required
        autoComplete="state"
      />
      <TextInput
        name={`${model}[postal_code]`}
        className="col-span-2"
        label="Zip code"
        required
        autoComplete="postal-code"
      />
      <PhoneNumberInput
        className="col-span-2"
        label="Phone Number"
        modelName={model}
        modelColumn="phone"
        required
      />
    </fieldset>
  );
}
