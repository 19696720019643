// @flow
import type { InitialStateReturn, SortableColumn } from "./types";

export default function initialState(
  url: string,
  sortableColumns: Array<SortableColumn>
): InitialStateReturn {
  const urlObj = new URL(url);

  const parsedSortColumn = urlObj.searchParams.get("sort_column");
  const parsedSortDirection = urlObj.searchParams.get("sort_direction");

  // $FlowIgnore[incompatible-type] not a real error
  const column: SortableColumn = sortableColumns.includes(parsedSortColumn)
    ? parsedSortColumn
    : undefined;
  const direction: "desc" | "asc" = parsedSortDirection === "asc" ? "asc" : "desc";

  return {
    searchTerm: urlObj.searchParams.get("search_term") || "",
    sortBy: {
      column,
      direction
    }
  };
}
