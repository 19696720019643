import React from "react";
import FilterIcon from "react-feather/dist/icons/filter";
import CheckboxList from "@components/CheckboxList";
import ToolbarDropdown from "./ToolbarDropdown";

const ToolbarFilter = ({
  data_url,
  manual_send_id,
  start_date,
  end_date,
  grouped_states,
  api_modes,
  environments,
  states = []
}) => {
  const checkedStates = Object.keys(grouped_states).filter((k) => states.includes(k));
  const checkedEnvironments = Object.keys(api_modes).filter((k) => environments.includes(k));
  const token = document.querySelector("[name=csrf-token]").content;

  return (
    <ToolbarDropdown button_text="Filter" icon={<FilterIcon size="16" className="mr-2" />}>
      <form action={data_url} acceptCharset="UTF-8" method="get">
        <input type="hidden" name="authenticity_token" value={token} />
        {!!manual_send_id && (
          <input type="hidden" name="states[manual_send_id]" value={manual_send_id} />
        )}
        <input type="hidden" name="states[start_date]" value={start_date} />
        <input type="hidden" name="states[end_date]" value={end_date} />
        <CheckboxList
          checkboxGroups={[
            {
              checkboxes: grouped_states,
              heading: "Mailing States",
              namespace: "states"
            },
            {
              checkboxes: api_modes,
              heading: "Environment",
              namespace: "environments"
            }
          ]}
          initialChecked={[...checkedStates, ...checkedEnvironments]}
          showSelectAll={true}
        />
      </form>
    </ToolbarDropdown>
  );
};

export default ToolbarFilter;
