// @flow

import type { UploaderType } from "@components/Uploader";
import type { BatchErrors } from "@utilities/UploadHelpers";
import fetch from "../Fetch";

type CreateUploadProps = {
  destinationId: string,
  type: UploaderType,
  onComplete: Function
};

type SendBatchProps = {
  id: string,
  url: string,
  records: Array<Object>,
  onComplete: Function
};

type FetchUploadProps = {
  url: string,
  onComplete: Function,
  options?: Object
};

type FetchRecordsProps = {
  url: string,
  page?: number,
  per_page?: number,
  onComplete: Function
};

export type FetchRecordsResponse = {
  headers: FetchRecordsResponseHeaders,
  body: Array<Object>
};

type FetchRecordsResponseHeaders = {
  "cache-control": string,
  "content-type": string,
  etag: string,
  "transfer-encoding": string,
  "x-frame-options": string,
  "x-next-page": string,
  "x-offset": string,
  "x-page": string,
  "x-per-page": string,
  "x-prev-page": string,
  "x-request-id": string,
  "x-runtime": string,
  "x-total": string,
  "x-total-pages": string
};

type UploadState =
  | "drafting"
  | "cancelled"
  | "ready_to_process"
  | "processing"
  | "processed"
  | "failed";

export type UploadResponse = {
  id: string,
  batch_url: string,
  cancel_url: string,
  state: UploadState,
  expires_at: string,
  finalize_url: string,
  edit_url: string,
  metadata: UploadResponseMetadata,
  record_url: string,
  records_url: string,
  status_url: string,
  url: string,
  upload_type: UploadType
};

export type BatchResponse = {
  batch_id: string,
  status: string,
  records: Array<BatchErrors>
};

type UploadType =
  | "manual_send_address"
  | "manual_send_email"
  | "manual_send_list"
  | "manual_send_house_file"
  | "audience"
  | "transactions";

type UploadResponseMetadata = {
  addresses: Addresses,
  batch_count: number,
  batches: Array<Object>,
  batches_ready: boolean,
  invalid_count: number,
  record_count: number,
  valid_count: number
};

export type Addresses = {
  [address: string]: AddressStrictnessHash
};

export type AddressStrictnessHash = {
  [level: string]: number
};

const UPLOAD_ENDPOINT_URL: string = "/v1/upload";

export const cancelUpload = async ({
  url,
  onComplete
}: FetchUploadProps): Promise<UploadResponse | any> => {
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  onComplete(await response.json());
};

export const createUpload = async ({
  destinationId,
  type,
  onComplete
}: CreateUploadProps): Promise<UploadResponse | any> => {
  const body = {
    destination_id: destinationId,
    type: type
  };

  const response = await fetch(UPLOAD_ENDPOINT_URL, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  });

  onComplete(await response.json());
};

export const fetchUpload = async ({
  url,
  onComplete
}: FetchUploadProps): Promise<UploadResponse | any> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });
  const responseJson = await response.json();
  onComplete(responseJson);
};

export const fetchBatch = async ({
  url,
  onComplete
}: FetchUploadProps): Promise<BatchResponse | any> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });
  const responseJson = await response.json();
  onComplete(responseJson);
};

export const fetchRecords = async ({
  url,
  onComplete,
  page
}: FetchRecordsProps): Promise<FetchRecordsResponse | any> => {
  if (page) {
    url += `?page=${page}`;
  }
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  onComplete({
    headers: await response.headers.map,
    body: await response.json()
  });
};

export const processUpload = async ({
  url,
  onComplete,
  options
}: FetchUploadProps): Promise<UploadResponse | any> => {
  const body = {
    options: options
  };

  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });

  onComplete(await response.json());
};

export const sendBatch = async ({
  id,
  url,
  records,
  onComplete
}: SendBatchProps): Promise<UploadResponse | any> => {
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      id: id,
      records: records
    }),
    headers: {
      "Content-Type": "application/json"
    }
  });

  onComplete(await response.json());
};
