// @flow

import React from "react";
import type { Node } from "react";

import ProIcon from "images/icons/plan/pro.svg";
import LiteIcon from "images/icons/plan/lite.svg";

import useCsrfToken from "@utilities/useCsrfToken";
import PlanPaymentCadence from "./PlanPaymentCadence";

import type { Plan, Tier } from "./types";

type Props = {
  formAction: string,
  formMethod: "put" | "post",
  plan: Plan,
  startDate: string, // MM/DD/YYYY
  endDate: string // MM/DD/YYYY
};

export default function ConfirmPlan({
  formAction,
  plan,
  startDate,
  endDate,
  formMethod
}: Props): Node {
  const token = useCsrfToken();

  function onSubmit(e) {
    if (!e.target.reportValidity()) {
      e.preventDefault();
    }
  }

  return (
    <div className="flex flex-col items-center">
      <PlanIcon tier={plan.tier} />
      <h1 className="mb-1 text-2xl font-bold">Confirm Your Plan</h1>
      <PlanDateRange startDate={startDate} endDate={endDate} />
      <form
        className="flex flex-col items-center edit_organization"
        id="submit_target"
        data-turbo="false"
        acceptCharset="UTF-8"
        action={formAction}
        method="post" // will be overridden by method prop
        onSubmit={onSubmit}
      >
        <input type="hidden" name="_method" value={formMethod} autoComplete="off" />
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="plan_tier" value={plan.tier} />
        <PlanPaymentCadence
          name="subscription[payment_cadence]"
          screenReaderLegend="Choose subscription payment cadence"
          selectionOptions={[
            { value: "annual", label: "Pay Annually" },
            { value: "monthly", label: "Pay Monthly" }
          ]}
          plan={plan}
        />
        <div className="flex items-end w-full py-4 border-t border-b border-gray-200 justify-evenly">
          <span>
            Postcards Starting At:
            <strong className="ml-4 text-xl">{plan.min_rate}</strong> ea.
          </span>
          <a href="https://heypoplar.com/pricing" target="_blank" rel="noreferrer">
            See All Pricing
          </a>
        </div>
        <TermsOfUseAgreement />
        <button type="submit" className="px-12 slm-button">
          Subscribe
        </button>
      </form>
    </div>
  );
}

function TermsOfUseAgreement(): Node {
  return (
    <label className="items-start my-4">
      <input type="checkbox" className="mt-1 mr-4" required />
      <p className="text-xs text-gray-500">
        I agree to automatic billing at the frequency shown above. I authorize Share Local Media to
        charge my payment method according to the terms outlined above and in the{" "}
        <a href="https://heypoplar.com/legal/terms-of-use" target="_blank" rel="noreferrer">
          Poplar Terms of Use
        </a>
        , and I certify that I approve such charges and that I have all necessary rights and
        authorizations to do so.
      </p>
    </label>
  );
}

type PlanDateRangeProps = {
  startDate: string, // MM/DD/YYYY
  endDate: string // MM/DD/YYYY
};

function PlanDateRange({ startDate, endDate }: PlanDateRangeProps): Node {
  return (
    <div className="pb-6 text-sm divide-x divide-gray-200">
      <span className="px-3">
        Start Date:
        <span className="ml-1 font-bold">{startDate}</span>
      </span>
      <span className="px-3">
        Auto-Renewal Date:
        <span className="ml-1 font-bold">{endDate}</span>
      </span>
    </div>
  );
}

function PlanIcon({ tier }: { tier: Tier }) {
  const icon = tier === "lite" ? LiteIcon : ProIcon;

  return (
    <div className="flex items-baseline mb-4 text-xl font-bold">
      <img className="mr-2" alt="Warning" src={icon} />
      <strong className="capitalize">{tier}</strong>
    </div>
  );
}
