// @flow
import React, { useState } from "react";
import _ from "lodash";
import RailsFormData from "@utilities/FormatRailsFormData";
import classNames from "classnames";

type RecipientFormProps = {
  disabledTypes: { [RecipientSource]: boolean },
  hiddenTypes: { [RecipientSource]: boolean },
  initialRecipientSource: RecipientSource,
  recipientSources: Array<Source & { category: "new" | "existing" }>
};

export default function RecipientForm({
  disabledTypes,
  hiddenTypes,
  initialRecipientSource,
  recipientSources
}: RecipientFormProps): React$Element<'div'>{
  const [recipientSource, setRecipientSource] = useState<RecipientSource>(
    initialRecipientSource || recipientSources[0].id
  );
  const categories: Categories = _.groupBy(recipientSources, "category");

  function _openBeacon(){
    const appends_msg_id = "15d65e0c-92ec-479c-8dd5-bec76552c0f5";
    window.Beacon("show-message", appends_msg_id, { force: true, delay: 1 });
  }

  return (
    <div>
      <RailsFormData path="manual_send[recipient_source]" data={recipientSource} />
      <h2 className="content-subheader">{CONTAINER_SUBHEADER}</h2>
      <h3 className="wall__header">{EXISTING_DATA_HEADER}</h3>
      <SourceSelector
        disabledTypes={disabledTypes}
        hiddenTypes={hiddenTypes}
        sources={categories.existing}
        selected={recipientSource}
        openBeacon={_openBeacon}
        onChange={(id) => setRecipientSource(id)}
      />
      <h3 className="wall__header">{NEW_DATA_HEADER}</h3>
      <SourceSelector
        disabledTypes={disabledTypes}
        hiddenTypes={hiddenTypes}
        sources={categories.new}
        selected={recipientSource}
        openBeacon={_openBeacon}
        onChange={(id) => setRecipientSource(id)}
      />
    </div>
  );
}

type SourceCardProps = {
  source: Source,
  openBeacon: () => void,
  onClick: () => void,
  isSelected: boolean,
  isDisabled: boolean,
};

function SourceCard({
  source: { id, name },
  openBeacon,
  onClick,
  isSelected,
  isDisabled
}: SourceCardProps){
  function handleSourceSelect(){
    if (isDisabled) return;

    onClick();
  }

  const wallTileClasses = classNames({
    "wall__tile small-tile": true,
    "selected": isSelected,
    "disabled": isDisabled,
    "wider-tile": id === "email_file"
  });
  const wallIconClasses = ["wall__icon", isDisabled ? `${id}_disabled` : id].join(" ");

  const title = isDisabled && id === "existing_audience" ?  "You have no mailable audiences." : undefined;

  return (
    <div className="wall__option">
      <div
        role="link"
        tabIndex={0}
        onClick={handleSourceSelect}
        onKeyPress={handleSourceSelect}
        className={wallTileClasses}
        title={title}
      >
        <div className={wallIconClasses} />
        <span dangerouslySetInnerHTML={{ __html: name }} />
        {isDisabled && id === "email_file" && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a role="none" onClick={openBeacon} onKeyPress={openBeacon}>
            Chat with us to turn on this feature.
          </a>
        )}
      </div>
    </div>
  );
}

type SourceSelectorProps = {
  disabledTypes: { [RecipientSource]: boolean },
  hiddenTypes: { [RecipientSource]: boolean },
  sources: Array<Source>,
  selected: RecipientSource,
  openBeacon: () => void,
  onChange: (id: RecipientSource) => void
};

function SourceSelector({
  disabledTypes,
  hiddenTypes,
  sources,
  selected,
  openBeacon,
  onChange
}: SourceSelectorProps){
  return (
    <div>
      <div className="wall__options">
        {sources.map(source => {
          return (
            !hiddenTypes[source.id] && (
              <SourceCard
                key={source.id}
                source={source}
                onClick={() => onChange(source.id)}
                openBeacon={openBeacon}
                isSelected={source.id === selected}
                isDisabled={disabledTypes[source.id]}
              />
            )
          );
        })}
      </div>
    </div>
  );
}

const CONTAINER_SUBHEADER = "How will you be providing recipient data?";
const EXISTING_DATA_HEADER = "I Have Data";
const NEW_DATA_HEADER = "I Need Data";

type RecipientSource =
  | "email_file"
  | "seed_file"
  | "single_address"
  | "address_file"
  | "existing_audience"
  | "house_file";

type Source = {
  id: RecipientSource,
  name: string
};

type Categories = {
  new: Array<Source>,
  existing: Array<Source>
};
