import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { removeInvalidChars } from "./FormatString";

// Parse uploaded CSV files. Uses the FileReader API to read the file once initially so that we can
// detect the encoding. Then parses the file with Papa Parse, setting the encoding in the config.
// the `reader.onload` event handler will execute once the file content is available from calling
// `reader.readAsBinaryString(file)`.

export const parseCsv = (file, progressCallback, completeCallback) => {
  const csvRows = [];
  const reader = new FileReader();

  reader.onload = (e) => {
    const csvResult = e.target.result.split(/\r|\n|\r\n/);

    // Dynamically import jschardet for file encoding detection.
    import("jschardet").then( (jschardet) => {
      const {encoding} = jschardet.detect(csvResult.toString());

      Papa.parse(file, {
        header: true,
        worker: true,
        chunkSize: 5000,
        encoding: encoding,
        chunk(chunk){
          // eslint-disable-next-line no-param-reassign
          chunk.data.forEach(row => {
            Object.keys(row).forEach(key => {
              if (typeof row[key] === "string") {
                row[key] = removeInvalidChars(row[key]);
              }
            });

            row._uuid = uuidv4();
          });

          csvRows.push(...chunk.data);
          progressCallback((chunk.meta.cursor / file.size) * 100);
        },
        complete(){
          completeCallback(csvRows, file);
        },
        skipEmptyLines: "greedy"
      });
    });
  };

  reader.readAsBinaryString(file);
};
