// @flow
import React, { useEffect, useRef, useState, Fragment } from "react";
import type { Node } from "react";
import IntersectionVisible from "react-intersection-visible";
import classNames from "classnames";
import TextInput from "@elements/TextInput";
import SearchIcon from "react-feather/dist/icons/search";
import Toolbar from "@components/Toolbar";
import fetchWithCsrfToken from "@utilities/PoplarRequest";
import LoadingSpinner from "@elements/LoadingSpinner";
import ChevronDownIcon from "react-feather/dist/icons/chevron-down";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import initialState from "./InitialState";
import buildSearchQueryForApi from "./BuildSearchQueryForApi";

import type {
  Campaign,
  CampaignsTableProps,
  CampaignsTotals,
  LoadMoreButtonProps,
  SortBy
} from "./types";

export default function CampaignsTable({
  columnHeaders,
  selectedColumns,
  sortableColumns,
  toolbarProps
}: CampaignsTableProps): Node {
  const tableRef = useRef();
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);
  const [campaignsTotals, setCampaignsTotals] = useState<CampaignsTotals>({
    mailed: "",
    spend: ""
  });
  const [sortBy, setSortBy] = useState<SortBy>(initialSortBy);
  const [page, setPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  function initialSearchTerm(): string {
    return initialState(window.location.href, sortableColumns, selectedColumns).searchTerm;
  }

  function initialSortBy(): SortBy {
    return initialState(window.location.href, sortableColumns, selectedColumns).sortBy;
  }

  useEffect(() => {
    const searchQuery = buildSearchQueryForApi({
      start_date: toolbarProps.data.start_date,
      end_date: toolbarProps.data.end_date,
      page,
      searchTerm,
      sortBy
    });

    pushStateToUrl(searchQuery);

    const abortRequestFunction = fetchCampaigns(searchQuery, page);

    return abortRequestFunction;
  }, [searchTerm, sortBy, page, toolbarProps.data.start_date, toolbarProps.data.end_date]);

  function fetchCampaigns(searchQuery, pageToFetch) {
    setLoading(true);
    setError(false);

    searchQuery.append("page", pageToFetch.toString());

    const abortRequestController = new AbortController();
    fetchWithCsrfToken(`/attribution_lift.json?${searchQuery.toString()}`, {
      method: "Get",
      signal: abortRequestController.signal
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        setPage(jsonResponse.page);
        setCampaigns((previousCampaigns) => [...previousCampaigns, ...jsonResponse.data]);
        setHasNextPage(jsonResponse.page < jsonResponse.total_pages);
        setLoading(false);
        setCampaignsTotals({
          mailed: jsonResponse.total_mailed,
          spend: jsonResponse.total_spend
        });
      })
      .catch(({ name }) => {
        if (name === "AbortError") return; // newer data has already returned

        setLoading(false);
        setError(true);
      });

    return () => abortRequestController.abort();
  }

  /**
   * Set column widths to prevent collapsing when reloading campaigns
   */
  function setColumnWidths() {
    const { current } = tableRef;
    if (campaigns.length && current) {
      current.querySelectorAll("thead th").forEach((th) => {
        th.style.minWidth = `${Math.min(400, th.offsetWidth)}px`;
      });
    }
  }
  useEffect(setColumnWidths, [campaigns]);

  function toggleSortDirectionForColumn(column: string) {
    if (!sortableColumns.includes(column)) return;
    let direction;

    setPage(1);
    setCampaigns([]);

    const { column: currentColumn = "last_updated", direction: currentDirection = "desc" } = sortBy;

    if (column === currentColumn) {
      // if we are already sorting by this column swap the sort order
      direction = currentDirection === "asc" ? "desc" : "asc";
    } else {
      // default to asc for the name_lower field and desc for all numeric fields on first sort
      direction = column === "name_lower" ? "asc" : "desc";
    }

    setSortBy({ direction, column });
  }

  function onSearchInputChange(e) {
    setPage(1);
    setCampaigns([]);
    setSortBy({ column: "_score", direction: "asc" }); // sort by most relevant search results first

    setSearchTerm(e.target.value);
  }

  function urlQuery() {
    const searchTermParam = searchTerm ? `&search_term=${searchTerm}` : "";
    const sortByParam =
      sortBy.column && sortBy.direction
        ? `&sort_column=${sortBy.column}&sort_direction=${sortBy.direction}`
        : "";

    return sortByParam + searchTermParam;
  }

  const emptySearchResults = !loading && campaigns?.length === 0 && searchTerm?.length > 0;
  const { start_date: startDate, end_date: endDate } = toolbarProps.data || {};

  return (
    <Fragment>
      <div className="grid-x grid-margin-x">
        <div className="mt-2 toolbar-grid">
          <div>
            <TextInput
              className="mb-0 search-input"
              label="Search"
              placeholder="Search..."
              icon={<SearchIcon size="18" />}
              type="search"
              value={searchTerm}
              onChange={onSearchInputChange}
            />
          </div>
          <Toolbar
            {...toolbarProps}
            urlQuery={urlQuery()}
            searchTerm={searchTerm}
            campaignsTotals={campaignsTotals}
          />
        </div>
      </div>
      <div className={classNames("tableholder", { loading })}>
        <table
          ref={tableRef}
          className={classNames("slm-table clickable-rows campaigns-table", { loading })}
        >
          <TableHead
            {...{
              columnHeaders,
              selectedColumns,
              sortableColumns,
              sortBy,
              toggleSortDirectionForColumn
            }}
          />
          <TableBody
            campaigns={campaigns}
            selectedColumns={selectedColumns}
            startDate={startDate}
            endDate={endDate}
          />
        </table>
      </div>
      {error && <div className="pt-6 text-center text-red">Oops, an error occurred. Try again</div>}
      {emptySearchResults && !error && (
        <div className="pt-6 text-center">
          No campaigns matching search term <i>&ldquo;{searchTerm}&rdquo;</i>
        </div>
      )}
      <LoadMoreButton
        loading={loading}
        hasNextPage={hasNextPage}
        onClick={() => setPage((previousPage) => previousPage + 1)}
      />
    </Fragment>
  );
}

function LoadMoreButton({ hasNextPage, loading, onClick }: LoadMoreButtonProps) {
  if (!hasNextPage && !loading) return <Fragment />;

  return (
    <div className="-mt-6">
      <IntersectionVisible
        onIntersect={(_e) => {
          if (!loading) onClick();
        }}
      >
        <button
          type="button"
          disabled={loading}
          className="flex items-center justify-center w-full p-2 py-3 -mt-4 text-gray-500 bg-gray-100 hover:bg-gray-200"
          onClick={onClick}
          label={loading ? "Loading..." : "Load more"}
        >
          {loading ? (
            <React.Fragment>
              <LoadingSpinner /> Loading
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ChevronDownIcon size="16" className="mr-1" />
              Load More
            </React.Fragment>
          )}
        </button>
      </IntersectionVisible>
    </div>
  );
}

function pushStateToUrl(searchQuery) {
  // don't change url until the user has sorted or searched for something
  if (searchQuery.get("sort_column") || searchQuery.get("search_term")) {
    window.history.replaceState({}, "", `/campaigns?${searchQuery.toString()}`);
  }
}
