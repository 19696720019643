//@flow

import React, { useState } from "react";
import Uploader from "@components/Uploader";
import type { UploaderProps } from "@components/Uploader/types";
import SelectTag from "@elements/SelectTag";

const REQUEST_TYPE_OPTIONS = [
  { id: "access", name: "Access" },
  { id: "erasure", name: "Deletion" }
];
const REGULATION_OPTIONS = [{ id: "cpra", name: "CPRA" }];

type SubjectRequestType = "erasure" | "access";

type Regulation = "cpra";

type OptionCategory = "subject_request_type" | "regulation";

type Options = {
  [OptionCategory]: {
    default: SubjectRequestType | Regulation,
    type: "hidden"
  }
};

function DataSubjectRequestUploader({
  config,
  ...props
}: UploaderProps): React$Element<"fieldset" | typeof Uploader> {
  const { options: configOptions } = config;
  const [uploaderDisplay, setUploaderDisplay] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>(configOptions);

  /**
   * TODO: Create a method for passing option values without overriding the config defaults
   * - add an optionsValues prop to Uploader and use it to replace this and the `use props` workaround for address_strictness
   */
  const setOptionValue = (option: OptionCategory, value: SubjectRequestType | Regulation) => {
    const newOptions = { ...options };
    newOptions[option].default = value;
    setOptions(newOptions);
  };

  return uploaderDisplay ? (
    <Uploader {...props} config={{ ...config, options: options }} />
  ) : (
    <fieldset className="max-w-4xl">
      <legend>Request Details</legend>
      <div className="input-container">
        <SelectTag
          data={REQUEST_TYPE_OPTIONS}
          label="Request Type"
          value={options.subject_request_type.default}
          className="w-full"
          onChange={(e) => setOptionValue("subject_request_type", e.target.value)}
          required
        />
      </div>
      <div className="input-container">
        <SelectTag
          data={REGULATION_OPTIONS}
          label="Regulation"
          value={options.regulation.default}
          className="w-full"
          onChange={(e) => setOptionValue("regulation", e.target.value)}
          readOnly
        />
      </div>
      <button type="button" className="mt-4 slm-button" onClick={() => setUploaderDisplay(true)}>
        Continue
      </button>
    </fieldset>
  );
}

export default DataSubjectRequestUploader;
