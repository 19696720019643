// @flow

import _ from "lodash";
import type { Addresses, AddressStrictnessHash } from "./api/Upload";
import type { Record, UploaderConfigField } from "@components/Uploader/types";
import DeepCopyArrayOfObjects from "@utilities/DeepCopyArrayOfObjects";

export type BatchErrors = Array<UuidErrors>;

export type UuidErrors = {
  uuid: string,
  errors: ValidationErrors,
  addresses: AddressesObject
};

export type ValidationErrors = {
  [field: string]: Array<string>
};

export type AddressesObject = {
  [address: string]: AddressErrors
};

type AddressErrors = {
  normal: true | string,
  relaxed: true | string,
  strict: true | string
};

export type RecordHash = {
  [uuid: string]: RecordErrors
};

export type RecordErrors = {
  errors: string,
  [smartystreets: Array<string>]: string | boolean
};

export const updateRecordsHash = (batchErrors: BatchErrors, recordHash: RecordHash): RecordHash => {
  const result = recordHash;
  batchErrors.forEach((record) => {
    const { uuid }: { uuid: string } = record;

    result[uuid] = {};
    result[uuid]["[errors]"] = generateErrorMessage(record.errors);

    _.forEach(record.addresses, (validationInfo, addressName) => {
      _.forEach(validationInfo, (stats, label) => {
        result[uuid][[`[${addressName}__${label}-validation]`]] = stats;
      });
    });
  });

  return result;
};

export const generateErrorMessage = (errors: ValidationErrors): string =>
  _.keys(errors)
    .map((field: string): string => {
      return _.map(errors[field], (message) => `${field} ${message}`).join(", ");
    })
    .join(", ");

export type FieldsObject = {
  [field: string]: UploaderConfigField
};

export type MappingHash = {
  [originalHeader: string]: string
};

export const generateInitialMappingHash = (
  fields: FieldsObject,
  mergeTags: ?Array<string>,
  csvRows: Array<Record>
): MappingHash => {
  const ignoreMergeTagColumnHeader = (columnHeader) => columnHeader !== "merge_tags";
  const columnHeaders = Object.keys(csvRows[0]).filter(ignoreMergeTagColumnHeader);
  const newMappingHash = mapHeadersToFields(columnHeaders, fields, mergeTags);
  return newMappingHash;
};

export const mapHeadersToFields = (
  headers: Array<string>,
  fields: FieldsObject,
  mergeTags: ?Array<string>
): MappingHash => {
  const newMappingHash: MappingHash = {};
  headers.forEach((originalHeader): void => {
    const standardizedOriginalHeader = originalHeader.toLowerCase().replace(/[^0-9a-z]/gi, "");
    _.keys(fields).forEach((field: string) => {
      if (
        standardizedOriginalHeader === field.toLowerCase().replace(/[^0-9a-z]/gi, "") ||
        fields[field].synonyms?.some(
          (synonym) =>
            synonym.toLowerCase().replace(/[^0-9a-z]/gi, "") === standardizedOriginalHeader
        )
      ) {
        if (!_.values(newMappingHash).includes(field)) {
          newMappingHash[originalHeader] = field;
        }
      }
    });
    if (mergeTags && fields.merge_tags?.accepts_merge_tags) {
      mergeTags.forEach((mergeTag) => {
        if (
          !newMappingHash[originalHeader] &&
          standardizedOriginalHeader === mergeTag.toLowerCase().replace(/[^0-9a-z]/gi, "")
        ) {
          if (!_.values(newMappingHash).includes(mergeTag)) {
            newMappingHash[originalHeader] = mergeTag;
          }
        }
      });
    }
    if (!newMappingHash[originalHeader] && originalHeader !== "_uuid") {
      newMappingHash[originalHeader] = " ";
    }
  });
  return newMappingHash;
};

export const mapCsvData = (
  data: Array<Record>,
  mappingHash: MappingHash,
  fields: FieldsObject
): Array<Record> => {
  const newData: Array<Record> = [];
  data.forEach((obj) => {
    let newObj: Record = {};
    newObj = _.transform(obj, (result: Record, val: string, key: string) => {
      if (mappingHash[key] && mappingHash[key].trim()) {
        if (!_.keys(fields).includes(mappingHash[key]) && fields.merge_tags?.accepts_merge_tags) {
          // eslint-disable-next-line no-param-reassign
          result.merge_tags = result.merge_tags || {};
          // eslint-disable-next-line no-param-reassign
          result.merge_tags[mappingHash[key]] = val;
        } else if (fields[mappingHash[key]].allow_multiple_values) {
          // eslint-disable-next-line no-param-reassign
          result[mappingHash[key]] = result[mappingHash[key]] || {};
          // eslint-disable-next-line no-param-reassign
          result[mappingHash[key]][key] = val;
        } else {
          // eslint-disable-next-line no-param-reassign
          result[mappingHash[key]] = val;
        }
      } else if (key === "_uuid") {
        // eslint-disable-next-line no-param-reassign
        result[key] = val;
      }
    });
    newData.push(newObj);
  });

  return newData;
};

export const buildErrorDownload = (
  mappedData: Array<Record>,
  errorHash: RecordHash
): Array<FlattendMappedDataObject> => {
  const newData: Array<FlattendMappedDataObject> = flattenArrayOfObjects(
    DeepCopyArrayOfObjects(mappedData)
  );

  newData.forEach((record) => {
    Object.assign(record, errorHash[record._uuid]);
  });

  return newData;
};

export type OriginalMappedDataObject = {
  [field: string]: string | MappedDataObjectValue
};

type MappedDataObjectValue = {
  [field: string]: string
};

export type FlattendMappedDataObject = {
  [field: string]: string
};

export const flattenArrayOfObjects = (
  mappedData: Array<OriginalMappedDataObject>
): Array<Object> => {
  const flattenedMappedData: Array<FlattendMappedDataObject> = mappedData.map(
    (record: OriginalMappedDataObject) => {
      _.keys(record).forEach((key: string) => {
        if (_.isObject(record[key])) {
          const obj: MappedDataObjectValue = record[key];
          delete record[key];
          Object.assign(record, obj);
        }
      });
      return record;
    }
  );
  return flattenedMappedData;
};

export const getMailableCount = (
  addresses: Addresses,
  addressStrictness: ?string
): null | number => {
  if (!addressStrictness) {
    return null;
  }

  let mailableRecords: number = 0;
  _.values(addresses).forEach((addressHash: AddressStrictnessHash) => {
    if (addressHash[`${addressStrictness}_strictness`]) {
      mailableRecords += addressHash[`${addressStrictness}_strictness`];
    }
  });
  return mailableRecords;
};

// If a `selected` value is present, return that. Otherwise, if no available mappings exist in items,
// return the value that maps to `ignore this field`(""). Otherwise, return the value that maps to
// `choose an option` (" ").
export const selectWithDefaults = (items: array, selected: string) => {
  if (!_.isEmpty(selected?.trim())) return selected;
  if (items.every((item) => _.isEmpty(item.value?.trim()))) return "";

  return " ";
};
