// @flow
import React from "react";
import type { Node } from "react";
import FeatherIcon from "@elements/FeatherIcon";
import Tooltip from "@components/Tooltip";
import classNames from "classnames";
import useUniqueId from "@utilities/useUniqueId";

type Props = {
  className?: string,
  iconName: "trash2" | "edit" | "repeat",
  rel?: string,
  href?: string,
  toolTipText?: string,
  "data-method"?: "delete" | string,
  "data-confirm"?: string
};

type ButtonProps = {
  labelledby?: string
};

export default function IconButton({ toolTipText, iconName, className, ...rest }: Props): Node {
  const { href } = rest;
  const tipId = useUniqueId("tip");
  const Tag = href ? "a" : "button";

  function Button({ labelledby }: ButtonProps): Node {
    return (
      <Tag {...rest} aria-labelledby={labelledby} className={classNames("inline-block", className)}>
        <FeatherIcon
          iconName={iconName}
          className="mt-1"
          color="black"
          strokeWidth="1.5"
          size={15}
        />
      </Tag>
    );
  }

  return toolTipText ? (
    <Tooltip value={toolTipText} id={tipId} dark>
      <Button labelledby={tipId} />
    </Tooltip>
  ) : (
    <Button />
  );
}
