// @flow

import React, { useState, useMemo, Fragment } from "react";
import classNames from "classnames";
import RailsFormData from "@utilities/FormatRailsFormData";
import TextInput from "@elements/TextInput";
import FormErrors from "@elements/FormErrors";
import CreativeEstimate from "@components/CreativeEstimate";
import type { Node } from "react";
import CreativeOptions from "./CreativeOptions";

import type {
  Creative,
  CreativeOption,
  CreativeType,
  HandleOptionChange,
  SelectedCreativeOptions
} from "./types";

type CreativeSelectionFormProps = {
  creative: Creative,
  estimateEndpoint: string,
  errors: Array<string>,
  creativeTypes: Array<CreativeType>,
  offsetEnabled: boolean,
  mailTypeDefaultImage: string
};

export default function CreativeSelectionForm({
  creative,
  errors,
  estimateEndpoint,
  creativeTypes,
  offsetEnabled,
  mailTypeDefaultImage
}: CreativeSelectionFormProps): Node {
  const initState = useMemo(
    () => initialSelections(creative, creativeTypes),
    [creative, creativeTypes]
  );

  const [creativeTypeId, setCreativeTypeId] = useState<string>(initState.creativeTypeId);
  const [templateName, setTemplateName] = useState<string>(creative.name);
  const [mailTypeId, setMailTypeId] = useState<string>(initState.mailTypeId);
  const [creativeTypeSizeId, setCreativeTypeSizeId] = useState<string>(
    initState.creativeTypeSizeId
  );
  const [paperTypeId, setPaperTypeId] = useState<string>(initState.paperTypeId);
  const [selectedOptions, setSelectedOptions] = useState<SelectedCreativeOptions>(
    initState.selectedOptions
  );

  const currentCreativeType: CreativeType = getCurrentCreativeType(creativeTypeId);

  function getCurrentCreativeType(currentCreativeTypeId: string): CreativeType {
    return creativeTypes.find(({ id }) => id === currentCreativeTypeId) || creativeTypes[0];
  }

  const handleCreativeOptionChange: HandleOptionChange = (value, option_name) => {
    const newOptions: SelectedCreativeOptions = { ...selectedOptions };
    newOptions[option_name] = value;

    // The `perforated_page` option is only available with `return_envelope`
    if (newOptions.return_envelope === "false") newOptions.perforated_page = "0";

    setSelectedOptions(newOptions);
  };

  function handleCreativeTypeChange(newCreativeTypeId: string) {
    setCreativeTypeId(newCreativeTypeId);

    const newCreativeType: CreativeType = creativeTypes.find(({ id }) => id === newCreativeTypeId);
    setSelectedOptions(formatOptions(newCreativeType.creative_options, {}));

    const availableCreativeTypeSizeIdsOnCreative = newCreativeType.creative_type_sizes.map(
      (creativeTypeSize) => creativeTypeSize.id
    );
    const availablePaperTypeIdsOnCreative = newCreativeType.eligible_paper_types.map(
      (paperType) => paperType.id
    );

    if (availableCreativeTypeSizeIdsOnCreative.includes(creativeTypeSizeId)) {
      setCreativeTypeSizeId(creativeTypeSizeId);
    } else if (availableCreativeTypeSizeIdsOnCreative.length > 0) {
      const newCreativeTypeSizeId =
        newCreativeType.creative_type_sizes.sort(sortByOrderThenName)[0].id;

      setCreativeTypeSizeId(newCreativeTypeSizeId);
    } else {
      // Send an empty string for creative_type_size_id param to controller;
      // needed to clear out associated creative_type_size to creative - if any.
      setCreativeTypeSizeId("");
    }

    setMailTypeId(""); // Unselect Mail Type to make sure user is forced to select one always.
    setPaperTypeId(""); // Unselect Paper Type to make sure user is forced to select one always.
  }

  function mailTypeOptions(mailTypes: array) {
    const options = {
      usps_standard: {
        description: "Typically delivers 5-15 business days after handoff to USPS.",
        imgCustomClass: "standard"
      },
      usps_first_class: {
        description: "Typically delivers 3-5 business days after handoff to USPS.",
        imgCustomClass: "first-class"
      }
    };

    return mailTypes
      .map(({ id, name, internal_name }) => {
        const option = options[internal_name];
        if (option) {
          return {
            id,
            name,
            ...option
          };
        } else {
          return {
            id,
            name,
            description: "",
            imgCustomClass: ""
          };
        }
        return null;
      })
      .filter(Boolean)
      .sort((a, b) => (a.name === "USPS Standard" ? -1 : 1)); // 'USPS Standard' should be before 'USPS First Class'
  }

  function handleTemplateNameChange(event: SyntheticInputEvent<HTMLInputElement>) {
    setTemplateName(event.target.value);
  }

  function handleCreativeTypeSizeChange(event: SyntheticInputEvent<HTMLInputElement>) {
    setCreativeTypeSizeId(event.target.value);
  }

  function handlePaperTypeChange(event: SyntheticInputEvent<HTMLInputElement>) {
    setPaperTypeId(event.target.value);
  }

  function handleMailTypeChange(event: SyntheticInputEvent<HTMLInputElement>) {
    setMailTypeId(event.target.value);
  }

  return (
    <div>
      <FormErrors errors={errors} />
      <RailsFormData
        path="creative"
        data={{
          name: templateName,
          creative_type_id: creativeTypeId,
          mail_type_id: mailTypeId,
          creative_type_size_id: creativeTypeSizeId,
          paper_type_id: paperTypeId,
          options: selectedOptions
        }}
      />
      <div className="grid-x">
        <div className="cell medium-8">
          <CreativeTypeSelector
            data={creativeTypes}
            onChange={handleCreativeTypeChange}
            value={creativeTypeId}
          />
          <div className="input-container">
            <TextInput
              label="Template Name"
              onChange={handleTemplateNameChange}
              value={templateName || ""}
              autoFocus
              required
              maxLength={255}
            />
          </div>
          {currentCreativeType.creative_type_sizes.length > 0 && (
            <CreativeTypeSizeSelector
              currentCreativeType={currentCreativeType}
              currentCreativeTypeSizeId={creativeTypeSizeId}
              handleCreativeTypeSizeChange={handleCreativeTypeSizeChange}
            />
          )}
          <MailTypeSelector
            mailTypeOptions={mailTypeOptions(currentCreativeType.mail_types)}
            currentMailTypeId={mailTypeId}
            mailTypeDefaultImage={mailTypeDefaultImage}
            handleMailTypeChange={handleMailTypeChange}
          />
          <PaperTypeSelector
            currentCreativeType={currentCreativeType}
            currentPaperTypeId={paperTypeId}
            handlePaperTypeChange={handlePaperTypeChange}
          />
          {currentCreativeType.creative_options.length > 0 && (
            <CreativeOptions
              options={currentCreativeType.creative_options}
              values={selectedOptions}
              onChange={handleCreativeOptionChange}
            />
          )}
        </div>
        <div className="cell medium-4">
          {mailTypeId && (
            <CreativeEstimate
              estimateEndpoint={estimateEndpoint}
              creativeId={creative.id}
              options={selectedOptions}
              mail_type_id={mailTypeId}
              creative_type_size_id={creativeTypeSizeId}
              paper_type_id={paperTypeId}
              creative_type_id={creativeTypeId}
              offsetEnabled={offsetEnabled}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function CreativeTypeTile({ id, iconName, selected, onChange }) {
  return (
    <div className="wall__option">
      <div
        className={classNames("wall__tile square-tile", { selected })}
        role="link"
        tabIndex={0}
        onKeyPress={() => onChange(id)}
        onClick={() => onChange(id)}
      >
        <div className={`wall__icon i-${iconName}`} />
      </div>
    </div>
  );
}

function CreativeTypeSelector({ data, onChange, value }) {
  return (
    <div className="wall__options">
      {data.sort(sortByOrderThenName).map((creative_type) => (
        <CreativeTypeTile
          {...creative_type}
          key={creative_type.id}
          selected={creative_type.id === value}
          onChange={onChange}
          iconName={creative_type.icon_slug}
        />
      ))}
    </div>
  );
}

type CreativeTypeSizeSelectorProps = {
  currentCreativeType: CreativeType,
  currentCreativeTypeSizeId: string,
  handleCreativeTypeSizeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void
};

type MailTypeSelectorProps = {
  currentCreativeType: CreativeType,
  currentMailTypeId: string,
  handleMailTypeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void
};

type PaperTypeSelectorProps = {
  currentCreativeType: CreativeType,
  currentPaperTypeId: string,
  handlePaperTypeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void
};

function CreativeTypeSizeSelector({
  currentCreativeType,
  currentCreativeTypeSizeId,
  handleCreativeTypeSizeChange
}: CreativeTypeSizeSelectorProps): Node {
  return (
    <fieldset>
      <legend className="pb-0.5 text-xs text-gray m-0">Size (before folding)</legend>
      <div className="wall__options">
        {currentCreativeType.creative_type_sizes
          .sort(sortByOrderThenName)
          .map(({ id, name, icon_slug }) => {
            return (
              <Fragment key={id}>
                <input
                  className="sr-only"
                  type="radio"
                  id={id}
                  onChange={handleCreativeTypeSizeChange}
                  checked={currentCreativeTypeSizeId == id}
                  value={id}
                />
                <label htmlFor={id} className="mx-0 wall__option">
                  <div className="wall__option" role="link" tabIndex={0}>
                    <div
                      className={classNames("wall__tile vertical-rectangle-tile", {
                        selected: currentCreativeTypeSizeId === id
                      })}
                    >
                      <div className={`wall__icon i-${icon_slug} mb-2`} />

                      <div className="m-auto">
                        <legend className="mb-0 text-xs text-gray">{name}</legend>
                      </div>
                    </div>
                  </div>
                </label>
              </Fragment>
            );
          })}
      </div>
    </fieldset>
  );
}

function MailTypeSelector({
  mailTypeOptions,
  currentMailTypeId,
  mailTypeDefaultImage,
  handleMailTypeChange
}: MailTypeSelectorProps): Node {
  return (
    <fieldset>
      <legend className="pb-0.5 text-xs text-gray m-0">Postage Type</legend>
      <div className="wall__options">
        {Object.keys(mailTypeOptions).map((mailTypeOption) => {
          const optionProperties = mailTypeOptions[mailTypeOption];
          return (
            <Fragment key={optionProperties.id}>
              <input
                className="sr-only"
                type="radio"
                id={optionProperties.id}
                onChange={handleMailTypeChange}
                checked={currentMailTypeId === optionProperties.id}
                value={optionProperties.id}
              />
              <label htmlFor={optionProperties.id} className="w-full mx-0 wall__option">
                <div
                  className={classNames("wall__tile mail-type-tile", {
                    selected: optionProperties.id === currentMailTypeId
                  })}
                >
                  <div className="wider-tile">
                    <div className="square-img">
                      <div className={`img-container ${optionProperties.imgCustomClass}`}>
                        <img
                          className="object-cover w-full h-full"
                          alt=""
                          src={mailTypeDefaultImage}
                          aria-hidden
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center px-4">
                    <div className="font-bold text-black-lighter">
                      <small className="text-gray">USPS</small>
                      {optionProperties.name.replace("USPS", "")}
                    </div>
                    <div className="text-black-lighter">{optionProperties.description}</div>
                  </div>
                </div>
              </label>
            </Fragment>
          );
        })}
      </div>
    </fieldset>
  );
}

function PaperTypeSelector({
  currentCreativeType,
  currentPaperTypeId,
  handlePaperTypeChange
}: PaperTypeSelectorProps): Node {
  return (
    currentCreativeType.eligible_paper_types.length > 0 && (
      <fieldset>
        <legend className="pb-0.5 text-xs text-gray m-0">Paper Type</legend>
        <div className="wall__options">
          {currentCreativeType.eligible_paper_types
            .sort(sortByOrderThenName)
            .map(({ id, name, description, preview_url }) => {
              return (
                <Fragment key={id}>
                  <input
                    className="sr-only"
                    type="radio"
                    id={id}
                    onChange={handlePaperTypeChange}
                    checked={currentPaperTypeId === id}
                    value={id}
                  />
                  <label htmlFor={id} className="w-full mx-0 wall__option">
                    <div
                      className={classNames("wall__tile paper-type-tile", {
                        selected: id === currentPaperTypeId
                      })}
                      role="link"
                    >
                      <div className="square-img">
                        <img
                          className="object-cover w-full h-full"
                          src={preview_url}
                          alt=""
                          aria-hidden
                        />
                      </div>
                      <div className="flex flex-col justify-center px-4">
                        <div className="font-bold text-black-lighter">{name}</div>
                        <div className="text-black-lighter">{description}</div>
                      </div>
                    </div>
                  </label>
                </Fragment>
              );
            })}
        </div>
      </fieldset>
    )
  );
}

function sortByOrderThenName(a, b) {
  return a.order - b.order || a.name.localeCompare(b.name);
}

export function formatOptions(
  creativeOptions: Array<CreativeOption>,
  previously_selected_options: SelectedCreativeOptions
): SelectedCreativeOptions {
  const object: SelectedCreativeOptions = {};
  creativeOptions.forEach(({ option_type, internal_name }) => {
    switch (option_type) {
      case "boolean":
        object[internal_name] = previously_selected_options[internal_name] || "false";
        break;
      case "integer":
        object[internal_name] = previously_selected_options[internal_name] || "0";
        break;
      default:
        object[internal_name] = "false";
        break;
    }
  });

  return object;
}

type InitialSelectionsReturn = {
  creativeTypeId: string,
  mailTypeId: string,
  creativeTypeSizeId: string,
  paperTypeId: string,
  selectedOptions: SelectedCreativeOptions
};

function initialSelections(
  savedCreative: Creative,
  creativeTypes: Array<CreativeType>
): InitialSelectionsReturn {
  const creativeTypeId = savedCreative.creative_type_id || creativeTypes[0].id;
  const initialCreativeType: CreativeType =
    creativeTypes.find(({ id }) => id === creativeTypeId) || creativeTypes[0];

  const selectedOptions = formatOptions(
    initialCreativeType.creative_options || [],
    savedCreative.formatted_selected_creative_options
  );

  return {
    creativeTypeId,
    mailTypeId: savedCreative.mail_type_id,
    creativeTypeSizeId:
      savedCreative.creative_type_size_id || initialCreativeType.creative_type_sizes[0]?.id,
    paperTypeId: savedCreative.paper_type_id,
    selectedOptions
  };
}
