import React from "react";
import SelectTag from "@elements/SelectTag";
import RailsFormData from "@utilities/FormatRailsFormData";
import TextInput from "@elements/TextInput";

class Details extends React.Component {
  constructor(props) {
    super(props);

    const { competitors, selectedCategory, selectedSubcategory, website } = this.props;

    this.state = {
      categoryId: selectedSubcategory || selectedCategory || null,
      competitors,
      selectedCategory,
      selectedSubcategory,
      subcategories: this._subcategoriesForCategory(selectedCategory),
      website: website || ""
    };

    this._categoryChanged = this._categoryChanged.bind(this);
    this._competitorsChanged = this._competitorsChanged.bind(this);
    this._subcategoriesForCategory = this._subcategoriesForCategory.bind(this);
    this._websiteChanged = this._websiteChanged.bind(this);
  }

  _categoryChanged(e, type) {
    const { selectedCategory } = this.state;
    const nextState = {};

    if (type === "category" && e.target.value !== "") {
      nextState.selectedSubcategory = null;
      nextState.categoryId = e.target.value;
      nextState.selectedCategory = e.target.value;
      nextState.subcategories = this._subcategoriesForCategory(e.target.value);
    } else if (type === "subcategory" && e.target.value !== "") {
      nextState.categoryId = e.target.value;
      nextState.selectedSubcategory = e.target.value;
    } else {
      nextState.selectedSubcategory = null;
      nextState.categoryId = selectedCategory;
    }

    this.setState(nextState);
  }

  _competitorsChanged(e) {
    this.setState({ competitors: e.target.value });
  }

  _subcategoriesForCategory(category) {
    const { subcategories } = this.props;

    if (category === null) {
      return [];
    } else {
      return subcategories.filter((subcategory) => subcategory.parent_id === category);
    }
  }

  _websiteChanged(e) {
    this.setState({ website: e.target.value });
  }

  render() {
    const {
      categoryId,
      competitors,
      selectedCategory,
      selectedSubcategory,
      subcategories,
      website
    } = this.state;
    const { categories } = this.props;
    const formData = { category_id: categoryId, competitors };

    return (
      <div className="grid-x manual-send-details">
        <RailsFormData path="manual_send[list_attributes]" data={formData} />
        <RailsFormData path="organization[website]" data={website} />
        <p>We take these details into consideration when we build your model.</p>
        <Competitors competitors={competitors} onChange={this._competitorsChanged} />
        <Category
          categories={categories}
          categoryChanged={this._categoryChanged}
          selectedCategory={selectedCategory}
          selectedSubcategory={selectedSubcategory}
          subcategories={subcategories}
          website={website}
          onWebsiteChange={this._websiteChanged}
        />
      </div>
    );
  }
}

const Competitors = ({ competitors, onChange }) => (
  <div className="cell medium-5 manual-send-details__competitors">
    <h5 className="manual-send-details__title">Competitors (Optional)</h5>
    <label>
      Add 5-10. Separate with commas.
      <textarea
        rows="5"
        value={competitors}
        onChange={onChange}
        placeholder="Competitor A, Competitor B"
        className="manual-send-details__competitors-text-area"
      />
    </label>
  </div>
);

const Category = ({
  categories,
  categoryChanged,
  selectedCategory,
  selectedSubcategory,
  subcategories,
  website,
  onWebsiteChange
}) => (
  <div className="cell medium-5 medium-offset-2 manual-send-details__category">
    <h5 className="manual-send-details__title">Details</h5>
    <div className="input-container">
      <SelectTag
        includeBlank
        data={categories}
        label="Category"
        value={selectedCategory}
        onChange={(e) => categoryChanged(e, "category")}
      />
    </div>
    <div className="input-container">
      <SelectTag
        includeBlank
        data={subcategories}
        label="Subcategory (Optional)"
        disabled={subcategories.length === 0}
        value={selectedSubcategory}
        onChange={(e) => categoryChanged(e, "subcategory")}
      />
    </div>
    <div className="input-container">
      <Website website={website} onChange={onWebsiteChange} />
    </div>
  </div>
);

const Website = ({ website, onChange }) => (
  <TextInput
    type="url"
    value={website}
    placeholder="https://example.com"
    label="Organization URL"
    onChange={onChange}
  />
);

export default Details;
