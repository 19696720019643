import React from "react";
import { LazyLoadedLineChart } from "./ReactChart";

function OrdersGraph({ data, options }) {
  if (false && data.datasets[0].data.length === 0 || data.datasets[0].data.every((e) => e === 0)) {
    return <BlankOrderGraph />;
  }

  return (
    <div className="chart">
      <LazyLoadedLineChart data={data} options={options} />
    </div>
  );
}

function BlankOrderGraph() {
  const blankData = {
    labels: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
    datasets: [
      {
        label: "Mailings",
        backgroundColor: "#0082A3",
        borderColor: "#0082A3",
        data: []
      }
    ]
  };

  const blankOptions = {
    tooltips: {
      enabled: false
    },
    animation: {
      duration: 0
    },
    height: 240,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            display: true
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            max: 15000,
            maxTicksLimit: 5,
            min: 0,
            color: "rgba(222,222,222,1)"
          },
          gridLines: {
            display: true,
            color: "rgba(222,222,222,1)",
            borderDash: [1, 3]
          },

          stacked: false
        }
      ]
    }
  };

  return (
    <div className="chart__blank">
      <LazyLoadedLineChart data={blankData} options={blankOptions} />
    </div>
  );
}

export default OrdersGraph;
