// @flow

// Very basic function to parse a numeric value and format as currency. This is limited to
// USD - if our requirements change then we should look at some of the libs out there.

export const formatCurrency = (value: number): string => (
  `$${parseFloat(value).toFixed(2)}`
);

export default formatCurrency;
