// @flow
import React from "react";
import type { Node } from "react";
import Database from "react-feather/dist/icons/database";
import buildMetadataTooltipContent from "@utilities/buildMetadataTooltipContent";
import Tooltip from "@components/Tooltip";

type Props = {
  metadata: { [string]: string }
};

export default function MetadataInformativeIcon({ metadata }: Props): Node {
  const tooltipContent = buildMetadataTooltipContent(metadata);

  function TooltipContent() {
    return tooltipContent.map((el) => <div key={el}>{el}</div>);
  }

  return (
    <Tooltip value={<TooltipContent />} dark>
      <Database className="mt-1" color="black" strokeWidth="1.5" size={15} />
    </Tooltip>
  );
}
