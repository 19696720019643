// @flow
import React from "react";
import classNames from "classnames";
import PlusIcon from "react-feather/dist/icons/plus";
import TrashIcon from "react-feather/dist/icons/trash-2";
import RateField from "./RateField";
import { useRateGroup } from "./context";
import { rateKey } from "./helpers";
import type {
  CreativeTypeField,
  OffsetTier,
  OffsetTierKey,
  OffsetTiers
} from "./types";

function OffsetRatesFields({ creativeType }: { creativeType: CreativeTypeField }): React$Node {
  const { setOffsetMinimum, getOffsetTiers } =
    useRateGroup();
  const offsetTiers: OffsetTiers = getOffsetTiers(creativeType.id);

  return (
    <div className="relative">
      <div className="overflow-x-auto tableholder">
        <table>
          <thead>
            <tr>
              <th className="sticky left-0 z-10 w-48 pl-0 font-bold bg-white h-14">
                <div className="flex justify-between w-48">
                  <span>Postage</span>
                  <span>Qty</span>
                </div>
              </th>
              {Object.entries(offsetTiers).map(
                ([tierKey, { destroy, minimum }]: [OffsetTierKey, OffsetTier | any]) => {
                  return (
                    <td
                      className={classNames("w-20 group", {
                        hidden: destroy
                      })}
                      key={`${tierKey}-min`}
                    >
                      <div className="relative w-20">
                        <input
                          type="text"
                          onChange={(e) =>
                            setOffsetMinimum(
                              e.target.value.replace(/\D/g, ""),
                              creativeType.id,
                              tierKey
                            )
                          }
                          id={tierKey}
                          placeholder="25,000"
                          value={minimum ? (+minimum).toLocaleString("en-US") : ""}
                        />
                        <DeleteTierButton tierKey={tierKey} creativeType={creativeType} />
                      </div>
                    </td>
                  );
                }
              )}
              <td aria-hidden />
            </tr>
          </thead>
          <tbody>
            {creativeType.mailTypes.map((mailType) => (
              <tr key={[creativeType.name, mailType.name, "offset"].join("-")} className="bg-white">
                <th scope="row" className="sticky left-0 pl-0 bg-inherit">
                  <div className="flex items-center justify-between w-48 h-8 font-normal">
                    <span>{mailType.name}</span>
                    <span className="text-gray-500 font-sm">Rate</span>
                  </div>
                </th>
                {Object.entries(offsetTiers).map(
                  ([key, { destroy, minimum }]: [OffsetTierKey, OffsetTier | any]) => {
                    const id = rateKey({
                      rateType: "offset",
                      creativeTypeId: creativeType.id,
                      mailTypeId: mailType.id,
                      offsetTier: key
                    });

                    return (
                      <td key={key} className={destroy ? "hidden" : ""}>
                        <RateField
                          fieldId={id}
                          creativeType={creativeType}
                          mailType={mailType}
                          rateType="offset"
                          offsetMinimum={minimum}
                          destroy={destroy}
                        />
                      </td>
                    );
                  }
                )}
                <td aria-hidden />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddTierButton creativeType={creativeType} />
    </div>
  );
}

function AddTierButton({ creativeType }: {creativeType: CreativeTypeField}) {
  const { addOffsetTier } = useRateGroup();

  return (
    <div className="add-tier">
      <button
        type="button"
        className="w-8 h-8 p-0 m-3 bg-white border text-jade-500"
        onClick={() => addOffsetTier(creativeType.id)}
      >
        <PlusIcon size={18} />
      </button>
    </div>
  );
}

function DeleteTierButton({
  tierKey,
  creativeType
}: {
  tierKey: string,
  creativeType: CreativeTypeField
}): React$Node {
  const { deleteOffsetTier } = useRateGroup();

  return (
    <div className="absolute inset-y-0 flex items-center invisible left-full group-hover:visible group-hover:animate-fade-in">
      <button
        type="button"
        className="w-6 h-6 p-0 text-gray-500 bg-white border-0"
        onClick={() => deleteOffsetTier(creativeType.id, tierKey)}
      >
        <TrashIcon size="16" />
      </button>
    </div>
  );
}

export default OffsetRatesFields;
