// @flow
import React from "react";
import type { Node } from "react";
import ClipboardButton from "@components/ClipboardButton";
import useUniqueId from "@utilities/useUniqueId";

const CodeSnippet = ({ json, value }: CodeSnippetProps): Node => {
  const id = useUniqueId("clipboard");
  const data = json ? JSON.stringify(value, null, 2) : value;

  return (
    <div className="relative">
      <pre className="slm-pre" id={id}>
        {data}
      </pre>
      <ClipboardButton value={data} className="absolute top-0 right-0 p-4" />
    </div>
  );
};

type CodeSnippetProps = {
  json: boolean,
  value: string
};

export default CodeSnippet;
