import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  #container = "#dropin-container";

  connect() {
    if (!this.hasFormTarget || !window.braintree_client_token) {
      return;
    }

    this.wizardControls = document.querySelector(".content__header-actions");
    this.errors         = document.querySelector(".flashes");

    this.requestPaymentMethod = this.requestPaymentMethod.bind(this);
    this.reEnableSubmit       = this.reEnableSubmit.bind(this);
    this.submitHandler        = this.submitHandler.bind(this);
    this.toggleForm           = this.toggleForm.bind(this);

    this.initPayments();
  }

  initPayments() {
    import("braintree-web-drop-in").then(({ default: dropin }) => {
      dropin.create(
        {
          authorization: window.braintree_client_token,
          container: this.#container,
          card: {
            cardholderName: true
          },
          translations: {
            payWithCard: "Secure"
          },
          vaultManager: true
        },
        (error, instance) => {
          if (error) {
            console.error(error);
          } else {
            window.braintree = instance;
            this.formTarget.addEventListener("submit", this.submitHandler);
            document.querySelector(this.#container).classList.add("loaded");
          }
        }
      );
    });
  }

  requestPaymentMethod(autosubmit = true) {
    window.braintree.requestPaymentMethod((err, payload) => {
      if (err) {
        console.error(err);
        this.toggleForm(true);
        window.setTimeout(this.reEnableSubmit, 10);
      } else {
        // Add Nonce to Form
        document.querySelector("#nonce").value = payload.nonce;

        if (this.errors) {
          this.errors.classList.add("hidden");
        }

        setTimeout(this.formTarget.submit(), 10);
      }
    });
  }

  // Re-enable for submit button if it has been disabled with data-disable-with
  reEnableSubmit() {
    if (
      this.formTarget.tagName === "FORM" &&
      this.formTarget.elements.button &&
      typeof window.rails?.enableElement === "function"
    ) {
      rails.enableElement(this.formTarget.elements.button);
    }
  }

  submitHandler(event) {
    event.preventDefault();
    this.toggleForm(false);

    this.requestPaymentMethod(true);
  }

  toggleForm(show) {
    this.formTarget.style.display = show ? "" : "none";
    if (this.wizardControls) {
      this.wizardControls.classList.toggle("loading", !show);
    }
  }
}
