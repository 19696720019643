import React from "react";
import RailsFormData from "@utilities/FormatRailsFormData";
import FileUploader from "@elements/FileUploader";
import ConvertBytesToMegabytes from "@utilities/ConvertBytesToMegabytes";

const MAX_FILE_SIZE = 5000000; // bytes

class GeofenceUploadForm extends React.Component {
  constructor(props){
    super(props);

    this.state = { kml_file: null };
    this.supportedFormats = ["KML"];
    this.supportedMimeTypes = [
      ".kml",
      "text/xml",
      "application/xml",
      "application/vnd.google-earth.kml+xml"
    ];
  }

  render(){
    return (
      <div className="grid-x grid-padding-x">
        <RailsFormData path="geofence" data={this.state} />

        <FileUploader
          maxFileSize={MAX_FILE_SIZE}
          accept={this.supportedMimeTypes}
          containerClassName="cell medium-12"
          supportedFormats={this.supportedFormats}
          onUploadSuccess={(blob) => this.setState({ kml_file: blob.signed_id })}
        />

        <div className="cell medium-12 uploader__footer">
          <span>{`Supported format(s): ${this.supportedFormats.join(", ")}`}</span><br />
          <span>{`Maximum filesize: ${ConvertBytesToMegabytes(MAX_FILE_SIZE)} MB`}</span><br />
        </div>
      </div>
    );
  }
}

export default GeofenceUploadForm;
