import React from "react";
import RailsFormData from "@utilities/FormatRailsFormData";
import ModalActions from "@elements/ModalPortals";
import Button from "@elements/Button";
import Mapper from "./Mapper";

class Drawing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coordinates: props.geofence.coordinates
    };

    this._onCoordinatesChange = this._onCoordinatesChange.bind(this);
  }

  _onCoordinatesChange(_id, coordinates) {
    this.setState({ coordinates });
  }

  render() {
    const { geofence, googleApiKey, previousWizardPath, renderWizardNavigationButtons } =
      this.props;
    const { coordinates } = this.state;
    const disableNextButton = coordinates.length === 0;

    function submitGeofenceSelectionForm() {
      const form = document.forms.geofence_drawing_form;
      form.querySelector("input[type='submit']")?.click();
    }

    return (
      <div className="geofence-drawing">
        {renderWizardNavigationButtons && (
          <ModalActions>
            <Button secondary label="Back" href={previousWizardPath} />
            <Button
              label="Next"
              disabled={disableNextButton}
              onClick={submitGeofenceSelectionForm}
            />
          </ModalActions>
        )}
        <Geofence
          key={geofence.id}
          geofence={geofence}
          googleApiKey={googleApiKey}
          coordinates={coordinates}
          onCoordinatesChange={this._onCoordinatesChange}
        />
      </div>
    );
  }
}

const Geofence = ({ geofence, googleApiKey, coordinates, onCoordinatesChange }) => (
  <div className="geofence-drawing__geofence">
    {coordinates.length > 0 && <RailsFormData path={geofence.object_name} data={{ coordinates }} />}
    <Mapper
      drawable
      editable
      geofences={[geofence]}
      googleApiKey={googleApiKey}
      drawingId={geofence.id}
      onCoordinatesChange={onCoordinatesChange}
    />
  </div>
);

export default Drawing;
