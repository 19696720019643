// @flow

import React, { useState, Fragment } from "react";
import Button from "@elements/Button";
import ModalActions, { ModalTitle } from "@elements/ModalPortals";
import StateErrorBoundary from "@components/Uploader/StateErrorBoundary";
import type { Node } from "react";
import CreativeSelectionForm from "./CreativeSelectionForm";

import type { Creative, CreativeType } from "./types";

export type CreativeUploadWizardState = "initializing" | "creative_selection" | "error";

type CreativeUploadWizardProps = {
  cancelHref: string,
  cancelMethod: string,
  creative: Creative,
  creativeTypes: Array<CreativeType>,
  errors: Array<string>,
  estimateEndpoint: string,
  offsetEnabled: boolean,
  mailTypeDefaultImage: string
};

export default function CreativeUploadWizard({
  cancelHref,
  cancelMethod,
  creative,
  creativeTypes,
  errors,
  estimateEndpoint,
  offsetEnabled,
  mailTypeDefaultImage
}: CreativeUploadWizardProps): React$Element<"div"> {
  const [wizardState, setWizardState] = useState<CreativeUploadWizardState>("creative_selection");

  function submitCreativeSelectionForm() {
    const form = document.forms["creative-selection-form"];
    form.querySelector("input[type='submit']")?.click();
  }

  let component;
  switch (wizardState) {
    case "initializing":
      component = <LoadingIndicator />;
      break;
    case "error":
      component = <NetworkIssueWarning />;
      break;
    case "creative_selection":
      component = (
        <Fragment>
          <ModalTitle>Creative Selection</ModalTitle>
          <ModalActions>
            <ExitCreativeUploadWizardButton cancelHref={cancelHref} cancelMethod={cancelMethod} />
            <Button label="Next" onClick={submitCreativeSelectionForm} />
          </ModalActions>
          <CreativeSelectionForm
            estimateEndpoint={estimateEndpoint}
            creative={creative}
            creativeTypes={creativeTypes}
            errors={errors}
            offsetEnabled={offsetEnabled}
            mailTypeDefaultImage={mailTypeDefaultImage}
          />
        </Fragment>
      );
      break;
    default:
      component = <div>Oops, something went wrong</div>;
      break;
  }

  return (
    <div className="uploader__container">
      <div className="uploader__component">
        <StateErrorBoundary>{component}</StateErrorBoundary>
      </div>
    </div>
  );
}

function ExitCreativeUploadWizardButton({
  cancelHref,
  cancelMethod
}: {
  cancelHref: string,
  cancelMethod: string
}): Node {
  if (cancelMethod === "get") {
    return (
      <a className="slm-button secondary" data-turbo="false" href={cancelHref}>
        Back
      </a>
    );
  } else {
    return (
      <Button
        secondary
        data-turbo="false"
        data-method={cancelMethod}
        href={cancelHref}
        label="Back"
      />
    );
  }
}

// This is already in the uploader. Make this general?
export function LoadingIndicator(): React$Element<any> {
  return (
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
}

// This is already in the uploader. Make this general?
export function NetworkIssueWarning(): React$Element<any> {
  return (
    <div>
      <p>
        Sorry, we were having trouble connecting to Poplar. Please verify your internet connection,
        reload and try again.
      </p>
    </div>
  );
}
