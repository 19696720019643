// @flow
import React from "react";
import RateField from "./RateField";
import type { AdditionalField } from "./types";
import { rateKey } from "./helpers";

function AdditionalRatesFields({ fields }: { fields: Array<AdditionalField> }): React$Node {
  return (
    <div className="mt-8">
      <h3>Additional Rates</h3>
      <table className="mx-6">
        <thead>
          <tr>
            <th>Name</th>
            <th>Rate</th>
            <td aria-hidden />
          </tr>
        </thead>
        <tbody>
          {fields.map((field: AdditionalField) => {
            const {creativeType, creativeTypeOptionId} = field;

            const fieldId = rateKey({
              creativeTypeId: creativeType?.id,
              creativeTypeOptionId,
              rateType: field.type
            });
            return (
              <tr key={field.label}>
                <td className="w-48">
                  <div className="w-48">
                    <label htmlFor={fieldId}>{field.label}</label>
                  </div>
                </td>
                <td>
                  <div className="w-20">
                    <RateField
                      label={field.label}
                      rateType={field.type}
                      fieldId={fieldId}
                      creativeType={field.creativeType}
                      creativeTypeOptionId={field.creativeTypeOptionId}
                    />
                  </div>
                </td>
                <td aria-hidden />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdditionalRatesFields;
