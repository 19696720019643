import Dropzone from "react-dropzone";
import React from "react";
import classNames from "classnames";
import { DirectUpload } from "@rails/activestorage";
import { captureException } from "@sentry/browser";
import ConvertBytesToMegabytes from "@utilities/ConvertBytesToMegabytes";
import ProgressBar from "./ProgressBar";

const UPLOAD_URL = "/rails/active_storage/direct_uploads";

class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      file: {},
      upload_in_progress: false,
      upload_progress: 0
    };

    this._handleDrop = this._handleDrop.bind(this);
    this._uploadFile = this._uploadFile.bind(this);
    this.directUploadWillStoreFileWithXHR = this.directUploadWillStoreFileWithXHR.bind(this);
    this._updateUploadProgress = this._updateUploadProgress.bind(this);
    this.renderFilePreview = this.renderFilePreview.bind(this);
  }

  componentWillUnmount() {
    const { file } = this.state;
    URL.revokeObjectURL(file.preview);
  }

  _handleDrop(acceptedFiles, rejectedFiles) {
    const acceptedFile = acceptedFiles[0];
    const { supportedFormats, maxFileSize } = this.props;
    if (acceptedFile) {
      this.setState(
        {
          file: Object.assign(acceptedFile, {
            preview: URL.createObjectURL(acceptedFile)
          }),
          type: acceptedFile.type.split("/")[1],
          error: null
        },
        () => this._uploadFile(acceptedFile)
      );
    } else {
      const rejectedFile = rejectedFiles[0];
      const error =
        rejectedFile.size > maxFileSize
          ? `File must be smaller than ${ConvertBytesToMegabytes(maxFileSize)}MB.`
          : `Please upload one of the following file types: ${supportedFormats.join(", ")}`;
      this.setState({ error: error, upload_in_progress: false });
    }
  }

  _uploadFile(acceptedFile) {
    const { onUploadSuccess } = this.props;
    const upload = new DirectUpload(acceptedFile, UPLOAD_URL, this);

    this.setState({ upload_progress: 0, upload_in_progress: true });

    upload.create((error, blob) => {
      if (error) {
        captureException(error);
        this.setState({
          upload_in_progress: false,
          error: "Error uploading file. Please try again."
        });
      } else {
        onUploadSuccess(blob);
        this.setState({ upload_in_progress: false, error: null });
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this._updateUploadProgress);
  }

  _updateUploadProgress(event) {
    const { loaded, total } = event;

    const upload_progress = (loaded / total) * 100;
    const roundedProgress = Math.ceil(upload_progress);

    this.setState({ upload_progress: roundedProgress });
  }

  renderFilePreview() {
    const { file, type, upload_in_progress, upload_progress } = this.state;

    if (!file.preview) return null;

    if (upload_progress === 100) {
      const next_button = document.getElementById("submit_trigger");
      if (next_button) {
        next_button.disabled = false;
      }
    }

    if (upload_in_progress) {
      return (
        <div className="uploader__thumb-container flex-align-center">
          <div className="uploader__progress">
            <ProgressBar percent={upload_progress} message="Upload in Progress" />
          </div>
        </div>
      );
    }

    if (type === "html" || type === "pdf") {
      return (
        <div key={file.name} className="uploader__thumb-container">
          <div className={classNames("uploader__thumb", type)} />
        </div>
      );
    } else if (file.name.endsWith(".kml")) {
      return (
        <div key={file.name} className="uploader__thumb-container">
          <div className={classNames("uploader__thumb", "kml")} />
        </div>
      );
    } else {
      return (
        <div key={file.name} className="uploader__thumb-container">
          <img src={file.preview} alt="Preview" className={classNames("uploader__thumb", type)} />
        </div>
      );
    }
  }

  render() {
    const { containerClassName, label, accept, maxFileSize } = this.props;
    const { file, error } = this.state;
    return (
      <div className={containerClassName}>
        {label && <label>{label}</label>}
        <Dropzone accept={accept} maxSize={maxFileSize} multiple={false} onDrop={this._handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", { dropzone__active: isDragActive })}
              >
                <input {...getInputProps()} />
                {this.renderFilePreview()}
                {!file.preview && <UploadMessage isDragActive={isDragActive} />}
              </div>
            );
          }}
        </Dropzone>
        <ErrorMessage message={error} />
      </div>
    );
  }
}

const UploadMessage = ({ isDragActive }) => {
  return isDragActive ? <p>Drop file here</p> : <p>Drag &amp; drop a file here to upload</p>;
};

const ErrorMessage = ({ message }) => {
  if (!message) return null;

  return <div className="uploader__error">{message}</div>;
};

export default FileUploader;
