import React from "react";
import DownloadIcon from "react-feather/dist/icons/download";

class ReportDownload extends React.Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick(e) {
    e.preventDefault();
    const { downloadAction } = this.props;
    downloadAction();
  }

  render() {
    return (
      <button
        type="button"
        className="justify-start -ml-2 toolbar__button text-button w-fit"
        onClick={this._onClick}
      >
        <DownloadIcon size={16} className="mr-2" />
        Download CSV
      </button>
    );
  }
}

export default ReportDownload;
