// @flow
import React, { useState, useEffect, Fragment } from "react";
import type { Node } from "react";
import Foundation from "foundation-sites";
import RadioButton from "@elements/RadioButton";
import TextInput from "@elements/TextInput";
import RailsFormData from "@utilities/FormatRailsFormData";

const SAMPLE_PACK_ADDRESS_SOURCE_OPTIONS = [
  { value: "organization_address", label: "Send a sample pack to this address" },
  { value: "new_address", label: "Send a sample pack to a different address" },
  { value: "no_sample_pack", label: "I don't want a sample pack" }
];

type SamplePackAddressSource = "organization_address" | "new_address" | "no_sample_pack";

type AddressField = "address_1" | "address_2" | "city" | "state" | "postal_code";
type Address = {
  address_1: string,
  address_2?: string,
  city?: string,
  state?: string,
  postal_code: string
};

export default function SamplePackAddress(): Node {
  const [addressSource, setAddressSource] =
    useState<SamplePackAddressSource>("organization_address");
  const [address, setAddress] = useState<Address>({});

  function onAddressChange(field: AddressField, { target: { value } }) {
    const newField = { [field]: value };
    setAddress((previousAddress) => {
      return { ...previousAddress, ...newField };
    });
  }

  useEffect(() => {
    Foundation.reInit("abide");
  }, [addressSource]);

  return (
    <Fragment>
      <fieldset>
        <RailsFormData path="organization[sample_pack_address_attributes]" data={{ ...address }} />
        <legend className="mb-4 font-bold">Receive a Free Sample Pack</legend>
        {SAMPLE_PACK_ADDRESS_SOURCE_OPTIONS.map(({ value, label }) => (
          <div key={`sample-pack-${value}`}>
            <RadioButton
              id={`sample-pack-${value}`}
              name="sample_pack_address_source"
              value={value}
              label={label}
              onChange={() => setAddressSource(value)}
              checked={addressSource === value}
            />
          </div>
        ))}
      </fieldset>
      {addressSource === "new_address" && (
        <fieldset>
          <legend className="my-4 font-bold">Address for Sample Pack</legend>
          <TextInput
            id="organization_sample_pack_address_address_1"
            label="Address 1"
            required
            value={address.address_1}
            onChange={(e) => onAddressChange("address_1", e)}
            autoComplete="address-line1"
          />
          <TextInput
            label="Address 2"
            value={address.address_2}
            onChange={(e) => onAddressChange("address_2", e)}
            autoComplete="address-line2"
          />
          <div className="grid grid-cols-6 gap-6">
            <TextInput
              className="col-span-3 input-container"
              label="City"
              value={address.city}
              onChange={(e) => onAddressChange("city", e)}
              autoComplete="city"
            />
            <TextInput
              className="col-span-1 input-container"
              label="State"
              value={address.state}
              onChange={(e) => onAddressChange("state", e)}
              autoComplete="state"
            />
            <TextInput
              className="col-span-2 input-container"
              label="Postal code"
              required
              value={address.postal_code}
              onChange={(e) => onAddressChange("postal_code", e)}
              autoComplete="postal-code"
            />
          </div>
        </fieldset>
      )}
    </Fragment>
  );
}
