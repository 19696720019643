// @flow

import React, { useState, Fragment } from "react";
import type { Node } from "react";
import MailIcon from "react-feather/dist/icons/mail";

import FormModal from "./FormModal";

type Props = {
  formAction: string
};

export default function SendSampleButton({ formAction }: Props): Node {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Fragment>
      <button
        type="button"
        className="button-link"
        onClick={() => setShowModal(true)}
        onKeyPress={(e) => e.key === "Enter" && setShowModal(true)}
        tabIndex={0}
      >
        <MailIcon className="mr-2" size={16} />
        Send a Sample
      </button>
      <FormModal closeModal={() => setShowModal(false)} open={showModal} formAction={formAction} />
    </Fragment>
  );
}
