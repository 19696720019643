import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["splitPercentage", "splitRecipients", "holdoutPercentage", "holdoutRecipients"];

  static values = {
    totalRecipients: Number,
    lal: Boolean
  };

  connect() {
    this.useHoldoutField = this.element.closest("form")?.elements["manual_send[use_holdout]"];

    this.updateHoldoutRecipients();
    this.updateAbSplitRecipients();
  }

  // -----------------------------------
  //  Change Handlers
  // -----------------------------------

  onAbSplitPercentageChange() {
    this.updateAbSplitRecipients();
  }

  onHoldoutPercentageChange() {
    this.updateHoldoutRecipients();
    this.updateAbSplitRecipients();
  }

  // -----------------------------------
  //  Helpers
  // -----------------------------------

  updateHoldoutRecipients() {
    this.holdoutRecipientsTarget.textContent = this.formatNumber(
      this.totalRecipientsValue * this.getHoldoutPercentage()
    );
  }

  updateAbSplitRecipients() {
    const totalRecipientsAfterHoldout =
      this.useHoldoutField?.value === "true" && !this.lalValue
        ? this.totalRecipientsAfterHoldout()
        : this.totalRecipientsValue;

    this.splitPercentageTargets.forEach((element, index) => {
      const splitPercentage = parseInt(element.value) || 0;

      const estimatedRecipients = Math.round(totalRecipientsAfterHoldout * (splitPercentage / 100));
      this.splitRecipientsTargets[index].textContent = this.formatNumber(estimatedRecipients);
    });
  }

  // <input value="20" /> --> 0.2
  getHoldoutPercentage() {
    return (parseInt(this.holdoutPercentageTarget.value) || 0) / 100;
  }

  // totalRecipients - holdout
  totalRecipientsAfterHoldout() {
    return this.totalRecipientsValue * (1 - this.getHoldoutPercentage());
  }

  // 1234.56 --> 1,235
  formatNumber(value) {
    return Math.round(value).toLocaleString();
  }
}
