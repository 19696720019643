import _ from "lodash";
import React from "react";
import Tooltip from "@components/Tooltip";
import TextInput from "@elements/TextInput";
import FormErrors from "@elements/FormErrors";
import EstimateTable from "@elements/EstimateTable";
import ModalActions from "@elements/ModalPortals";
import Button from "@elements/Button";
import RailsFormData from "@utilities/FormatRailsFormData";

class QuantityAndAttributes extends React.Component {
  constructor(props) {
    super(props);

    const selectedIncomes = [
      props.list.incomeLevel1 ? "income_level_1" : null,
      props.list.incomeLevel2 ? "income_level_2" : null,
      props.list.incomeLevel3 ? "income_level_3" : null,
      props.list.incomeLevel4 ? "income_level_4" : null
    ].filter(Boolean);

    const selectedRadioCheckboxes = {
      income: selectedRadioCheckboxesForField("income", selectedIncomes)
    };

    this.state = {
      estimateItems: estimateItemsForQuantity(props.list.quantity, props.recordPrice),
      estimateTotal: estimateTotalForQuantity(props.list.quantity, props.recordPrice),
      incomeLevel1: props.list.incomeLevel1,
      incomeLevel2: props.list.incomeLevel2,
      incomeLevel3: props.list.incomeLevel3,
      incomeLevel4: props.list.incomeLevel4,
      maxAge: props.list.maxAge,
      minAge: props.list.minAge,
      quantity: props.list.quantity,
      selectedRadioCheckboxes: selectedRadioCheckboxes,
      selectedRadioOptions: {
        gender: props.list.gender,
        homeownership: props.list.homeownership,
        children: props.list.children
      },
      showAttributes: false
    };

    this._ageChanged = this._ageChanged.bind(this);
    this._quantityChanged = this._quantityChanged.bind(this);
    this._radioCheckboxChanged = this._radioCheckboxChanged.bind(this);
    this._radioInputChanged = this._radioInputChanged.bind(this);
    this._toggleAttributes = this._toggleAttributes.bind(this);
    this._submitCreativeSelectionForm = this._submitCreativeSelectionForm.bind(this);
  }

  _toggleAttributes(_e) {
    const { showAttributes } = this.state;
    this.setState({ showAttributes: !showAttributes });
  }

  _ageChanged(e) {
    const { minAge, maxAge } = this.state;
    const ages = { min_age: minAge, max_age: maxAge };

    ages[e.target.name] = e.target.value;

    this.setState({ minAge: ages.min_age, maxAge: ages.max_age });
  }

  _radioCheckboxChanged(field, e) {
    const { selectedRadioCheckboxes } = this.state;
    const selected = selectedRadioCheckboxes[field];

    if (e.target.checked) {
      selected.push(e.target.value);
    } else {
      selected.splice(selected.indexOf(e.target.value), 1);
    }

    const nextState = { selectedRadioCheckboxes: selectedRadioCheckboxes };
    nextState[_.camelCase(e.target.value)] = e.target.checked;

    this.setState(nextState);
  }

  _radioInputChanged(field, newValue) {
    const { selectedRadioOptions } = this.state;

    this.setState({
      selectedRadioOptions: {
        ...selectedRadioOptions,
        [field]: newValue
      }
    });
  }

  _quantityChanged(e) {
    const { recordPrice } = this.props;

    const quantity = parseInt(e.target.value.replace(/,/g, "") || 0);
    const estimateItems = estimateItemsForQuantity(quantity, recordPrice);
    const estimateTotal = estimateTotalForQuantity(quantity, recordPrice);

    this.setState({ estimateItems, estimateTotal, quantity });
  }

  _submitCreativeSelectionForm() {
    const form = document.forms["quantity-and-attributes"];
    form.querySelector("input[type='submit']")?.click();
  }

  render() {
    const {
      errors,
      minimumRecordsSelectedToMail,
      previousWizardPath,
      recipientSource,
      validRecords
    } = this.props;
    const {
      estimateItems,
      estimateTotal,
      incomeLevel1,
      incomeLevel2,
      incomeLevel3,
      incomeLevel4,
      maxAge,
      minAge,
      quantity,
      selectedRadioCheckboxes,
      selectedRadioOptions,
      showAttributes
    } = this.state;

    const formData = {
      ...selectedRadioOptions,
      income_level_1: incomeLevel1,
      income_level_2: incomeLevel2,
      income_level_3: incomeLevel3,
      income_level_4: incomeLevel4,
      max_age: parseInt(maxAge),
      min_age: parseInt(minAge),
      quantity: parseInt(quantity)
    };

    const attributesSelectionFeatureEnabled = recipientSource !== "house_file";

    const minQuantity = 5000;
    const maxQuantity =
      recipientSource === "house_file" ? Math.round(validRecords / 3) : Number.MAX_VALUE;
    const quantityError = quantity < minQuantity || quantity > maxQuantity;

    return (
      <div className="manual-send-quantity-and-attributes">
        <ModalActions>
          <Button secondary label="Back" href={previousWizardPath} />
          <Button
            id="submit_trigger"
            onClick={this._submitCreativeSelectionForm}
            label="Next"
            disabled={errors.length > 0 || quantityError}
          />
        </ModalActions>

        <RailsFormData path="manual_send[list_attributes]" data={formData} />
        <FormErrors errors={errors} />
        <Required
          quantity={quantity}
          minQuantity={minimumRecordsSelectedToMail}
          maxQuantity={maxQuantity}
          recipientSource={recipientSource}
          onQuantityChange={this._quantityChanged}
          validRecords={validRecords}
          items={estimateItems}
          total={estimateTotal}
        />
        {attributesSelectionFeatureEnabled && (
          <Attributes
            minAge={minAge}
            maxAge={maxAge}
            showAttributes={showAttributes}
            selectedRadioOptions={selectedRadioOptions}
            toggleAttributes={this._toggleAttributes}
            onAgeChange={this._ageChanged}
            onRadioCheckboxChange={this._radioCheckboxChanged}
            onRadioInputChange={this._radioInputChanged}
            selectedRadioCheckboxes={selectedRadioCheckboxes}
          />
        )}
      </div>
    );
  }
}

const Required = ({
  quantity,
  minQuantity,
  maxQuantity,
  recipientSource,
  validRecords,
  items,
  total,
  onQuantityChange
}) => {
  const label =
    recipientSource === "house_file"
      ? `How many of your top-ranked customers do you want to mail? You can mail up to ${maxQuantity.toLocaleString()} of the ${validRecords.toLocaleString()} mailable customers you uploaded.`
      : `How many people do you want to mail? The minimum is ${minQuantity.toLocaleString()}.`;
  return (
    <div className="manual-send-quantity-and-attributes__required">
      <div className="grid-x">
        <div className="cell medium-6 pt-6">
          {label}
          <Quantity
            recipientSource={recipientSource}
            quantity={quantity}
            validRecords={validRecords}
            onChange={onQuantityChange}
            max={maxQuantity}
            min={minQuantity}
          />
        </div>
        <div className="medium-4 medium-offset-2">
          <Estimate recipientSource={recipientSource} items={items} total={total} />
        </div>
      </div>
    </div>
  );
};

const Attributes = ({ toggleAttributes, showAttributes, ...props }) => (
  <div>
    <input type="checkbox" className="animation-toggle" id="toggler" onClick={toggleAttributes} />
    <label className="manual-send-quantity-and-attributes__title-button" htmlFor="toggler">
      <i className="manual-send-quantity-and-attributes__icon_right" />
      Attributes (Optional)
    </label>

    {showAttributes && <AttributesBody {...props} />}
  </div>
);

const AttributesBody = ({
  maxAge,
  minAge,
  onAgeChange,
  onRadioCheckboxChange,
  onRadioInputChange,
  selectedRadioOptions,
  selectedRadioCheckboxes
}) => {
  return (
    <div className="grid-x">
      <div className="cell">
        <Note label={ATTRIBUTES_NOTE} />
      </div>
      <div className="cell medium-6">
        <RadioInputs
          field="gender"
          label="Gender"
          collection={RADIO_CHECKBOXES.gender}
          selected={selectedRadioOptions.gender}
          onChange={(e) => onRadioInputChange("gender", e.target.value)}
        />
        <AgeRange min={minAge} max={maxAge} onChange={onAgeChange} />
        <RadioInputs
          field="children"
          label="Presence of Children"
          selected={selectedRadioOptions.children}
          collection={RADIO_CHECKBOXES.children}
          onChange={(e) => onRadioInputChange("children", e.target.value)}
        />
      </div>
      <div className="medium-offset-2">
        <RadioCheckboxes
          field="income"
          label="Household Income"
          tooltip={INCOME_TOOLTIP}
          selected={selectedRadioCheckboxes.income}
          collection={RADIO_CHECKBOXES.income.boxes}
          onChange={(e) => onRadioCheckboxChange("income", e)}
        />
        <RadioInputs
          field="homeownership"
          label="Homeownership"
          selected={selectedRadioOptions.homeownership}
          collection={RADIO_CHECKBOXES.homeownership}
          onChange={(e) => onRadioInputChange("homeownership", e.target.value)}
        />
      </div>
    </div>
  );
};

const AgeRange = ({ min, max, onChange }) => {
  const tooltip = (
    <div>
      Age Range
      <Tooltip value={MAX_AGE_TOOLTIP} />
    </div>
  );

  return (
    <div className="manual-send-quantity-and-attributes__age-range">
      <div className="manual-send-quantity-and-attributes__age-range-inputs">
        <TextInput
          required
          value={min}
          type="number"
          error="Minimum 18"
          name="min_age"
          label={tooltip}
          onChange={onChange}
          data-validator="minAge"
          className="manual-send-quantity-and-attributes__age-range-min"
        />
        <span className="manual-send-quantity-and-attributes__age-range-divider">-</span>
        <TextInput
          required
          value={max}
          type="number"
          error="Maximum 99"
          name="max_age"
          label="&#x200b;"
          onChange={onChange}
          data-validator="maxAge"
        />
      </div>
    </div>
  );
};

const RadioCheckboxes = ({ collection, field, label, onChange, selected, tooltip }) => (
  <div className={`manual-send-quantity-and-attributes__${field}`}>
    <label>
      {label}
      <Tooltip value={tooltip} />
    </label>
    {collection.map(({ name, value }) => {
      const checked = selected.includes(value);
      const disabled = checked && selected.length === 1;

      return (
        <label className="preview-attribute__value" key={value}>
          <input
            value={value}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          {name}
        </label>
      );
    })}
  </div>
);

const RadioInputs = ({ collection, selected, onChange, label: groupLabel, field }) => (
  <div className={`manual-send-quantity-and-attributes__${field}`}>
    <label>{groupLabel}</label>
    {collection.map(({ label, value }) => (
      <label className="preview-attribute__value" key={value}>
        <input value={value} type="radio" checked={selected === value} onChange={onChange} />
        {label}
      </label>
    ))}
  </div>
);

const Note = ({ label }) => {
  return (
    <div className="callout warning">
      {label}{" "}
      <a
        href="https://docs.heypoplar.com/article/211-lookalike-prospecting"
        rel="noreferrer"
        target="_blank"
      >
        Learn more.
      </a>
    </div>
  );
};

const Quantity = ({ quantity, onChange, max, min }) => {
  const minError = quantity < min ? `Minimum ${min.toLocaleString("en")}` : null;
  const maxError = quantity > min ? `Maximum ${max.toLocaleString("en")}` : null;
  return (
    <div className="manual-send-quantity-and-attributes__quantity">
      <TextInput
        required
        autoFocus
        error={minError || maxError}
        label="Number of Customers"
        max={max}
        min={min}
        onChange={onChange}
        value={quantity.toLocaleString("en")}
        data-validator="quantity"
      />
    </div>
  );
};

const Estimate = ({ items, total, recipientSource }) => (
  <div className="manual-send-quantity-and-attributes__estimate">
    <EstimateTable
      key={total}
      items={items}
      total={total}
      total_label={recipientSource === "house_file" ? "Total Modeling Cost" : "Total Data Cost"}
    />
  </div>
);

const estimateItemsForQuantity = (quantity, price) => [
  { label: "Price Per Record", value: price },
  { label: "Records", value: quantity.toLocaleString("en"), skipFormatting: true }
];

const estimateTotalForQuantity = (quantity, price) => quantity * price;

const selectedRadioCheckboxesForField = (field, initialValue) => {
  let selectedValues;

  if (initialValue === RADIO_CHECKBOXES[field].all_value) {
    selectedValues = RADIO_CHECKBOXES[field].boxes.map(({ _name, value }) => value);
  } else {
    selectedValues = Array.isArray(initialValue) ? initialValue : [initialValue];
  }

  return selectedValues;
};

const RADIO_CHECKBOXES = {
  children: [
    { label: "Households with children", value: "with" },
    { label: "Households without children", value: "without" },
    { label: "Both", value: "with_and_without" }
  ],
  gender: [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "All", value: "male_and_female" }
  ],
  homeownership: [
    { label: "Renters", value: "renters" },
    { label: "Homeowners", value: "homeowners" },
    { label: "Both", value: "renters_and_homeowners" }
  ],
  income: {
    all_value: null,
    boxes: [
      { name: "$0-49,999", value: "income_level_1" },
      { name: "$50,000-124,999", value: "income_level_2" },
      { name: "$125,000-249,999", value: "income_level_3" },
      { name: "$250k+", value: "income_level_4" }
    ]
  }
};

const INCOME_TOOLTIP =
  "We recommend that you select for ALL income levels, because the model already accounts for" +
  " income as a factor. Your top-scoring prospects could be in income ranges different from what you’d expect." +
  " Only narrow down HHI when you’re 100% certain you want customers from that specific range.";

const MAX_AGE_TOOLTIP =
  "For best model results, we suggest selecting at least a range of 20 years" +
  ", if not broader. The minimum age for prospecting is 18 and maximum age is 99.";

const ATTRIBUTES_NOTE =
  "If your attributes are too specific, it can negatively affect performance." +
  " Only make deselections for groups that you cannot service. The lookalike model automatically reflects" +
  " the demographic patterns discovered amongst your customers.";

export default QuantityAndAttributes;
